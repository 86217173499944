import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { inspect } from 'util';
import clsx from 'clsx';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { InputTextField, Messages, Modal, RadioButton, RichText, Text, Types } from '@marriott/mi-ui-library';
import {
  phoenixAccountGetEarningsAndRewards,
  phoenixAccountUpdateEarningAndRewardsMethod,
} from '../../modules/graph/index';
import { useProfileStore } from '../../modules/store/profileStore';
import {
  BANNER_MSGS_KEYS,
  EARNING_AND_REWARDS_MODAL_ID,
  EARNING_AND_REWARDS_OPTIONS,
  EARNING_REWARDS_CLICK_TRACK,
  PageContext,
  apiLogger,
  checkForSpecialCharacters,
  constants,
  DATALAYER_PAGE_EVENT,
  generateApolloClientHeaders,
  getCurrentUrlParams,
  updateDataLayerProperties,
  scrollToClass,
} from '../../modules';
import { useBannerMessagesStore } from '../../modules/store/bannerMessagesStore';
import { Dropdown } from '../../molecules/Dropdown';
import { DropdownOption } from '../../molecules/Dropdown/Dropdown.types';
import { StyledEarningAndRewards } from './EarningAndRewards.styles';
import { EarningAndRewardsTypes, PayloadType } from './EarningAndRewards.types';
import mockData from './__mock__/EarningsAndRewards.mock.json';

export const EarningAndRewards = (props: EarningAndRewardsTypes) => {
  const pageContext = useContext(PageContext);
  const isAuthorMode = props?.isAuthorMode;
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const { IS_LOCAL_DEV } = process.env;
  const modalBodyClass = 'earning-and-rewards-modal-body';
  const { setBannerMsgs } = useBannerMessagesStore(state => state);
  const { profileModalId, setProfileModalId, setRevisionToken, revisionToken } = useProfileStore(state => state);
  const [earningOption, setEarningOption] = useState<string>('');
  const [accountNum, setAccountNum] = useState<string>('');
  const defaultPartner = props?.model?.airlinePartnerProgramDropdown[0];
  const [selectedPartner, setSelectedPartner] = useState<{ id: string; value: string }>(defaultPartner);
  const [updateEarningAndRewardsMethod] = useMutation(phoenixAccountUpdateEarningAndRewardsMethod);
  const [hasError, setHasError] = useState({ isErrortrue: false, isCommonErrorMsg: '', isDualError: false });
  const [getEarningsAndRewards] = useLazyQuery(phoenixAccountGetEarningsAndRewards);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);

  useEffect(() => {
    if (!isAuthorMode && profileModalId === EARNING_AND_REWARDS_MODAL_ID && !earningOption) {
      fetchEarningsAndRewards();
    }
    if (isAuthorMode) {
      setEarningOption(mockData?.data?.customer?.loyaltyInformation?.rewards?.currency?.code);
    }
  }, [isAuthorMode, profileModalId]);

  useEffect(() => {
    const urlParam = getCurrentUrlParams();
    const param = new URLSearchParams(urlParam);
    const isEarningPreferenceModal = param.get('editEarningPreference');
    if (isEarningPreferenceModal === 'true') {
      setProfileModalId(EARNING_AND_REWARDS_MODAL_ID);
    }
  }, []);

  const handlePartnerChange = (selectedOption: DropdownOption) => {
    setSelectedPartner(selectedOption);
    setIsSaveButtonEnabled(true);
  };

  //*******uxl***********
  const fetchEarningsAndRewards = () => {
    getEarningsAndRewards({
      variables: {
        customerId: sessionData?.consumerID,
      },
      context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        setEarningOption(data?.customer?.loyaltyInformation?.rewards?.currency?.code || constants.POINTS_CURRENCY_CODE);
        const preferredPartner = data?.customer?.loyaltyInformation?.rewards?.partners?.find(
          (partner: { code: string; account: string; preferred: boolean }) => partner?.preferred
        );
        if (preferredPartner) {
          const partnerObj = props?.model?.airlinePartnerProgramDropdown?.find(
            item => item?.id === preferredPartner?.code
          );
          setSelectedPartner({ id: partnerObj?.id ?? '', value: partnerObj?.value ?? '' });
          setAccountNum(preferredPartner.account ?? '');
        }
        apiLogger(
          `[EarningPreference] EarningPreferenceData - sessionId: ${sessionData?.sessionToken}: ${inspect(data)}`
        );
      },
      onError: () => {
        setHasError({ isErrortrue: true, isCommonErrorMsg: pageContext?.uxlErrorMessage, isDualError: false });
        scrollToClass(modalBodyClass);
      },
    });
  };

  //mutation
  const handleSumbit = () => {
    let getPayload;
    if (earningOption === EARNING_AND_REWARDS_OPTIONS.POINTS) {
      getPayload = {
        rewards: {
          currency: constants.POINTS_CURRENCY_CODE,
        },
        customerId: sessionData?.consumerID,
        revisionToken: revisionToken,
      };
      handleMutationCall(getPayload);
    } else {
      const haveEmptyAccountNum = checkForSpecialCharacters(accountNum);
      if (haveEmptyAccountNum || !accountNum || accountNum?.length === 1) {
        setHasError({
          isErrortrue: true,
          isCommonErrorMsg: props?.model?.errorMsgFrequentFlyerProgramNumber,
          isDualError: false,
        });
        scrollToClass(modalBodyClass);
      } else {
        getPayload = {
          rewards: {
            partners: [
              {
                code: selectedPartner.id,
                account: accountNum,
                preferred: selectedPartner.id ? true : false,
              },
            ],
            currency: constants.MILES_CURRENCY_CODE,
          },
          customerId: sessionData?.consumerID,
          revisionToken: revisionToken,
        };
        handleMutationCall(getPayload);
      }
    }
  };

  const handleMutationCall = (getPayload: PayloadType) => {
    updateEarningAndRewardsMethod({
      variables: { updateLoyaltyRewardsInput2: getPayload },
      context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
      onCompleted: data => {
        const responseCode = data?.updateLoyaltyRewards?.status?.[0]?.code;
        const responseField = data?.updateLoyaltyRewards?.status?.[0]?.messages?.[0]?.user?.field;
        const revisionToken = data?.updateLoyaltyRewards?.revisionToken;
        revisionToken && setRevisionToken(revisionToken);
        apiLogger(
          `[EarningPreferenceChange] updateEarningPreference - sessionId value: ${sessionData?.sessionToken}: ${inspect(
            data
          )}`
        );
        if (responseCode === constants?.STATUS_SUCCESS) {
          const dataLayerProps = {
            mr_prof_earning_pref: getPayload.rewards.currency,
          };
          updateDataLayerProperties(dataLayerProps, DATALAYER_PAGE_EVENT.EARNING_PREFERENCE_SUCCESS);
          setBannerMsgs(BANNER_MSGS_KEYS.ACCOUNT_UPDATED_MSG);
          handleResetData();
        } else if (responseCode === constants.INVALID_PARTNER_NUMBER) {
          setHasError({
            isErrortrue: true,
            isCommonErrorMsg: props?.model?.errorMsgFrequentFlyerProgramNumber,
            isDualError: false,
          });
          scrollToClass(modalBodyClass);
        } else if (responseField === constants.DC_ERROR_FLAG || responseField === 'partnercode.invalid') {
          setHasError({
            isErrortrue: true,
            isCommonErrorMsg: props?.model?.dualCurrencyErrorMessage,
            isDualError: true,
          });
          setSelectedPartner(defaultPartner);
          setAccountNum('');
          scrollToClass(modalBodyClass);
        } else {
          setHasError({ isErrortrue: true, isCommonErrorMsg: pageContext?.uxlErrorMessage, isDualError: false });
          scrollToClass(modalBodyClass);
        }
      },
      onError: () => {
        setHasError({ isErrortrue: true, isCommonErrorMsg: pageContext?.uxlErrorMessage, isDualError: false });
        scrollToClass(modalBodyClass);
      },
    });
  };
  const handleResetData = () => {
    setHasError({ isErrortrue: false, isCommonErrorMsg: '', isDualError: false });
    setProfileModalId('');
    setEarningOption('');
    setSelectedPartner(defaultPartner);
    setAccountNum('');
    setIsSaveButtonEnabled(false);
  };

  return (
    <StyledEarningAndRewards data-component-name="o-account-earningandrewards" data-testid="earningandrewards">
      <Modal
        show={profileModalId === EARNING_AND_REWARDS_MODAL_ID}
        popupOpenState={profileModalId === EARNING_AND_REWARDS_MODAL_ID}
        setPopupOpenState={(): void => {
          handleResetData();
        }}
        modalId={'earning_and_rewards__modal'}
        labelledBy={'earning_and_rewards__modal'}
        secondaryClassName="modal__container"
        disableScrollOnBody={true}
        aria-modal="true"
        onClose={(): void => {
          handleResetData();
        }}
        className="modal__container"
        role="dialog"
        handleBlur={true}
      >
        <Modal.Header
          className="earning_and_rewards__modal__header t-subtitle-xl"
          customHeadingClass="t-subtitle-xl"
          labelText={props?.model?.modelTitle}
          popupHeaderOnCLoseFunc={(): void => {
            handleResetData();
          }}
        />
        <Modal.Body className={`modal__container__body custom-scrollbar ${modalBodyClass}`}>
          <div className="my-2 my-md-3 mr-3">
            {!earningOption ? (
              <div className="container modal_loader px-0">
                <div className="col ">
                  <div className="skeleton-loader modal_loader__heading " />
                  <div className="skeleton-loader modal_loader__body mt-3" />
                  <div className="skeleton-loader modal_loader__body mt-3" />
                </div>
              </div>
            ) : (
              <div>
                {hasError?.isErrortrue && (
                  <div id="earnings-and-rewards" data-testId="uxl-error-msg" className="uxl-error-msg">
                    <Messages messageType="warning" className="mb-4">
                      <RichText text={hasError?.isCommonErrorMsg} componentId="uxl-error-msg" />
                    </Messages>
                  </div>
                )}
                <Text
                  customClass="earning-rewards--heading"
                  copyText={props?.model?.modelDescription}
                  fontSize={Types.size.medium}
                  element={Types.tags.div}
                />
                <div className="my-5">
                  {props?.model?.rewardsCheckbox?.map((cType: { value: string; id: string }) => (
                    <RadioButton
                      key={cType?.id}
                      data-testid={`earning-rewards-btn-${cType?.id}`}
                      radiobuttonId={`earning-rewards-btn-${cType?.id}`}
                      radiobuttonName={'earning-rewards'}
                      radiobuttonLabel={cType?.value}
                      checked={earningOption === cType?.id}
                      onChange={() => {
                        setEarningOption(cType?.id);
                        setIsSaveButtonEnabled(true);
                      }}
                      className="earning-rewards--radiobtn pb-2"
                      value={cType?.id}
                      setTabIndex={0}
                      labelClassName={'pt-1 pl-4'}
                      onKeyDown={event => {
                        if (event.key === 'Enter') {
                          setEarningOption(cType?.id);
                          setIsSaveButtonEnabled(true);
                        }
                      }}
                      isRichText={false}
                    />
                  ))}
                </div>
                {earningOption === EARNING_AND_REWARDS_OPTIONS.MILES && (
                  <div>
                    <div className={'m-input-field t-font-m px-0 '} onClick={e => e.stopPropagation()}>
                      <label
                        className={clsx(
                          'earning-rewards--dropdown__label mb-0',
                          hasError?.isDualError && 'earning-rewards__disabled'
                        )}
                        id="dropdown-label-partneradd"
                        htmlFor="dropdown-label-partneradd"
                        data-testid="partneradd-label"
                      >
                        {props?.model?.airlinePartnerProgramLabel}
                      </label>
                      <Dropdown
                        defaultOption={selectedPartner.value}
                        dropdownOptions={props?.model?.airlinePartnerProgramDropdown}
                        onChange={handlePartnerChange}
                        customClassName="earning-rewards--dropdown__dropdownlist is-active"
                        dropdownId="partneradd"
                        isDisabled={hasError?.isDualError}
                      />
                    </div>
                    <InputTextField
                      inputValue={accountNum}
                      label={props?.model?.frequentFlyerProgramLabel}
                      testId="frequentAccount"
                      showErrorMessage={undefined}
                      setErrorHtml={true}
                      messageClass="error-label"
                      inputMaxLength={25}
                      getInputProps={() => ({
                        autoComplete: 'off',
                        id: `frequentAccount`,
                      })}
                      getLabelProps={() => ({
                        htmlFor: `frequentAccount`,
                      })}
                      className={clsx(
                        'earning-rewards--account m-input-field mt-5',
                        hasError?.isDualError && 'is-disabled'
                      )}
                      getInputValue={event => {
                        setAccountNum(event);
                        setIsSaveButtonEnabled(true);
                      }}
                    />
                    <Text
                      customClass={'earning-rewards--footerText mt-5 mb-5  mb-md-4'}
                      copyText={props?.model?.footerDescription}
                      fontSize={Types.size.medium}
                      element={Types.tags.div}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer
          clearButtonLabel={props?.model?.cancelButton}
          applyLabel={props?.model?.saveButton}
          className="modal-main-container__content__modalFooter justify-content-md-end px-5 py-4 py-md-5"
          applyBtnClickTrackValue={EARNING_REWARDS_CLICK_TRACK}
          clearBtnClassname="modal-main-container__content__modalFooter__clear-btn m-link-action mr-md-4 p-0 px-auto px-md-0"
          clearClickHandler={(): void => {
            handleResetData();
          }}
          applyBtnClickHandler={() => {
            handleSumbit();
          }}
          applyButtonDisabled={
            (earningOption === EARNING_AND_REWARDS_OPTIONS.MILES &&
              (!accountNum || selectedPartner.id === '' || hasError?.isDualError)) ||
            !isSaveButtonEnabled
          }
        />
      </Modal>
    </StyledEarningAndRewards>
  );
};

export const EarningAndRewardsConfig = {
  emptyLabel: 'earningandrewards',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/earningandrewards`,
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EarningAndRewardsEditable = (props: any) => {
  return props?.cqPath?.includes('datapagecomponent') ? (
    <EarningAndRewards {...props} />
  ) : (
    <EditableComponent config={EarningAndRewardsConfig} {...props}>
      <EarningAndRewards {...props} />
    </EditableComponent>
  );
};
