import React, { FC, useContext, useEffect, useState, useMemo } from 'react';
import { inspect } from 'util';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Accordion,
  CheckBox,
  Heading,
  Icon,
  Messages,
  Modal,
  RadioButton,
  RichText,
  Text,
  Types,
} from '@marriott/mi-ui-library';
import clsx from 'clsx';
import {
  ACCESSIBILITY,
  FoodBevIconsType,
  ROOM_OPTIONS,
  ROOM_PREF_PRIORITYTYPE,
  BANNER_MSGS_KEYS,
  ROOM_AMENITIES,
  ROOM_PREF_AMENTITIES,
  ROOM_PREFERENCE_ID,
  EXTRA_FEATHER_PILLOWS_ID,
  FEATHER_FREE_ID,
  ROOM_PREFERENCES_CLICK_TRACK,
} from '../../modules/utils/constants/constants';
import { PageContext } from '../../modules/context/PageContext';
import { phoenixAccountGetRoomPreferences, phoenixAccountAddRoomPreferences } from '../../modules/graph';
import { useProfileStore } from '../../modules/store/profileStore';
import { generateApolloClientHeaders, getCurrentUrlParams, scrollToClass } from '../../modules/utils/helper';
import { useBannerMessagesStore } from '../../modules/store/bannerMessagesStore';
import { apiLogger } from '../../modules/utils/apilogger';
import { CheckedDataType, RoomPreferencesProps, CustomerPreferences, Detail } from './RoomPreferences.types';
import { StyledRoomPreferences } from './RoomPreferences.styles';
import mockdata from './__mock__/RoomPreferences.model.json';

export const RoomPreferences: FC<RoomPreferencesProps> = ({ model, isAuthorMode }) => {
  const { IS_LOCAL_DEV } = process.env;
  const { setBannerMsgs } = useBannerMessagesStore(state => state);
  const { profileModalId, setProfileModalId, setRevisionToken, revisionToken } = useProfileStore(state => state);
  const pageContext = useContext(PageContext);
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const modalBodyClass = 'room-preferences-modal-body';
  const [hasError, setHasError] = useState({ showError: false, message: '' });
  const [customerPreferences, setCustomerPreferences] = useState<CustomerPreferences | undefined>(undefined);
  const [accordionOpenState, setAccordionOpenState] = useState<{ [key: string]: boolean }>({
    [ROOM_OPTIONS]: false,
    [ACCESSIBILITY]: true,
    [ROOM_AMENITIES]: false,
  });
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [checkedRoomOpt, setCheckedRoomOpt] = useState<CheckedDataType>([]);
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [dataChanged, setDateChanged] = useState(false);
  const [getCustomerRoomPreference] = useLazyQuery(phoenixAccountGetRoomPreferences);
  const [updateCustomerRoomPreference] = useMutation(phoenixAccountAddRoomPreferences);

  useEffect(() => {
    if (isAuthorMode) {
      setCustomerPreferences(mockdata?.customerPreferences);
    }

    const urlParam = getCurrentUrlParams();
    const param = new URLSearchParams(urlParam);
    const isRoomPreferencesModal = param.get('editReservationPreferences');
    if (isRoomPreferencesModal === 'true') {
      setProfileModalId(ROOM_PREFERENCE_ID);
    }
  }, []);

  const roomPreferencesModalData = useMemo(() => {
    const roomOptions: Detail[] = [];
    const accessibility: Detail[] = [];
    const roomAmenities: Detail[] = [];
    model?.details?.map(preferenceData => {
      if (preferenceData.titleId === ROOM_OPTIONS) {
        roomOptions.push(preferenceData);
      } else if (preferenceData.titleId === ACCESSIBILITY) {
        accessibility.push(preferenceData);
      } else {
        roomAmenities.push(preferenceData);
      }
    });
    return [
      {
        sectionType: ACCESSIBILITY,
        sectionDetails: accessibility,
        sectionTitle: accessibility[0]?.title,
      },
      { sectionType: ROOM_OPTIONS, sectionDetails: roomOptions, sectionTitle: roomOptions[0]?.title },
      { sectionType: ROOM_AMENITIES, sectionDetails: roomAmenities, sectionTitle: roomAmenities[0]?.title },
    ];
  }, [model?.details]);

  useEffect(() => {
    if (!isAuthorMode && profileModalId === ROOM_PREFERENCE_ID && !customerPreferences) {
      fetchRoomPreferenceData();
    }
  }, [profileModalId, customerPreferences]);

  useEffect(() => {
    if (profileModalId === ROOM_PREFERENCE_ID && customerPreferences) {
      const roomOptionsChecked: CheckedDataType = [];
      // Assuming preferences is an array of preference objects
      const preferenceIds = customerPreferences?.preferences?.map(pref => pref.id);
      model?.details?.forEach(section => {
        let matched = false;
        if (section.titleId === ROOM_OPTIONS) {
          if (section.sectionId === ROOM_PREF_PRIORITYTYPE) {
            if (section.items.find(item => item.id === customerPreferences?.roomPreferencePriority?.code)) {
              roomOptionsChecked.push({
                sectionType: section.sectionId,
                id: customerPreferences?.roomPreferencePriority?.code ?? 'NP',
              });
            } else {
              roomOptionsChecked.push({ sectionType: section.sectionId, id: 'NP' });
            }
          } else {
            for (const item of section.items ?? []) {
              const matchingPref = preferenceIds?.includes(item.id);
              if (matchingPref) {
                roomOptionsChecked.push({ sectionType: section.sectionId, id: item.id });
                matched = true;
                break;
              }
            }
            if (!matched) {
              roomOptionsChecked.push({ sectionType: section.sectionId, id: 'NP' });
            }
          }
        }
      });
      setCheckedRoomOpt([...roomOptionsChecked]);
      const newCheckedItems = model?.details
        ?.filter(option => option.titleId === ACCESSIBILITY || option.titleId === ROOM_PREF_AMENTITIES)
        .flatMap(option => option.items)
        .filter(item => preferenceIds?.includes(item.id))
        .map(item => item.id);
      setCheckedItems(newCheckedItems);
      setDateChanged(false);
      setAccordionOpenState({
        [ROOM_OPTIONS]: false,
        [ACCESSIBILITY]: true,
        [ROOM_AMENITIES]: false,
      });
    }
  }, [profileModalId, customerPreferences]);

  const handleRoomOptionsRadio = (sectionId: string, id: string) => {
    const isInvalid = id === EXTRA_FEATHER_PILLOWS_ID && validateFeatherFeeSelect(FEATHER_FREE_ID, checkedItems);
    if (!isInvalid) {
      if (hasError.message === model?.featherFreeErrMsg) {
        setHasError({ showError: false, message: '' });
      }
      setDateChanged(true);
      setCheckedRoomOpt(prevState => {
        const index = prevState.findIndex(data => data.sectionType === sectionId);
        if (index !== -1) {
          // If the section already exists, update the id
          return prevState.map((data, i) => (i === index ? { ...data, id } : data));
        } else {
          // If the section doesn't exist, add a new object
          return [...prevState, { sectionType: sectionId, id }];
        }
      });
    }
  };
  const handleCheckChange = (itemId: string, isChecked: boolean) => {
    const isInvalid =
      itemId === FEATHER_FREE_ID &&
      validateFeatherFeeSelect(
        EXTRA_FEATHER_PILLOWS_ID,
        checkedRoomOpt.map(item => item.id)
      );
    if (!isInvalid) {
      if (hasError.message === model?.featherFreeErrMsg) {
        setHasError({ showError: false, message: '' });
      }
      setDateChanged(true);
      if (isChecked) {
        setCheckedItems(prevItems => prevItems.filter(id => id !== itemId));
      } else {
        setCheckedItems(prevItems => [...prevItems, itemId]);
      }
    }
  };

  const validateFeatherFeeSelect = (itemId: string, selectedItem: string[]) => {
    const isInvalid = selectedItem?.includes(itemId) ? true : false;
    if (isInvalid) {
      setHasError({ showError: true, message: model?.featherFreeErrMsg ?? '' });
      scrollToClass(modalBodyClass);
    }
    return isInvalid;
  };

  function getRoomOptionsContent(sectionDetails: Detail[]) {
    return (
      <div className="room-options row">
        {sectionDetails.map(
          (option: {
            title: string;
            sectionId: string;
            sectionTitle: string | undefined;
            items: { id: string; value: string }[];
          }) => {
            return (
              <div key={option.sectionId} className={`room_type ${option.sectionId} col-12 col-lg-4`}>
                <Heading
                  variation={Types.headingType.subtitle}
                  fontSize={Types.size.small}
                  titleText={option.sectionTitle}
                  customClass={clsx('d-inline food-item my-5')}
                />
                <div className="room_type__section">
                  {option?.items.map(item => {
                    let isChecked = false;
                    checkedRoomOpt.forEach(section => {
                      if (section.sectionType === option?.sectionId && item.id === section.id) {
                        isChecked = true;
                      }
                    });

                    return (
                      <div key={item.id}>
                        <RadioButton
                          radiobuttonId={option.sectionId + item.id}
                          radiobuttonName={option.sectionId}
                          radiobuttonLabel={item.value}
                          value={item.value}
                          className="pt-2 pr-3 pr-lg-5 mr-lg-5 pt-md-4 modal__container_radio"
                          checked={isChecked}
                          setTabIndex={0}
                          dataTestId={option.sectionId + item.id}
                          onChange={() => {
                            handleRoomOptionsRadio(option.sectionId, item.id);
                            setIsSaveButtonEnabled(true);
                          }}
                          onKeyDown={event => {
                            if (event.key === 'Enter') {
                              handleRoomOptionsRadio(option.sectionId, item.id);
                              setIsSaveButtonEnabled(true);
                            }
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          }
        )}
      </div>
    );
  }
  //********uxl*************
  const fetchRoomPreferenceData = () => {
    getCustomerRoomPreference({
      variables: {
        customerId: sessionData?.consumerID,
      },
      context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        setCustomerPreferences(data?.customer?.preferences);
        setHasError({ showError: false, message: '' });
        apiLogger(
          `[RoomPreference] getRoomPreferenceData - sessionId value: ${sessionData?.sessionToken}: ${inspect(data)}`
        );
      },
      onError: () => {
        setHasError({ showError: true, message: pageContext?.uxlErrorMessage });
        scrollToClass(modalBodyClass);
      },
    });
  };

  //********mutation*************
  const updatePreferences = () => {
    const priorityData = checkedRoomOpt.find(data => data.sectionType === ROOM_PREF_PRIORITYTYPE);
    const checkedItemsObjects = checkedItems.map(checkedId => ({ id: checkedId }));
    const payload = {
      customerId: sessionData?.consumerID,
      preferences: [
        ...checkedRoomOpt.filter(data => data.id !== 'NP').map(data => ({ id: data.id })),
        ...checkedItemsObjects,
      ],
      revisionToken: revisionToken,
      roomPreferencePriority: {
        code: priorityData?.id && priorityData?.id !== 'NP' ? priorityData?.id : '',
      },
    };
    setSaveInProgress(true);
    updateCustomerRoomPreference({
      variables: {
        input: {
          ...payload,
        },
      },
      context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
      onCompleted: data => {
        setRevisionToken(data?.updateCustomerPreferences?.revisionToken);
        setBannerMsgs(BANNER_MSGS_KEYS.ACCOUNT_UPDATED_MSG);
        setProfileModalId('');
        setIsSaveButtonEnabled(false);
        setCustomerPreferences(undefined);
        setHasError({ showError: false, message: '' });
        apiLogger(
          `[RoomPreferenceMethod] updateRoomPreferenceMethod - sessionId value: ${sessionData?.sessionToken}: ${inspect(
            data
          )}`
        );
        setSaveInProgress(false);
      },
      onError: () => {
        setSaveInProgress(false);
        setHasError({ showError: true, message: pageContext?.uxlErrorMessage });
        scrollToClass(modalBodyClass);
      },
    });
  };

  const resetForm = () => {
    setHasError({ showError: false, message: '' });
    setProfileModalId('');
    setAccordionOpenState({
      [ROOM_OPTIONS]: false,
      [ACCESSIBILITY]: false,
      [ROOM_AMENITIES]: false,
    });
    setIsSaveButtonEnabled(false);
  };

  return (
    <StyledRoomPreferences>
      <Modal
        show={profileModalId === ROOM_PREFERENCE_ID}
        popupOpenState={profileModalId === ROOM_PREFERENCE_ID}
        setPopupOpenState={(): void => {
          resetForm();
        }}
        modalId={'header123'}
        labelledBy={'header'}
        onClose={(): void => {
          resetForm();
        }}
        className="modal__container"
        disableScrollOnBody={true}
        handleBlur={true}
        aria-modal="true"
      >
        <Modal.Header
          className="room-header t-subtitle-xl py-3 py-md-4 pl-4 pr-4 px-md-5 pt-md-5"
          customHeadingClass="t-subtitle-xl"
          labelText={model?.header}
          popupHeaderOnCLoseFunc={(): void => {
            resetForm();
          }}
        />
        <Modal.Body
          className={`modal__container-content custom-scrollbar px-4 px-md-5 py-4 mt-md-2 mb-3 mx-2 ${modalBodyClass}`}
        >
          <div>
            {hasError.showError && (
              <div id="room-preferences-error" data-testId="room-preferences-error" className="room-preferences-error">
                <Messages messageType="warning" className="mb-4">
                  <RichText text={hasError?.message} componentId="room-preferences-error-test" />
                </Messages>
              </div>
            )}
            <Text
              element={Types.tags.paragraph}
              fontSize={Types.size.medium}
              customClass="mb-4 mb-lg-5"
              copyText={model?.description}
            />
            {roomPreferencesModalData?.map((option, index) => (
              <div key={index}>
                {option.sectionType === ROOM_OPTIONS ? (
                  <div>
                    <Accordion
                      id={option?.sectionType}
                      key={index + option.sectionType}
                      enableAccordionSeparator={true}
                      isOpen={accordionOpenState[option?.sectionType]}
                      headerChildren={
                        <div className="pt-2">
                          <Icon iconClass={clsx('icon-decorative icon-m', FoodBevIconsType[ROOM_OPTIONS])}></Icon>
                          <Heading
                            variation={Types.headingType.subtitle}
                            fontSize={Types.size.large}
                            titleText={option.sectionTitle}
                            customClass={clsx('d-inline room-item ml-3')}
                          />
                        </div>
                      }
                      handleHeaderClick={(id: string) =>
                        setAccordionOpenState(prevState => ({
                          ...prevState,
                          [id]: !prevState[id],
                        }))
                      }
                    >
                      {getRoomOptionsContent(option.sectionDetails)}
                    </Accordion>
                  </div>
                ) : (
                  <Accordion
                    id={option?.sectionType}
                    enableAccordionSeparator={index !== roomPreferencesModalData?.length - 1}
                    key={index}
                    isOpen={accordionOpenState[option?.sectionType]}
                    headerChildren={
                      <div className="pt-2">
                        <Icon
                          iconClass={clsx(
                            'icon-decorative icon-m',
                            option?.sectionType === ACCESSIBILITY
                              ? FoodBevIconsType[ACCESSIBILITY]
                              : FoodBevIconsType[ROOM_AMENITIES]
                          )}
                        ></Icon>
                        <Heading
                          variation={Types.headingType.subtitle}
                          fontSize={Types.size.large}
                          titleText={option.sectionTitle}
                          customClass={clsx('d-inline food-item ml-3')}
                        />
                      </div>
                    }
                    handleHeaderClick={(id: string) =>
                      setAccordionOpenState(prevState => ({
                        ...prevState,
                        [id]: !prevState[id],
                      }))
                    }
                  >
                    {option.sectionDetails?.[0]?.items?.map(item => {
                      const isChecked = checkedItems.some(checkedId => checkedId === item.id);
                      return (
                        <div key={item.id}>
                          <CheckBox
                            onChange={() => {
                              handleCheckChange(item.id, isChecked);
                              setIsSaveButtonEnabled(true);
                            }}
                            checked={isChecked}
                            data-testid={option?.sectionType + item.id}
                            checkboxName={option.sectionType}
                            checkboxId={item.value}
                            className="accessibility-amenities"
                            children={
                              <Text element={Types.tags.paragraph} fontSize={Types.size.medium} copyText={item.value} />
                            }
                            onKeyDown={event => {
                              if (event.key === 'Enter') {
                                handleCheckChange(item.id, isChecked);
                                setIsSaveButtonEnabled(true);
                              }
                            }}
                          />
                        </div>
                      );
                    })}
                  </Accordion>
                )}
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer
          className="modal__container-footer m-auto pt-5 pb-md-5 align-items-baseline justify-content-md-end"
          clearButtonLabel={model?.cancelButton}
          clearClickHandler={(): void => {
            resetForm();
          }}
          applyLabel={model?.saveButton}
          data-testid="Save"
          applyBtnClickHandler={() => {
            updatePreferences();
          }}
          clearBtnClassname="m-link-action mx-md-4 p-0 px-auto px-md-0 clear-btn"
          applyButtonDisabled={!isSaveButtonEnabled || !customerPreferences || saveInProgress || !dataChanged}
          applyBtnClickTrackValue={ROOM_PREFERENCES_CLICK_TRACK}
        ></Modal.Footer>
      </Modal>
    </StyledRoomPreferences>
  );
};
export const RoomPreferencesConfig = {
  emptyLabel: 'roomPreferences',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/roomPreferences`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const RoomPreferencesEditable = (props: any) => {
  return props?.cqPath?.includes('datapagecomponent') ? (
    <RoomPreferences {...props} />
  ) : (
    <EditableComponent config={RoomPreferencesEditable} {...props}>
      <RoomPreferences {...props} />
    </EditableComponent>
  );
};
