import styled from 'styled-components';
import { toRem, baseVariables } from '../../styles';
import { logoResize } from '../../utils/logoResize';
import { FONT_ICON_BRAND_LOGO_SIZE_D135_M90, FONT_ICON_PORTFOLIO_LOGO_SIZE_D135_M90 } from '../../utils/enums/enums';

export const StyledBannerText = styled.div<{
  contentBlockAlignment: string;
  brandCode: string;
  loyaltyClasses?: string;
}>`
  display: flex;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  .bannertext {
    padding: ${toRem(32)} ${toRem(24)};
    @media ${baseVariables.mediaQuery.lg} {
      padding: ${toRem(32)} ${toRem(0)};
    }
    display: flex;
    flex-direction: column;
    align-items: ${props => props.contentBlockAlignment?.toLowerCase()};
    justify-content: space-between;
    gap: ${toRem(32)};
    &_content {
      display: flex;
    }
    &_icon {
      display: flex;
      justify-content: ${props => props.contentBlockAlignment?.toLowerCase()};
      padding: ${toRem(6.6)} ${toRem(4.4)};
      @media ${baseVariables.mediaQuery.sm} {
        padding: ${toRem(7.14)} ${toRem(0)} ${toRem(6.8)} ${toRem(0)};
      }
      ${props =>
        props.brandCode?.length > 0
          ? `
          height: unset;
          width: unset; 
          `
          : `
          height: ${toRem(40)};
          width: ${toRem(40)};
      `}
      .brand {
        &-logo-${props => props.brandCode} {
          &:hover {
            background-color: transparent;
          }
          &:before {
            ${props => logoResize(props.brandCode.toUpperCase(), FONT_ICON_BRAND_LOGO_SIZE_D135_M90, true)};
          }
        }
        &-logo-Escape,
        &-logo-escape,
        &-logo-ESCAPE {
          &:after {
            padding-left: ${props =>
              props.contentBlockAlignment?.toLowerCase() === 'center' ? `${toRem(10)}` : 'unset'};
            ${logoResize('ESCAPE', FONT_ICON_BRAND_LOGO_SIZE_D135_M90, true)};
            line-height: unset;
          }
          margin-left: -1.4rem;
          @media ${baseVariables.mediaQuery.lg} {
            margin-left: -1.5rem;
          }
        }
      }
      .portfolio-icon-${props => props.brandCode}:before {
        ${props => logoResize(props.brandCode.toLowerCase(), FONT_ICON_PORTFOLIO_LOGO_SIZE_D135_M90, true)};
      }
      .icon-custom {
        &:before {
          display: flex;
          align-items: center;
          justify-content: center;
          ${({ loyaltyClasses }) => {
            let color = '';
            if (loyaltyClasses) {
              color = loyaltyClasses;
            }
            return `
                color: ${baseVariables.color[`${color}`]};
              `;
          }}
        }
      }
    }
    &_desccontent {
      display: flex;
      flex-direction: column;
      p {
        margin: 0;
      }
      &.small-margin {
        @media ${baseVariables.mediaQuery.md} {
          margin-bottom: ${toRem(-8)};
        }
      }
    }
    &_text-align {
      text-align: ${props => props.contentBlockAlignment?.toLowerCase()};
    }
    &_link {
      align-self: flex-end;
    }
    &_linktext {
      border-bottom: ${toRem(1)} solid ${baseVariables.color['base-10']};
    }
    &_button,
    a {
      width: fit-content;
    }
    .description-column {
      display: flex;
      gap: ${toRem(24)};
      flex-direction: column;
      @media ${baseVariables.mediaQuery.xl} {
        flex-direction: row;
      }
      .column {
        display: flex;
        flex-direction: column;
        gap: ${toRem(24)};
        :first-child {
          border-bottom: 1px solid ${baseVariables.color['neutral30']};
          padding-bottom: ${toRem(24)};
          @media ${baseVariables.mediaQuery.xl} {
            padding-bottom: 0;
            padding-right: ${toRem(24)};
            border-bottom: none;
            border-right: 1px solid ${baseVariables.color['neutral30']};
          }
        }
      }
    }
    .m-link-action {
      font-size: ${toRem(16)};
      font-weight: 700;
      padding: 0 0 ${toRem(2)} 0;
    }
    .m-link-tertiary-button {
      padding: 0 0 ${toRem(2)} 0;
      font-size: ${toRem(16)};
      font-weight: 700;
    }
    .bannertext_linktext {
      font-weight: 500;
    }
  }
  .glide {
    padding-top: ${toRem(48)};
    padding-bottom: ${toRem(48)};
    .glide__slides {
      align-items: end;
      list-style-type: none;
    }
    .icon-styles {
      display: flex;
      justify-content: center;
      font-size: ${toRem(54)};
      padding: ${toRem(10)};
    }
    .description-block-pullquote {
      font-size: ${toRem(18)};
      @media ${baseVariables.mediaQuery.md} {
        font-size: ${toRem(22)};
      }
    }
    .attribution-block {
      display: flex;
      justify-content: center;
      gap: ${toRem(2)};
      font-size: ${toRem(12)};
    }
    .center-align-controls {
      display: flex;
      justify-content: center;
      margin-top: ${toRem(16)};
      .carouselControlType3b {
        height: ${toRem(24)};
      }
    }
  }
`;
