// Styles for CreateAccountForm go here.
import rtl from 'styled-components-rtl';
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { constants } from '../../../modules';

export const StyledCreateAccountForm = styled.div`
  ${rtl` 
  .activate_account--countrydropdown {
    width: 100%;
    height: ${toRem(44.67)};
    border-radius: ${toRem(4)} !important; //important is given to override the dropdown css property
    border-color: ${baseVariables.color['neutral40']} !important;
    padding-top: ${toRem(12)} !important;
    padding-bottom: ${toRem(12)} !important;
    .icon-dropdown-down,
    .icon-dropdown-up {
      position: absolute;
      right: ${toRem(8)};
    }
    :is(${constants.RTL_LANG_LIST}) {
      display: inline-flex;
      
      .icon-dropdown-down,
      .icon-dropdown-up {
        right: unset;
        left: ${toRem(12)};
      }
        .dropdown__container--list--option {
        text-align: right !important
      }
    }
  }

  .dropdown {
    cursor: pointer;
  }
  .dropdown-value {
    font-size: ${toRem(16)};
  }

  .dropdown__container {
    color: ${baseVariables.color['neutral40']} !important;
    z-index: 2;
  }
  .country-label {
    z-index: 1;
  }
  .m-dropdown-container {
    .m-dropdown-children {
      height: auto;
      max-height:${toRem(224)}; 
    }
  }

  .dropdown__container {
    z-index: 99;
  }
  .password-checks {
    margin-bottom: ${toRem(24)} !important;
  }
  .pointer-cursor {
    cursor: pointer;
    text-decoration: underline;
  }
  .disableField * {
    color: ${baseVariables.color['neutral30']} !important;
    border-color: ${baseVariables.color['neutral30']} !important;
  }
  .m-input-field {
    &.is-disabled {
      label {
        z-index: 1;
      }
    }
    caret-color: inherit; // This is to override transparent caret color

    &:is(${constants.RTL_LANG_LIST}){
      text-align: right;

      & input {
        text-indent: ${toRem(16)} !important;
        height: ${toRem(44.67)} !important;
      }

      & label {
        right: ${toRem(11)} !important;
        width: max-content;
      }
    }
  }
  .error-label,
  .error-label * {
    font-size: ${baseVariables.font.fontXs};
    color: ${baseVariables.color.alert50} !important;
  }

  .create-account__link {
    @media ${baseVariables.mediaQuery.md} {
      align-items: center;
    }
  }

  .link-underline {
    font-size: ${toRem(16)};
    display: inline-block;
    width: fit-content;
  }
  .m-badge-inline-neutral {
    padding :0;
    background-color: ${baseVariables.color.merch50} !important;
    color: ${baseVariables.color.neutral40} !important;
    text-align: unset !important;
  }
 .dropdown-field {
    .dropdown__container:is(${constants.RTL_LANG_LIST}) {
      right: 0;
      left: auto;
    }
    label {
      left: ${toRem(16)}; // to override left spacing when ellipsis gets added
      @media ${baseVariables.mediaQuery.lg} {
          left: ${toRem(20)}; // to override left spacing when ellipsis gets added
      }
    }
    label:is(${constants.RTL_LANG_LIST}) {
      left: ${toRem(8)};
      @media ${baseVariables.mediaQuery.lg} {
          left: ${toRem(12)};
      }
    }
  }
  .create_account__checkbox:is(${constants.RTL_LANG_LIST}) {
    label{
      ::before {
        left:unset;
        display:flex;
      }
      ::after {
        left:unset;
        right: ${toRem(6)};
        transform: rotateZ(45deg) !important;
      }
      div{
        padding-right:${toRem(24)};
      }
    }
  }
  .account-page-error-msg:is(${constants.RTL_LANG_LIST}) {
    .m-message-inline{
      text-align:right;
    }
  }
  .account-page-error-msg p {
    margin-bottom: 0 !important;
  }
  .create-password-eaa {
    .password-checks {
      margin-bottom: ${toRem(16)} !important;
    }
  }

  .resend-code{
    p {
    margin-bottom: 0 !important;
   }
  }
  .is-disabled {
    .password-checks {
      &__description {
        color: ${baseVariables.color.neutral30} !important;
      }
    }
  }
  .create-account-password {
    .password-checks {
      &__errorlabel {
        font-weight: 400 !important;      
      }
    } 
  }
  `}
`;
