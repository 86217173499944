/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useMemo, useRef } from 'react';
import { useRouter } from 'next/router';
import { inspect } from 'util';
import { useQuery, useMutation } from '@apollo/client';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { USER_STATE_AUTHENTICATED } from '../../modules/utils/constants/constants';
import { useAccountPersistentStore } from '../../modules/store/accountPersistentStore';
import {
  DATALAYER_PAGE_EVENT,
  PROMOTIONS_CONSTANT,
  PageContext,
  addSubDirectoryPrefix,
  apiLogger,
  generateApolloClientHeaders,
  updateDataLayerProperties,
  updateImageDomain,
} from '../../modules';
import {
  phoenixAccountGetGlobalPromotionDetail,
  phoenixAccountRegisterPromotion,
  phoenixAccountGetLoyaltyPromotionDetail,
} from '../../modules/graph';

import PromotionBannerMockdata from './__mock__/PromotionBanner.model.json';

import { useStore } from '../../modules/store/memberLevelStore';
import { BannerContent } from './BannerContent/BannerContent';
import { StyledPromotionBanner } from './PromotionBanner.styles';

export const PromotionBanner = (props: any) => {
  const { model } = props;
  const { IS_LOCAL_DEV } = process.env;
  const isAuthorMode = props?.isAuthorMode;

  const router = useRouter();
  const promotion = router && router.query['promotion'];
  const { promotionDetails, setPromotionDetails, setIsPromotionDetailsLoading } = useStore(state => state);
  const { promoRegistered, setPromoRegistered } = useAccountPersistentStore(state => state);
  const pageContext = useContext(PageContext);
  const dataLoaded = useRef<boolean>(false);
  const skipDatalayerUpdate = useRef<boolean>(false);
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const isLoggedIn = pageContext?.userState === USER_STATE_AUTHENTICATED;
  const currentLocale = pageContext?.currentLocale ?? 'en_US';

  // Memoize all variables that affect the query,
  // to prevent re-triggering useQuery if component re-renders.
  const skipQuery =
    useMemo(() => {
      return !pageContext?.sessionData && !isAuthorMode;
    }, [pageContext, isAuthorMode]) ||
    dataLoaded.current ||
    isAuthorMode;

  const promotionQuery = useMemo(() => {
    if (isLoggedIn) {
      return phoenixAccountGetLoyaltyPromotionDetail;
    }
    return phoenixAccountGetGlobalPromotionDetail;
  }, [sessionData?.consumerID]);

  const {
    loading: loadingLoyaltyPromotion,
    error: errorLoyaltyPromotion,
    data: loyaltyPromotionData,
  } = useQuery(promotionQuery, {
    variables: {
      customerId: sessionData?.consumerID,
      loyaltyPromotionId: promotion && promotion,
      promotionId: promotion && promotion,
    },
    skip: skipQuery,
    context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
  });

  useEffect(() => {
    if (isAuthorMode) {
      setPromotionDetails(PromotionBannerMockdata.data);
    }
  }, []);

  useEffect(() => {
    if (promoRegistered && !skipDatalayerUpdate?.current) {
      const dataLayerProps = { mr_register_for_promo: promoRegistered };
      updateDataLayerProperties(dataLayerProps, DATALAYER_PAGE_EVENT.PROMOTION);
      setPromoRegistered && setPromoRegistered('');
    }
  }, [promoRegistered]);

  useEffect(() => {
    setIsPromotionDetailsLoading(loadingLoyaltyPromotion);
  }, [loadingLoyaltyPromotion]);

  const [registerLoyaltyPromotions] = useMutation(phoenixAccountRegisterPromotion);

  useEffect(() => {
    if (errorLoyaltyPromotion) {
      routeToUnavailable();
    }
  }, [errorLoyaltyPromotion]);

  useEffect(() => {
    if (loyaltyPromotionData) {
      setPromotionDetails(updateImageDomain(loyaltyPromotionData, currentLocale));
      apiLogger(
        `[PromotionDetail] getPromotionDetailData - sessionId value: ${sessionData?.sessionToken}: ${inspect(
          loyaltyPromotionData
        )}`
      );
    }
  }, [loyaltyPromotionData]);
  const routeToUnavailable = () => {
    if (promotion) {
      router.push({
        pathname: addSubDirectoryPrefix(PROMOTIONS_CONSTANT.PROMOTION_UNAVAILABLE_URL),
        query: { promotion: promotion },
      });
    }
  };

  const registerPromotion = () => {
    registerLoyaltyPromotions({
      variables: {
        input: {
          customerId: sessionData?.consumerID,
          ids: [
            {
              id: promotion && promotion,
            },
          ],
        },
      },
      context: generateApolloClientHeaders(IS_LOCAL_DEV === 'true', pageContext),
      onCompleted: (data: any) => {
        skipDatalayerUpdate.current = true;
        setPromoRegistered && setPromoRegistered(promotion?.toString());
        window.location.reload();
        apiLogger(
          `[RegisterPromotion] updateCustomerSavedProperties - sessionId value: ${sessionData?.sessionToken}: ${inspect(
            data
          )}`
        );
      },
      onError: (error: any) => {
        apiLogger(
          `[RegisterPromotion] updateCustomerSavedProperties - sessionId value: ${
            sessionData?.sessionToken
          } - error: ${inspect(error)}`
        );
      },
    });
  };
  return (
    <StyledPromotionBanner data-component-name="o-account-promotionbanner" data-testid="promotionbanner">
      <div className="pb-item">
        {!loyaltyPromotionData ? (
          <div className=" pb-item__skeleton d-flex d-md-block d-lg-flex flex-column align-items-center align-items-lg-start offset-md-0 offset-lg-0">
            <div className="skeleton-loader mx-sm-auto mx-md-0 pb-item__skeleton__img"></div>
          </div>
        ) : (
          <BannerContent
            model={model}
            promotionDetail={promotionDetails}
            locale={currentLocale}
            isLoggedIn={isLoggedIn}
            registerPromotion={registerPromotion}
          />
        )}
      </div>
    </StyledPromotionBanner>
  );
};

export const PromotionBannerConfig = {
  emptyLabel: 'promotionbanner',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/promotionbanner`,
};
export const PromotionBannerEditable = (props: any) => {
  return (
    <EditableComponent config={PromotionBannerConfig} {...props}>
      <PromotionBanner {...props} />
    </EditableComponent>
  );
};
