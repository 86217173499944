import React, { FC, useState, useId, useContext, useEffect } from 'react';
import clsx from 'clsx';
import axios from 'axios';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { Modal, CheckBox, InputTextField, RichText, Messages, Types, Text } from '@marriott/mi-ui-library';
import { useClientEnvVarsStore, useUserDetailsGlobalStore } from '@marriott/mi-store-utils';

import {
  PageContext,
  addSubDirectoryPrefix,
  handleRedirect,
  PROFILE_CONSTANTS,
  PROFILE_REDIRECTION_URL,
  BANNER_MSGS_KEYS,
  changePwrdURL,
  getCurrentUrlParams,
  validateField,
  encryptPassword,
  EDIT_CHANGE_PASSWORD_ID,
  EDIT_CHANGE_PWRD_QUERY_PARAM,
  PHOENIX_SEND_OTP_CHALLENGE_URL,
  scrollToClass,
  checkChinaLocale,
} from '../../modules';
import { useProfileStore } from '../../modules/store/profileStore';
import { useBannerMessagesStore } from '../../modules/store/bannerMessagesStore';
import { useAccountPersistentStore } from '../../modules/store/accountPersistentStore';
import { PasswordInput } from '../../molecules/PasswordInput';
import { EditChangePasswordProps, FormData, FormError, CustomError } from './EditChangePassword.types';
import { StyledEditChangePassword } from './EditChangePassword.styles';

export const EditChangePassword: FC<EditChangePasswordProps> = ({ model }) => {
  const passwordCondArr = [
    {
      condition: model?.pwrdCharCheck ?? '',
      isValid: false,
    },
    {
      condition: model?.pwrdUpperCaseCheck ?? '',
      isValid: false,
    },
    {
      condition: model?.pwrdLowerCaseCheck ?? '',
      isValid: false,
    },
    {
      condition: model?.pwrdSpecialCharCheck ?? '',
      isValid: false,
    },
  ];

  const clientEnvVars = useClientEnvVarsStore.getState().envVarsObject;
  const { IS_PASSWORD_ENCRYPT: isEncryptionEnable } = clientEnvVars;
  const inputId = useId();
  const checkBoxId = useId();
  const pageContext = useContext(PageContext);
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const { memberData } = useAccountPersistentStore(store => store);
  const { setPartialUserInfo } = useUserDetailsGlobalStore(state => state);
  const isChinaLocale = checkChinaLocale(pageContext?.currentLocale);

  const publicKey = pageContext?.rsaPublicKey;
  const isStepUpAuthenticated = sessionData?.stepUpAuthenticated;
  const modalBodyClass = 'change-password-modal-body';

  const userName = memberData?.customer?.contactInformation?.emails[0]?.address || '';
  const [formData, setFormData] = useState<FormData>({
    email: userName,
    currentPwrd: '',
    newPwrd: '',
    confirmPwrd: '',
  });
  const [errors, setErrors] = useState<FormError>();
  const [errorsList, setErrorsList] = useState<string[]>([]);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isRememberMe, setIsRememberMe] = useState(true);
  const [passwordConditions, setPasswordConditions] = useState(passwordCondArr);
  const [passwordValid, setPasswordValid] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { profileModalId, setProfileModalId, profileDetails } = useProfileStore(state => state);
  const { setBannerMsgs } = useBannerMessagesStore(state => state);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);

  const getPhoneOption = () => {
    const phoneOptions = profileDetails?.phoneNumber;
    const phone = phoneOptions?.find(item => item.phoneType === PROFILE_CONSTANTS.PHONE_TYPE.DEFAULT);
    return phone?.phoneNumber;
  };

  useEffect(() => {
    const urlParam = getCurrentUrlParams();
    const param = new URLSearchParams(urlParam);
    const isEditChangePwrdModal = param.get(EDIT_CHANGE_PWRD_QUERY_PARAM);
    if (isEditChangePwrdModal === 'true') {
      if (!isStepUpAuthenticated) {
        const redirectUrl = `${PHOENIX_SEND_OTP_CHALLENGE_URL}?phxRedirect=true&returnTo=${encodeURIComponent(
          PROFILE_REDIRECTION_URL
        )}`;
        handleRedirect(addSubDirectoryPrefix(redirectUrl), false, false);
      } else {
        setProfileModalId(EDIT_CHANGE_PASSWORD_ID);
      }
    }

    return () => {
      resetPasswordFields();
    };
  }, []);

  // validate password
  const validatePassword = (value: string) => {
    const str = value;
    const passwordArr = [...passwordConditions];
    if (passwordArr[0]) {
      passwordArr[0].isValid = 8 <= str.length && str.length <= 20;
    }
    if (passwordArr[1]) {
      passwordArr[1].isValid = !!str.match(/[A-Z]/g);
    }
    if (passwordArr[2]) {
      passwordArr[2].isValid = !!str.match(/[a-z]/g);
    }
    if (passwordArr[3]) {
      passwordArr[3].isValid = !!str.match(/^(?=.*[0-9$!#&@?%=_])[A-Za-z0-9$!#&@?%=_]{1,}$/g);
    }
    setPasswordConditions([...passwordArr]);
  };
  // Verify that all password checks are true.
  useEffect(() => {
    const isPasswordValid = passwordConditions.reduce((acc, curr) => acc && curr.isValid, true);
    setPasswordValid(isPasswordValid);
  }, [passwordConditions]);
  // Ensure that the confirmation password matches the new password.
  const matchConfirmPassword = () => {
    let isValid = true;
    const errObj: Partial<FormError> = {};
    if (formData?.newPwrd && formData?.confirmPwrd && formData?.newPwrd !== formData?.confirmPwrd) {
      errObj.newPwrdConfirmPwrdMismatchError = model?.confirmPwrdAndNewPwrdMismatchErrMsg || '';
      setErrors(prevErrors => ({
        ...prevErrors,
        ...errObj,
      }));
      setErrorsList((prevErrorsList: string[]) => [...prevErrorsList, ...(Object.values(errObj) as string[])]);
      isValid = false;
    }
    return isValid;
  };

  // validate fields
  const validateChangePasswordFields = () => {
    const newErrors: Partial<FormError> = {};
    let isValid = true;
    if (validateField(formData?.currentPwrd || '')) {
      newErrors.currentPwrdError = model?.currentPwdRequiredErrMsg;
      isValid = false;
    }
    if (validateField(formData?.newPwrd || '')) {
      newErrors.newPwrdError = model?.newPwdRequiredErrMsg;
      isValid = false;
    }
    if (validateField(formData?.confirmPwrd || '')) {
      newErrors.confirmPwrdError = model?.confirmNewPwdRequiredErrMsg;
      isValid = false;
    }
    setErrors(newErrors);
    setErrorsList(Object.values(newErrors));
    return isValid && matchConfirmPassword();
  };

  const resetPasswordFields = () => {
    setFormData({ email: userName, currentPwrd: '', newPwrd: '', confirmPwrd: '' });
    setErrorsList([]);
    setErrors({});
    setPasswordConditions(passwordCondArr);
  };

  const handleSubmit = async () => {
    try {
      if (!validateChangePasswordFields()) {
        scrollToClass(modalBodyClass);
        return;
      }
      const response = await axios.post(
        addSubDirectoryPrefix(changePwrdURL),
        {
          userName: userName,
          oldPwrd: encryptPassword(formData?.currentPwrd, publicKey, isEncryptionEnable),
          newPwrd: encryptPassword(formData?.newPwrd, publicKey, isEncryptionEnable),
          confirmNewPwrd: encryptPassword(formData?.confirmPwrd, publicKey, isEncryptionEnable),
          rememberMe: isRememberMe,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Cookie: 'sessionID=' + sessionData?.sessionToken,
          },
        }
      );
      if (response.status === 200) {
        //* update the store for profile page
        const url = new URL(window.location.href);
        url.searchParams.delete(EDIT_CHANGE_PWRD_QUERY_PARAM);
        window.history.replaceState(null, '', url);
        setPartialUserInfo({ isRememberMe: isRememberMe });
        setBannerMsgs(BANNER_MSGS_KEYS.PASSWORD_UPDATED_MSG);
        handleResetData();
      }
    } catch (error) {
      if (error) {
        if ((error as CustomError)?.response?.data?.isRedirect) {
          handleRedirect((error as CustomError)?.response?.data?.nextStateURI, false);
        } else {
          const errMessageType = (error as CustomError)?.response?.data?.phoenixErrorMessages?.errorMessages[0];
          if (errMessageType) {
            setBannerMsgs(errMessageType || '');
            setProfileModalId('');
            resetPasswordFields();
          } else {
            setHasError(true);
            scrollToClass(modalBodyClass);
          }
        }
      } else {
        setHasError(true);
        scrollToClass(modalBodyClass);
      }
    }
  };

  const handleResetData = () => {
    setProfileModalId('');
    resetPasswordFields();
    setHasError(false);
    setIsSaveButtonEnabled(false);
  };

  return (
    <StyledEditChangePassword data-testid="editchangepassword" data-component-name="o-account-editchangepassword">
      <Modal
        show={profileModalId === model?.modalId}
        handleBlur={profileModalId === model?.modalId}
        popupOpenState={profileModalId === model?.modalId}
        setPopupOpenState={(): void => {
          handleResetData();
        }}
        modalId={model?.modalId + 'modal'}
        labelledBy={model?.modalId}
        onClose={(): void => {
          handleResetData();
        }}
        secondaryClassName="modal__container"
        disableScrollOnBody={true}
        aria-modal="true"
        role="dialog"
      >
        <Modal.Header
          className="t-subtitle-xl py-3 py-md-4 pl-4 pr-4 px-md-5 pt-md-5"
          customHeadingClass="t-subtitle-xl"
          labelText={model?.title || ''}
          popupHeaderOnCLoseFunc={(): void => {
            handleResetData();
          }}
          data-testid="edit-change-password-header"
        />
        <Modal.Body className="modal__container-content custom-scrollbar px-4 px-md-5 py-4 mt-md-2 mb-3 mx-2 color-scheme1 change-password-modal-body">
          {profileModalId === model?.modalId ? (
            <div>
              {hasError && (
                <div data-testId="uxl-error-msg" className="uxl-error-msg">
                  <Messages messageType="warning" className="mb-4">
                    <RichText text={pageContext?.uxlErrorMessage} componentId="uxl-error-msg" />
                  </Messages>
                </div>
              )}
              {/* warning message */}
              {!!errorsList?.length && (
                <div className="">
                  <Messages messageType="error-sev1" className="mt-3 account-page-error-msg mb-4 px-2">
                    {!!errorsList?.length && (
                      <>
                        <Text
                          copyText={model?.errMsgCommonHeader}
                          fontSize={Types.size.small}
                          customClass="t-subtitle-m mt-5"
                          element={Types.tags.span}
                          data-testid="modal-heading"
                        />
                        {errorsList.map(error => (
                          <ul>
                            <li>
                              <RichText text={error} componentId="edit-chng-pwrd-error" />
                            </li>
                          </ul>
                        ))}
                      </>
                    )}
                  </Messages>
                </div>
              )}
              <InputTextField
                type={'email'}
                label={isChinaLocale ? model?.emailPhoneLabel : model?.email}
                inputValue={isChinaLocale ? getPhoneOption() || userName : userName}
                inputMaxLength={20}
                getLabelProps={() => ({
                  htmlFor: `${inputId}-email`,
                })}
                showErrorMessage={errors?.emailError !== undefined}
                messageToShow={errors?.emailError}
                setErrorHtml={true}
                className="m-input-field mt-5 is-disabled edit-password__email"
                showIcon={true}
                messageClass="error-label m-0 t-label-s"
                getInputValue={() => {
                  setFormData({ ...formData, email: userName });
                }}
                getInputProps={() => ({
                  /** setReadOnly props for remeber case */
                  readOnly: true,
                  autoComplete: 'off',
                  id: `${inputId}-email`,
                })}
                inputTextFieldClassName={'custom-value-color'}
              />
              <PasswordInput
                value={formData.currentPwrd}
                id="password"
                name="password"
                disable={false}
                hasError={!!errors?.currentPwrdError}
                label={model?.currPwdLabel || ''}
                passwordType={showCurrentPassword ? 'text' : 'password'}
                error={errors?.currentPwrdError}
                customClass="mt-5 edit-password__password"
                iconClass={clsx(!showCurrentPassword ? `icon-visibility` : 'icon-visibility-off')}
                labelClass="t-body-s"
                showValidations={true}
                handleChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
                  setFormData({ ...formData, currentPwrd: e.target.value });
                  setIsSaveButtonEnabled(true);
                }}
                onIconClick={() => setShowCurrentPassword(!showCurrentPassword)}
                isValid={passwordValid}
                showCheckIcon={false}
              />
              <PasswordInput
                value={formData.newPwrd}
                id="newpassword"
                name="newpassword"
                disable={false}
                hasError={!!errors?.newPwrdError}
                label={model?.newPwrdLabel || ''}
                passwordType={showNewPassword ? 'text' : 'password'}
                error={errors?.newPwrdError}
                customClass="mt-5 edit-password__newPassword"
                iconClass={clsx(!showNewPassword ? `icon-visibility` : 'icon-visibility-off')}
                labelClass="t-body-s"
                showValidations={true}
                handleChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
                  setFormData({ ...formData, newPwrd: e.target.value });
                  validatePassword(e.target.value);
                  setIsSaveButtonEnabled(true);
                }}
                onIconClick={() => setShowNewPassword(!showNewPassword)}
                isValid={passwordValid}
                passwordConditions={passwordConditions}
                showCheckIcon={true}
              />

              <PasswordInput
                value={formData.confirmPwrd}
                id="confirmpassword"
                name="confirmpassword"
                disable={!passwordValid && true}
                hasError={(!!errors?.confirmPwrdError && passwordValid) || !!errors?.newPwrdConfirmPwrdMismatchError}
                label={model?.confirmPwrdLabel || ''}
                passwordType={showConfirmPassword ? 'text' : 'password'}
                error={errors?.confirmPwrdError ?? errors?.newPwrdConfirmPwrdMismatchError}
                customClass="mt-5 edit-password__confirmPassword"
                iconClass={clsx(!showConfirmPassword ? `icon-visibility` : 'icon-visibility-off')}
                labelClass="t-body-s"
                showValidations={true}
                handleChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
                  setFormData({ ...formData, confirmPwrd: e.target.value });
                  setIsSaveButtonEnabled(true);
                }}
                onIconClick={() => setShowConfirmPassword(!showConfirmPassword)}
                isValid={formData?.confirmPwrd?.length && formData?.confirmPwrd === formData?.newPwrd ? true : false}
                showCheckIcon={true}
              />
              <div className={clsx('mt-5 checkbox-remember')}>
                <CheckBox
                  onChange={() => {
                    setIsRememberMe(!isRememberMe);
                    setIsSaveButtonEnabled(true);
                  }}
                  checked={isRememberMe}
                  data-testId="checkbox_consent"
                  checkboxName="checkbox_consent"
                  checkboxLabel={model?.rememberMeLabel}
                  checkboxId={checkBoxId}
                  className="t-font-m"
                />
              </div>
              <RichText customClass="t-font-s mt-5" text={model?.contactUs || ''} componentId={'contactus'} />
            </div>
          ) : (
            <></>
          )}
        </Modal.Body>
        <Modal.Footer
          className="modal__container-footer m-auto px-5 py-4 py-md-5 align-items-baseline justify-content-md-end"
          clearButtonLabel={model?.cancelButton}
          clearBtnClassname="clear m-link-action mx-md-4 p-0 px-auto px-md-0 custom_click_track clear-btn"
          clearClickHandler={(): void => {
            handleResetData();
          }}
          applyButtonDisabled={!isSaveButtonEnabled}
          applyLabel={model?.submitButtonLabel}
          data-testid="Save"
          applyBtnClickHandler={() => {
            handleSubmit();
          }}
        ></Modal.Footer>
      </Modal>
    </StyledEditChangePassword>
  );
};

export const EditChangePasswordConfig = {
  emptyLabel: 'editchangepassword',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/editchangepassword`,
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EditChangePasswordEditable = (props: any) => {
  return props?.cqPath?.includes('datapagecomponent') ? (
    <EditChangePassword {...props} />
  ) : (
    <EditableComponent config={EditChangePasswordConfig} {...props}>
      <EditChangePassword {...props} />
    </EditableComponent>
  );
};
