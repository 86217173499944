import styled from 'styled-components';
import { theme, toRem } from '../../styles';

export const StyledComp = styled.section`
  .display-none {
    display: none !important;
  }
  .display-visible {
    display: contents !important;
  }
  .border {
    border-bottom: ${toRem(1)} solid ${theme.colors['light-gray-6']};
    padding-bottom: ${toRem(45)};
  }
  .hqv-modal-content {
    overflow-x: hidden;
    height: 68vh;
    overflow-y: auto;
    margin-top: ${toRem(12)};
    @media ${theme.mediaQuery.mobileTablet} {
      padding-left: ${toRem(8)};
    }
  }

  .custom-scrollbar {
    ::-webkit-scrollbar {
      width: ${toRem(5)};
    }

    ::-webkit-scrollbar-track {
      background: ${theme.colors.white};
      border-radius: ${toRem(2)};
      margin: ${toRem(12)} 0;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${theme.colors['light-gray-9']};
      border-radius: ${toRem(2)};
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${theme.colors['dark-gray-10']};
    }
  }
`;
