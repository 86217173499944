/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState, useContext, useRef } from 'react';
import moment from 'moment';
import axios from 'axios';
import clsx from 'clsx';
import { inspect } from 'util';
import { useRouter } from 'next/router';

import { EditableComponent } from '@adobe/aem-react-editable-components';
import { Types, Button, Text, InputTextField, Heading, RichText, Messages } from '@marriott/mi-ui-library';
import { getUserDetails, useClientEnvVarsStore, useUserDetailsGlobalStore } from '@marriott/mi-store-utils';

import {
  apiLogger,
  checkChinaLocale,
  getCountryAndLangCode,
  CHINESE_MOBILE_CODE,
  logger,
  handleRedirect,
  clearSessionAndCookie,
} from '../../modules/utils';
import { updateUserLoginDataLayer } from '../../modules/utils/userLogin.helper';
import {
  constants,
  BANNER_MSGS_KEYS,
  PROFILE_REDIRECTION_URL,
  MFA_OPTIONS_KEY,
  CONSENT_FORM_DECRYPT_ENDPOINT,
  generateOneTimePwrdForMobileUrl,
  API_ERROR_KEY,
  FORGOT_PWRD_ID,
  TRACKING_PAGE_NAME,
  NON_SUPPORTED_PHONE_NUMBER,
  DUPLICATE_PHONE_ERROR_KEY,
} from '../../modules/utils/constants/constants';
import { useAccountPersistentStore } from '../../modules/store/accountPersistentStore';
import { useBannerMessagesStore } from '../../modules/store/bannerMessagesStore';
import { useStore } from '../../modules/store/memberLevelStore';
import {
  getCurrentUrlParams,
  encryptPassword,
  getRedirectUrlFromParams,
  checkMobileNumValidity,
  isCoutriesSame,
  addSubDirectoryPrefixForNonEaa,
  scrollToClass,
  canUseDOM,
} from '../../modules/utils/helper';
import { addSubDirectoryPrefix, useCheckBreakpoint } from '../../modules';
import { MASKED_PHONE, submitCreateOnlineAccount, newPwrdAPI } from '../../modules/utils/constants/constants';
import { PageContext } from '../../modules/context/PageContext';
import { PhoneDetails } from '../ActivateOrForgotForm/ActivateOrForgotForm.types';
import { PasswordInput } from '../PasswordInput';
import { PhoneNumberField } from '../PhoneNumberField';
import { OtpTimer } from '../OtpTimer';
import { SetPasswordProps, FormData, FormError, ApiError } from './SetPassword.types';
import { StyledSetPassword } from './SetPassword.styles';

const initialFormData: FormData = {
  email: '',
  password: '',
  confirmPwrd: '',
  mobilePhone: '',
  verificationCode: '',
};

const { log } = logger({})('SetPassword');
const changePasswordInitalFormData: FormData = {
  password: '',
  confirmPwrd: '',
};

// Use named rather than default exports.
export const SetPassword: FC<SetPasswordProps> = ({
  isDTT,
  isFormDisabled,
  authorModelData,
  userDetails,
  setPageError,
  setTypeTwoErrorHeader,
  setDisplayTypeOneError,
  phoneDetails,
  trackingPageName,
  isChangePwrd, //use this for showing error on resettoken expiry
}) => {
  const [transactionId, setTransactionId] = useState<PhoneDetails>();
  const passwordCondArr = [
    {
      condition: authorModelData?.pwrdCharCheck,
      isValid: false,
    },
    {
      condition: authorModelData?.pwrdUpperCaseCheck,
      isValid: false,
    },
    {
      condition: authorModelData?.pwrdLowerCaseCheck,
      isValid: false,
    },
    {
      condition: authorModelData?.pwrdSpecialCharCheck,
      isValid: false,
    },
  ];

  const clientEnvVars = useClientEnvVarsStore.getState().envVarsObject;
  const { HOMEPAGE_REDIRECT_URL: homePageRedirectUrl, IS_PASSWORD_ENCRYPT: isEncryptionEnable } = clientEnvVars;
  const pageContext = useContext(PageContext);
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const isEAA = pageContext?.isEAASignIn;
  const { isMobileAuthFeatureEnabled, isNonChinaMobileEnabled, currentLocale } = pageContext;
  const emailInputRef = useRef<any>(null);
  const phoneInputRef = useRef<HTMLDivElement | null>(null);
  const otpInputRef = useRef<HTMLDivElement | null>(null);
  const [passwordType, setPasswordType] = useState<'text' | 'password'>('password');
  const [confirmPasswordType, setConfirmPasswordType] = useState<'text' | 'password'>('password');
  const [errors, setErrors] = useState<FormError>({});
  const [errorsList, setErrorsList] = useState<string[]>([]);
  const [displayTypeOneErrorInside, setDisplayTypeOneErrorInside] = useState(false);
  const [formData, setFormData] = useState<FormData>(isChangePwrd ? changePasswordInitalFormData : initialFormData);
  const [passwordConditions, setPasswordConditions] = useState(passwordCondArr);
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const isDesktopAndAbove = useCheckBreakpoint('viewportL');
  let pageLevelErrors: string[] = [];
  const { countryCode } = getCountryAndLangCode(currentLocale);
  const router = useRouter();
  const { resetToken, mobileEditable, validateToken } = router.query;
  const isMobileEditable = mobileEditable === 'true' || userDetails?.mobileEditable;
  const countryDialCode = authorModelData?.countriesDialCodes?.find(
    (d: any) => d.id?.toUpperCase() === countryCode?.toUpperCase()
  )?.value;
  const [selectedCountryCode, setSelectedCountryCode] = useState(countryDialCode);
  const typeOneErrorKeys = [
    'fcpEnabledErrorMessage',
    'pwrdSubmissionFail',
    'signApiFailure',
    'inActiveUserErrorMsg',
    'expiredOtpWarningMessage',
    API_ERROR_KEY,
  ];
  const [maskedPhone, setMaskedPone] = useState('');
  const { setMemberDetailsUxl } = useAccountPersistentStore(state => state);
  const publicKey = pageContext?.rsaPublicKey;
  const { setBannerMsgs, setBannerId, setInterPageErrorMsg } = useBannerMessagesStore();
  const { communicationOption, setCommunicationOption } = useAccountPersistentStore(state => state);
  const { rememberedUser } = useUserDetailsGlobalStore(state => state);
  const { setShowCustomMessage } = useStore(state => state);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [showSendCode, setShowSendCode] = useState(true);
  const resetForm = (fieldName: string) => {
    const newErrors: any = { ...errors };

    switch (fieldName) {
      case 'password':
        newErrors.newPwrdRequired = '';
        break;
      case 'confirmPwrd':
        newErrors.confirmPwrdAndPwrdMatch = '';
        break;
      case 'email':
        newErrors.emailWarning = '';
        newErrors.emailRequired = '';
        break;
      default:
    }
    setErrors(newErrors);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    value && resetForm(name);
    if (name === 'password') {
      validatePassword(value);
    }
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };
  const matchConfirmPassword = () => {
    let isValid = true;
    const errObj: any = {};
    if (formData.confirmPwrd !== formData.password) {
      errObj.confirmPwrdAndPwrdMatch = authorModelData?.confirmPwrdAndPwrdMatch;
      pageLevelErrors.push(authorModelData?.confirmPwrdMustMatchPwrd);
      isValid = false;
    } else {
      errObj.confirmPwrdAndPwrdMatch = undefined;
      isValid = true;
    }
    setErrors({ ...errors, ...errObj });
    return isValid;
  };
  const checkPasswordAndEmail = () => {
    let isValid = true;
    const errObj: any = {};
    if (!isChangePwrd) {
      if (!checkChinaLocale(currentLocale || '') && !formData.email?.trim()) {
        errObj.emailRequired = authorModelData?.emailRequired;
        pageLevelErrors.push(authorModelData?.emailRequired);
        isValid = false;
      } else if (checkChinaLocale(currentLocale || '')) {
        if (isChinesePhoneNumberValid()) {
          errObj.emailRequired = undefined;
        } else if (!formData.email?.trim()) {
          errObj.emailRequired = authorModelData?.emailRequired;
          pageLevelErrors.push(authorModelData?.emailRequired);
          isValid = false;
        }
      }
    }
    if (!formData.password?.trim()) {
      errObj.newPwrdRequired = authorModelData?.newPwrdRequired;
      pageLevelErrors.push(authorModelData?.newPwrdRequired);
      isValid = false;
    } else {
      errObj.newPwrdRequired = undefined;
    }
    if (
      ((!isEAA && !isChangePwrd && isMobileAuthFeatureEnabled) ||
        ((isEAA || checkChinaLocale(currentLocale || '')) && isChinesePhoneNumberValid())) &&
      !checkMobileNumValidity(formData?.mobilePhone)
    ) {
      errObj.invalidMobilePhone = authorModelData?.invalidMobilePhone;
      pageLevelErrors.push(authorModelData?.invalidMobilePhoneMsg);
      pageLevelErrors.push(authorModelData?.invalidMobilePhone);
      isValid = false;
    } else {
      errObj.mobilePhoneRequired = undefined;
    }
    if (
      (((isEAA || checkChinaLocale(currentLocale || '')) && isChinesePhoneNumberValid()) || validateToken) &&
      !formData.verificationCode?.trim()
    ) {
      errObj.verificationCodeError = authorModelData?.verificationCodeError;
      pageLevelErrors.push(authorModelData?.verificationCodeError);
      isValid = false;
    } else {
      errObj.verificationCodeError = undefined;
    }
    setErrors({ ...errors, ...errObj });
    return isValid;
  };
  const validatePassword = (value: string) => {
    const str = value;
    const passwordArr = [...passwordConditions];
    if (passwordArr[0]) {
      passwordArr[0].isValid = 8 <= str.length && str.length <= 20;
    }
    if (passwordArr[1]) {
      passwordArr[1].isValid = !!str.match(/[A-Z]/g);
    }
    if (passwordArr[2]) {
      passwordArr[2].isValid = !!str.match(/[a-z]/g);
    }
    if (passwordArr[3]) {
      passwordArr[3].isValid = !!str.match(/^(?=.*[0-9$!#&@?%=_])[A-Za-z0-9$!#&@?%=_]{1,}$/g);
    }
    setPasswordConditions([...passwordArr]);
  };

  const getUserRemeberDetails = () => {
    try {
      const sessionToken =
        sessionData?.sessionToken || sessionData?.data?.sessionToken || sessionData?.data?.cacheData?.sessionToken;
      log.debug(`[User Remember] function called sessionID: ${sessionToken}`);
      getUserDetails(false, isDTT);
    } catch (e) {
      log.debug(`[User Remember] error sessionID: ${sessionData?.sessionToken}, ${inspect(e)}`);
    }
  };

  useEffect(() => {
    const mskPhn = sessionStorage?.getItem(MASKED_PHONE) || '';
    setMaskedPone(mskPhn);
    isChangePwrd && !isEAA && getUserRemeberDetails();
  }, []);

  // set phone number value when recieved from API
  useEffect(() => {
    if (phoneDetails) {
      setFormData(prevData => ({
        ...prevData,
        mobilePhone: phoneDetails?.phoneNumber?.includes(NON_SUPPORTED_PHONE_NUMBER)
          ? ''
          : phoneDetails?.phoneNumber ?? '',
        email: phoneDetails?.emailAddress ?? '',
      }));
    }
  }, [phoneDetails]);

  useEffect(() => {
    const isPasswordValid = passwordConditions.reduce((acc, curr) => acc && curr.isValid, true);
    setPasswordValid(isPasswordValid);
  }, [passwordConditions]);

  useEffect(() => {
    if (!isFormDisabled) {
      //focus on phone number field if it is present, else focu on email field
      if (
        !isChangePwrd &&
        ((isMobileAuthFeatureEnabled && (!userDetails?.givenName || !userDetails?.surName)) ||
          ((isEAA || checkChinaLocale(currentLocale || '')) && isChinesePhoneNumberValid()))
      ) {
        if (!isMobileEditable && !!phoneDetails?.phoneNumber) {
          const otpInput = otpInputRef.current?.querySelector('input');
          if (otpInput) {
            otpInput?.focus();
          }
        } else {
          const phoneInput = phoneInputRef.current?.querySelector('input');
          if (phoneInput) {
            phoneInput?.focus();
          }
        }
      } else {
        emailInputRef?.current?.focus();
      }

      if (hasPhoneNumber) {
        setIsOtpSent(true);
        setShowSendCode(false);
      }
    }
  }, [isFormDisabled, userDetails, phoneDetails?.phoneNumber]);

  const validatePasswordFields = () => {
    pageLevelErrors = [];
    let isValid = true;
    isValid = matchConfirmPassword() && checkPasswordAndEmail();
    if (pageLevelErrors?.length) {
      setDisplayTypeOneError?.(false);
      setTypeTwoErrorHeader?.(authorModelData?.errorMessage);
    }
    setPageError?.(pageLevelErrors);
    isChangePwrd && setErrorsList?.(pageLevelErrors);
    return isValid;
  };

  const fetchMobileNumber = (obj: any) => {
    setShowSendCode(true);
    setFormData(prevData => ({ ...prevData, mobilePhone: `${obj.countryCode}${obj.mobileNumber}` }));
    setSelectedCountryCode(obj?.countryCode);
    if (obj.mobileNumber?.length) {
      setErrors({ ...errors, mobilePhoneRequired: undefined, mobilePhoneWarning: undefined });
    }
  };

  const handleSubmit = async () => {
    setIsloading(true);
    try {
      if (!validatePasswordFields()) {
        scrollToClass();
        return;
      }
      const urlParam = getCurrentUrlParams();
      if (!isChangePwrd) {
        await axios
          .post(
            addSubDirectoryPrefixForNonEaa(submitCreateOnlineAccount, isEAA),
            {
              givenName: userDetails?.givenName,
              surName: userDetails?.surName,
              emailAddress: formData.email,
              pageModuleName: trackingPageName || '',
              password: formData.password && encryptPassword(formData.password, publicKey, isEncryptionEnable),
              confirmPassword:
                formData.confirmPwrd && encryptPassword(formData.confirmPwrd, publicKey, isEncryptionEnable),
              country: userDetails?.country,
              returnUrl: !isEAA ? addSubDirectoryPrefix(PROFILE_REDIRECTION_URL) : '',
              memberName: userDetails?.memberName,
              rememberMe: '',
              postalCode: userDetails?.postalCode,
              phoneNumber: transactionId
                ? transactionId?.phoneNumber ?? formData?.mobilePhone?.includes('+')
                  ? formData?.mobilePhone
                  : `${selectedCountryCode ?? ''}${formData?.mobilePhone}`
                : phoneDetails?.phoneNumber,
              transactionId: transactionId ? transactionId?.transactionId : phoneDetails?.transactionId,
              oneTimePassword: formData?.verificationCode,
              ...(isEAA &&
                (userDetails?.prefillQueryParam
                  ? { prefillQuery: userDetails?.prefillQueryParam }
                  : urlParam
                  ? { queryParams: urlParam }
                  : '')),
            },
            {
              headers: {
                'Content-Type': 'application/json',
                Cookie: 'sessionID=' + sessionData?.sessionToken,
                isEAA: isEAA ?? '',
              },
            }
          )
          .then(data => {
            if (data.status === 200) {
              if (isEAA && !data?.data?.isRedirect) {
                setShowCustomMessage(true);
              } else {
                handleRedirect(addSubDirectoryPrefix(data?.data?.nextStateURI));
              }
            }
          });
      } else {
        const payload: any = {
          pwrd: formData.password && encryptPassword(formData.password, publicKey, isEncryptionEnable),
          confirmPwrd: formData.confirmPwrd && encryptPassword(formData.confirmPwrd, publicKey, isEncryptionEnable),
          resetToken: resetToken,
          transactionId: transactionId
            ? transactionId?.transactionId
            : encodeURIComponent(typeof validateToken === 'string' ? validateToken : ''),
          oneTimePwrd: formData.verificationCode,
          returnUrl: getRedirectUrlFromParams(isEAA, false) || homePageRedirectUrl,
          errorUrl: '/forgotPassword',
          rememberedUser: rememberedUser,
          communicationOption: communicationOption,
          pageModuleName: trackingPageName || '',
          ...(isEAA && urlParam && { queryParams: urlParam }),
        };

        await axios
          .post(
            addSubDirectoryPrefixForNonEaa(newPwrdAPI, isEAA),
            {
              ...payload,
            },
            {
              headers: {
                'Content-Type': 'application/json',
                Cookie: 'sessionID=' + sessionData?.sessionToken,
              },
            }
          )
          .then(data => {
            if (data?.data?.customerDetails) {
              clearSessionAndCookie();
              setMemberDetailsUxl?.(data?.data?.customerDetails);
            }
            if (data?.status === 200) {
              setCommunicationOption?.(''); // setting communicationOption to default when change password api is success
              if (isChangePwrd) {
                const responseData = data?.data?.mfaOption;
                if (
                  responseData &&
                  (responseData?.emailAddress || (responseData?.phoneNumbers && responseData?.phoneNumbers?.length > 0))
                ) {
                  sessionStorage.setItem(TRACKING_PAGE_NAME, trackingPageName);
                  sessionStorage.setItem(MFA_OPTIONS_KEY, JSON.stringify(responseData));
                }
              }
              setBannerId(FORGOT_PWRD_ID);
              setBannerMsgs(BANNER_MSGS_KEYS.PASSWORD_UPDATED_MSG);
              handleRedirect(addSubDirectoryPrefix(data?.data?.nextStateURI));
            }
          });
      }
    } catch (error: any) {
      if (error) {
        const errorResponse = error?.response?.data;
        if (errorResponse?.isRedirect) {
          setInterPageErrorMsg(errorResponse?.phoenixErrorMessages?.errorMessages?.[0]);
          handleRedirect(errorResponse?.nextStateURI);
        } else {
          const errorArray: any = [];
          const errorObject: any = {};

          // For M.Com china flow, we have to show expiredOtpWarningMessage error label for otp expired
          const errorBody =
            !isEAA && checkChinaLocale(currentLocale || '')
              ? {
                  errorMessages: errorResponse?.phoenixErrorMessages?.errorMessages?.map((item: string) =>
                    item === (isChangePwrd ? 'inActiveUserErrorMsg' : 'expiredTransactionCode')
                      ? 'expiredOtpWarningMessage'
                      : item
                  ),
                }
              : errorResponse?.phoenixErrorMessages;

          errorBody?.errorMessages?.map((key: string) => {
            const errorString = key === API_ERROR_KEY ? pageContext?.uxlErrorMessage : authorModelData[key];
            errorArray.push(errorString);
            errorObject[key] = errorString;
          });
          updateUserLoginDataLayer(error?.response?.headers?.['logineventdetails'] || '', false);
          if (!isChangePwrd) {
            if (
              errorBody?.errorMessages &&
              errorBody?.errorMessages?.length &&
              !errorBody?.errorMessages?.includes('incorrectVerificationCode')
            ) {
              setDisplayTypeOneError && setDisplayTypeOneError(true);
            } else {
              setDisplayTypeOneError && setDisplayTypeOneError(false);
              setTypeTwoErrorHeader && setTypeTwoErrorHeader(authorModelData?.errorMessage);
            }
            setPageError && setPageError(errorArray);
          } else {
            if (errorBody?.errorMessages?.[0] && typeOneErrorKeys.includes(errorBody?.errorMessages?.[0])) {
              setDisplayTypeOneErrorInside(true);
            } else {
              setDisplayTypeOneErrorInside(false);
            }
            setErrorsList(errorArray);
          }
          setErrors(errorObject);
        }
      }
    } finally {
      setIsloading(false);
    }
  };

  const keepDisabledForChangePwrdFlow = () => {
    let isDisabled = false;
    if (isEAA && isChangePwrd && (!formData.verificationCode || !formData.password || !formData.confirmPwrd))
      isDisabled = true;
    return isDisabled;
  };

  const communicationTokenDecrypt = () => {
    return axios.post(CONSENT_FORM_DECRYPT_ENDPOINT, {
      data: [
        {
          key: 'communicationOption',
          value: communicationOption, // to check how to get value
          algorithmKey: 'aesCBC',
        },
      ],
      clientId: 'mcom_account',
    });
  };

  const generateOtpApi = async (phoneNumber?: string) => {
    try {
      const payload = {
        phoneNumber: phoneNumber
          ? phoneNumber
          : formData?.mobilePhone?.includes('+') // for change password, to fetch data from
          ? formData?.mobilePhone
          : `${selectedCountryCode ?? ''}${formData?.mobilePhone}`,
        ...(isChangePwrd && { noAccountAssociation: true }), // WCHAT-17488 - noAccountAssociation value is passed as true to BE, so that checkAssociation flag is sent as false to UXL.
      };
      const response = await axios.post(
        addSubDirectoryPrefixForNonEaa(generateOneTimePwrdForMobileUrl, isEAA),
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          params: {
            ...(currentLocale && { locale: currentLocale }),
          },
        }
      );
      setTransactionId(response.data);
      setIsOtpSent(true);
      setShowSendCode(false);
      setDisplayTypeOneError?.(false);
      setPageError?.([]);
      setErrors({});
    } catch (error: unknown) {
      const genOtpError = error as ApiError;
      const errorResponse = genOtpError?.response?.data;
      const errorArray: string[] = [];
      const errorObject: FormError = {};
      const errorBody = errorResponse?.phoenixErrorMessages;
      errorBody?.errorMessages?.map((key: string) => {
        const errorString = key === API_ERROR_KEY ? pageContext?.uxlErrorMessage : authorModelData[key];
        const inlineError =
          key === DUPLICATE_PHONE_ERROR_KEY ? authorModelData['phoneAlreadyAssociatedInlineError'] : '';
        errorArray.push(errorString);
        inlineError && (errorObject['phoneAlreadyAssociatedInlineError'] = inlineError);
      });
      setDisplayTypeOneError && setDisplayTypeOneError(true);
      setPageError && setPageError(errorArray);
      setErrors(errorObject);
      apiLogger(
        `[GenerateOTP] GenerateTransactionDetails - sessionId value:: ${sessionData?.sessionToken} - error: ${inspect(
          error
        )}`
      );
    }
  };

  const handleOtp = async () => {
    if (isChangePwrd && communicationOption) {
      try {
        const response = await communicationTokenDecrypt();
        if (response?.status === 200) {
          generateOtpApi(response?.data?.data[0]?.value);
        }
      } catch (error: any) {
        apiLogger(
          `[EMEAConsentForm] decryptLoginCount - sessionId value: ${sessionData?.sessionToken} - error: ${inspect(
            error
          )}`
        );
      }
    } else {
      generateOtpApi();
    }
  };

  const handleTimerActiveChange = (timerState: boolean) => {
    setIsOtpSent(timerState);
  };

  const isValidChangePassword = passwordValid && !isFormDisabled;

  const isChinesePhoneNumberValid = () => {
    if (!userDetails?.givenName || !userDetails?.surName) {
      return false;
    }
    if (isMobileEditable) {
      return true;
    }
    const isUserInChina = userDetails.country === constants.CHINA_COUNTRY_CODE;
    return (
      (phoneDetails?.isChinaMobileNumber ||
        (!phoneDetails?.phoneNumber && userDetails?.givenName && userDetails?.surName)) &&
      isUserInChina
    );
  };

  const hasPhoneNumber =
    (isEAA || checkChinaLocale(currentLocale || '')) &&
    !isMobileEditable &&
    phoneDetails?.maskedNumber &&
    selectedCountryCode === CHINESE_MOBILE_CODE &&
    isChinesePhoneNumberValid();

  const isMobile = canUseDOM && window.innerWidth < 768;

  return (
    <StyledSetPassword data-component-name="m-account-SetPassword" data-testid="account-SetPassword">
      <div
        className={clsx(
          'set-password-container pt-5 pt-lg-0',
          !isChangePwrd && 'offset-lg-1',
          isFormDisabled && 'form-disabled'
        )}
      >
        {isChangePwrd ? (
          <>
            {checkChinaLocale(currentLocale || '') || validateToken ? (
              <>
                <Heading
                  variation={Types.headingType.title}
                  titleText={authorModelData?.changePwrdTitle}
                  customClass="my-5"
                  fontSize={isDesktopAndAbove ? Types.size.medium : Types.size.small}
                />

                {!!maskedPhone?.length && (
                  <RichText
                    text={authorModelData?.subTitle?.replace('{0}', maskedPhone)}
                    customClass={'mb-4 t-body-m'}
                    componentId="reset-pwrd-subtitle"
                  />
                )}
              </>
            ) : (
              <Heading
                variation={Types.headingType.title}
                titleText={authorModelData?.changePwrdTitle}
                customClass="my-4"
                fontSize={Types.size.small}
              />
            )}
            {errorsList?.length ? (
              <Messages messageType="error-sev1" className="mt-3 account-page-error-msg mb-4 px-2">
                {!!errorsList?.length &&
                  (displayTypeOneErrorInside ? (
                    <RichText text={errorsList?.[0]} componentId="password-error-message" />
                  ) : (
                    <>
                      <span>{authorModelData?.errorMessage}</span>
                      {errorsList.map(error => (
                        <ul>
                          <li>{error}</li>
                        </ul>
                      ))}
                    </>
                  ))}
              </Messages>
            ) : null}
          </>
        ) : (
          <RichText
            customClass="set-password__heading t-subtitle-xl mb-4"
            text={authorModelData?.setYourPwrd}
            componentId="set-your-password"
          />
        )}
        <div className="set-password-container__form">
          <div className="set-password-container__form--layout row">
            <div className="col-12 col-md-12">
              {isChangePwrd && (checkChinaLocale(currentLocale || '') || validateToken) && (
                <div
                  className={`m-input-field mb-4 pb-2 mt-3
                    ${
                      errors.verificationCodeError || errors?.expiredOtpWarningMessage
                        ? 'is-error'
                        : formData.verificationCode
                        ? 'is-active'
                        : ''
                    }
                  `}
                >
                  <InputTextField
                    iconClass="icon icon-clear"
                    // showIcon={otpError.otp !== undefined}
                    type="number"
                    label={authorModelData?.verificationCodeLabel}
                    inputValue={formData.verificationCode}
                    showErrorMessage={!!errors?.expiredOtpWarningMessage}
                    messageToShow=""
                    setErrorHtml={true}
                    messageClass="error-label"
                    className={clsx(
                      'm-input-field',
                      formData.verificationCode !== undefined &&
                        formData.verificationCode !== '' &&
                        errors.verificationCodeError === undefined &&
                        errors?.expiredOtpWarningMessage === undefined
                        ? 'is-active'
                        : ''
                    )}
                    getInputValue={(val: string) => {
                      setFormData({ ...formData, verificationCode: val });
                    }}
                  />

                  <div
                    className={clsx(
                      't-font-xs d-flex mt-1',
                      isFormDisabled && 'disabled-text',
                      errors?.expiredOtpWarningMessage && 'validation-msg'
                    )}
                  >
                    <RichText
                      text={
                        errors?.expiredOtpWarningMessage
                          ? authorModelData?.expiredOtpWarningInlineMessage
                          : authorModelData?.didNotGetACode
                      }
                      componentId="verification-code"
                    />

                    <div
                      onClick={handleOtp}
                      className="ml-1 pointer-cursor help-text-decoration mr-3"
                      data-testId="verify_code"
                    >
                      <RichText text={authorModelData?.resendANewCode} componentId="resend-new-code" />
                    </div>
                    {errors.verificationCodeError && <span>{errors.verificationCodeError}</span>}
                  </div>
                </div>
              )}
              {!isChangePwrd &&
                ((isMobileAuthFeatureEnabled && (!userDetails?.givenName || !userDetails?.surName)) ||
                  ((isEAA || checkChinaLocale(currentLocale || '')) && isChinesePhoneNumberValid())) && (
                  <>
                    {/* show this only when EAA or coutry is zh-cn and locale === zh-cn*/}
                    <div
                      className={`m-input-field mb-5 pb-2 ${
                        isFormDisabled ? 'is-disabled' : formData.mobilePhone ? 'is-active' : ''
                      }`}
                      ref={phoneInputRef}
                    >
                      <PhoneNumberField
                        label={
                          selectedCountryCode === CHINESE_MOBILE_CODE && isEAA && isChinesePhoneNumberValid()
                            ? authorModelData?.mobilePhone
                            : authorModelData?.mobilePhoneOptional
                        }
                        defaultCCOption={countryDialCode}
                        dropdownCCOptions={authorModelData?.countriesDialCodes}
                        mobileInputVal={
                          !isMobileEditable ? phoneDetails?.maskedNumber?.replace(countryDialCode || '', '') || '' : ''
                        }
                        isInputError={
                          !!errors?.mobilePhoneRequired ||
                          !!errors?.invalidMobilePhone ||
                          !!errors?.invalidMobilePhoneMsg ||
                          !!errors?.phoneAlreadyAssociatedInlineError
                        }
                        inputErrorMsg={''}
                        disabled={isFormDisabled || (!isMobileEditable && !!phoneDetails?.phoneNumber)}
                        fetchMobileNumber={(obj: any) => fetchMobileNumber(obj)}
                        isNonChinaMobileEnabled={isNonChinaMobileEnabled}
                        isRegisteredNumber={phoneDetails?.phoneNumber ? !isMobileEditable : false}
                        isMobileEditable={isMobileEditable}
                        dropdownId={'dropdown-label-fp-country-dial-code'}
                      />
                      <div className="d-md-flex d-block">
                        {isMobile && (
                          <>
                            {errors.mobilePhoneRequired && (
                              <span className="validation-msg t-font-xs">{errors.mobilePhoneRequired}</span>
                            )}
                            {errors?.mobilePhoneWarning && (
                              <span className="validation-msg t-font-xs">{errors?.mobilePhoneWarning}</span>
                            )}
                            {errors?.phoneAlreadyAssociatedInlineError && (
                              <span className="validation-msg t-font-xs">
                                {errors?.phoneAlreadyAssociatedInlineError}
                              </span>
                            )}
                          </>
                        )}
                        {(!phoneDetails?.phoneNumber || isMobileEditable) && showSendCode && (
                          <div
                            className={clsx(
                              't-font-xs d-flex phoen-help-text help-text-decoration mt-1 pr-2',
                              isFormDisabled && 'disabled-text'
                            )}
                            onClick={handleOtp}
                            data-testId="verify_code"
                          >
                            {authorModelData?.sendVerificationCode}
                          </div>
                        )}
                        {!isMobile && (
                          <>
                            {errors.mobilePhoneRequired && (
                              <span className="validation-msg t-font-xs ml-2 pr-5">{errors.mobilePhoneRequired}</span>
                            )}
                            {errors?.mobilePhoneWarning && (
                              <span className="validation-msg t-font-xs ml-2 pr-5">{errors?.mobilePhoneWarning}</span>
                            )}
                            {errors?.phoneAlreadyAssociatedInlineError && (
                              <span className="validation-msg t-font-xs ml-2 pr-5">
                                {errors?.phoneAlreadyAssociatedInlineError}
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div
                      className={`m-input-field mb-4 pb-2
                    ${
                      isFormDisabled
                        ? 'is-disabled'
                        : errors.verificationCodeError ||
                          errors?.incorrectVerificationCode ||
                          errors?.expiredTransactionCode ||
                          errors?.expiredOtpWarningMessage
                        ? 'is-error'
                        : formData.verificationCode
                        ? 'is-active'
                        : ''
                    }
                  `}
                      ref={otpInputRef}
                    >
                      <InputTextField
                        iconClass="icon icon-clear"
                        // showIcon={otpError.otp !== undefined}
                        type="number"
                        label={authorModelData?.verificationCodeLabel}
                        inputValue={formData.verificationCode}
                        showErrorMessage={
                          errors?.incorrectVerificationCode ||
                          errors?.expiredTransactionCode ||
                          errors?.verificationCodeError ||
                          errors?.expiredOtpWarningMessage
                            ? true
                            : false
                        }
                        messageToShow={''}
                        setErrorHtml={true}
                        messageClass="error-label"
                        className={clsx(
                          'm-input-field',
                          formData.verificationCode !== undefined &&
                            formData.verificationCode !== '' &&
                            errors.verificationCodeError === undefined
                            ? 'is-active'
                            : ''
                        )}
                        getInputValue={(val: string) => {
                          setFormData({ ...formData, verificationCode: val });
                          setErrors({ ...errors, verificationCodeError: undefined });
                        }}
                      />
                      {!showSendCode && (
                        <div
                          className={clsx(
                            't-font-xs d-flex mt-1',
                            isFormDisabled && 'disabled-text',
                            (errors?.incorrectVerificationCode ||
                              errors?.expiredTransactionCode ||
                              errors?.expiredOtpWarningMessage) &&
                              'validation-msg'
                          )}
                        >
                          {pageContext?.enableTimer === 'true' && authorModelData?.timer ? (
                            <OtpTimer
                              handleOtp={handleOtp}
                              onTimerActiveChange={handleTimerActiveChange}
                              isOtpSent={isOtpSent}
                              timer={authorModelData?.timer}
                              timerRunningLabel={authorModelData?.timerRunningLabel}
                              resendNewCodeLabel={authorModelData?.resendANewCode}
                              otpValidationError={
                                errors?.incorrectVerificationCode || errors?.expiredTransactionCode ? true : false
                              }
                              incorrectCodeRunningLabel={authorModelData?.incorrectCodeRunningLabel}
                              verificationCodeInlineMessage={authorModelData?.verificationCodeInlineMessage}
                              didNotGetACodeLabel={authorModelData?.didNotGetACode}
                            />
                          ) : (
                            <>
                              <RichText
                                text={
                                  errors?.incorrectVerificationCode || errors?.expiredTransactionCode
                                    ? authorModelData?.verificationCodeInlineMessage
                                    : errors?.expiredOtpWarningMessage
                                    ? authorModelData?.expiredOtpWarningInlineMessage
                                    : authorModelData?.didNotGetACode
                                }
                                componentId="verification-code"
                              />
                              <div
                                onClick={handleOtp}
                                className="ml-1 pointer-cursor help-text-decoration mr-3"
                                data-testid="verify_code"
                              >
                                <RichText text={authorModelData?.resendANewCode} componentId="resend-new-code" />
                              </div>
                            </>
                          )}
                        </div>
                      )}
                      {errors.verificationCodeError && <span>{errors.verificationCodeError}</span>}
                    </div>
                  </>
                )}
              {!isChangePwrd && (!isEAA || (isEAA && !phoneDetails?.hideEmail)) && (
                <div
                  className={`email-field m-input-field mb-5 pb-2
                    ${
                      isFormDisabled || !!phoneDetails?.emailAddress
                        ? 'is-disabled'
                        : errors.emailRequired
                        ? 'is-error'
                        : formData.email
                        ? 'is-active'
                        : ''
                    }
                  `}
                >
                  <label htmlFor="email" className="t-body-s">
                    {`${
                      isCoutriesSame(constants?.CHINA_COUNTRY_CODE, userDetails?.country) &&
                      isChinesePhoneNumberValid() &&
                      (isEAA || checkChinaLocale(currentLocale || ''))
                        ? authorModelData?.emailOptionalTitle
                        : authorModelData?.email
                    }`}
                  </label>
                  <input
                    ref={emailInputRef}
                    autoComplete="off"
                    type="text"
                    id="email"
                    data-testId="email"
                    maxLength={80}
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    disabled={isFormDisabled || !!phoneDetails?.emailAddress}
                  />
                  {errors.emailRequired && <span>{errors.emailRequired}</span>}
                  {errors.emailWarning && <span>{errors.emailWarning}</span>}
                </div>
              )}
              <PasswordInput
                value={formData.password}
                id="newpassword"
                name="password"
                disable={isFormDisabled}
                hasError={!!errors.newPwrdRequired}
                label={isChangePwrd ? authorModelData?.newPwrdLabel : authorModelData?.pwrdLabel}
                passwordType={passwordType}
                error={errors?.newPwrdRequired}
                customClass="mb-2"
                iconClass={passwordType === 'password' ? 'icon-visibility-off' : 'icon-visibility'}
                labelClass="t-body-s"
                showValidations={true}
                passwordConditions={passwordConditions}
                handleChange={handleChange}
                onIconClick={() => setPasswordType(passwordType === 'password' ? 'text' : 'password')}
                isValid={passwordValid}
                showCheckIcon={true}
                ariaLabel="password"
              />
              <PasswordInput
                value={formData.confirmPwrd}
                id="confirmPassword"
                name="confirmPwrd"
                disable={!isValidChangePassword}
                hasError={!!errors.confirmPwrdAndPwrdMatch}
                label={authorModelData?.confirmPwrdLabel}
                passwordType={confirmPasswordType}
                error={errors?.confirmPwrdAndPwrdMatch}
                customClass="mb-5"
                iconClass={confirmPasswordType === 'password' ? 'icon-visibility-off' : 'icon-visibility'}
                labelClass="t-body-s"
                handleChange={handleChange}
                onIconClick={() => setConfirmPasswordType(confirmPasswordType === 'password' ? 'text' : 'password')}
                isValid={formData.confirmPwrd?.length && formData.confirmPwrd === formData.password ? true : false}
                showCheckIcon={true}
              />
            </div>
          </div>
          <div className="col-12 px-0">
            <Button
              type={Types.ButtonTypeVariation.Button}
              buttonCopy={''}
              className={clsx(
                'm-button-m m-button-primary submit-btn px-5 create-account-submit-btn',
                (isFormDisabled ||
                  keepDisabledForChangePwrdFlow() ||
                  (formData.confirmPwrd?.length && formData.password?.length ? false : true)) &&
                  'disabled'
              )}
              isLink={false}
              custom_click_track_value={''}
              isDisabled={
                isFormDisabled ||
                isLoading ||
                keepDisabledForChangePwrdFlow() ||
                (formData.confirmPwrd?.length && formData.password?.length ? false : true)
              }
              callback={handleSubmit}
              testId="submit_btn"
            >
              {isLoading ? (
                <div className="m-spinner-s" data-testid="set-password-spinner"></div>
              ) : (
                authorModelData?.submitButtonLabel
              )}
            </Button>
            {isChangePwrd && (
              //using 'a' tag as 'm-link-action' button font doesn't match with invision.
              <span className="d-block d-sm-inline mt-4 mt-sm-0 text-center">
                <a
                  href={addSubDirectoryPrefix(authorModelData?.cancelCtaPath)}
                  className={clsx('cancel-button m-sm-5 m-link p-0', isChangePwrd ? 't-font-s' : 't-font-m')}
                  data-testid={authorModelData?.cancelCtaLabel}
                >
                  {authorModelData?.cancelCtaLabel}
                </a>
              </span>
            )}
          </div>
          <Text
            customClass={'t-font-xs pt-2 mt-5 d-lg-none'}
            copyText={authorModelData?.copyright?.replace('{0}', String(moment().year()))}
            fontSize={Types.size.extraSmall}
            element={Types.tags.paragraph}
          />
        </div>
      </div>
    </StyledSetPassword>
  );
};

export const SetPasswordConfig = {
  emptyLabel: 'SetPassword',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/setpassword`,
};
export const SetPasswordEditable = (props: any) => {
  return (
    <EditableComponent config={SetPasswordConfig} {...props}>
      <SetPassword {...props} />
    </EditableComponent>
  );
};
