import React, { memo, useEffect, useState } from 'react';
import clsx from 'clsx';

import { RichText, Types, Text } from '@marriott/mi-ui-library';
import { replaceText } from '../../modules/utils/promotionHelper';
import { OtpTimerProps } from './OtpTimer.types';
import { StyledOtpTimer } from './OtpTimer.styles';

export const OtpTimer = memo<OtpTimerProps>((props: OtpTimerProps) => {
  const {
    handleOtp,
    onTimerActiveChange,
    isOtpSent,
    timerRunningLabel,
    timer,
    resendNewCodeLabel,
    otpValidationError,
    incorrectCodeRunningLabel,
    verificationCodeInlineMessage,
    didNotGetACodeLabel,
    isMFAFlow,
    customClass,
  } = props;

  const [seconds, setSeconds] = useState(timer);
  const [timerActive, setTimerActive] = useState(false);

  useEffect(() => {
    if (isOtpSent) {
      setSeconds(timer);
      setTimerActive(true);
    }
  }, [isOtpSent]);

  useEffect(() => {
    let countdown: NodeJS.Timeout;
    if (timerActive && seconds > 0) {
      countdown = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds - 1);
      }, 1000);
    }

    return () => clearInterval(countdown);
  }, [timerActive]);

  useEffect(() => {
    if (seconds === 0) {
      setTimerActive(false);
      onTimerActiveChange(false);
    }
  }, [seconds]);

  return (
    <StyledOtpTimer>
      {timerActive ? (
        <RichText
          text={replaceText(otpValidationError ? incorrectCodeRunningLabel || '' : timerRunningLabel || '', [
            `${seconds.toString().padStart(2, '0')}`,
          ])}
          componentId="verification-code-running-label"
          customClass={clsx(
            isMFAFlow ? 't-font-s' : 't-font-xs',
            otpValidationError ? 'validation-msg' : '',
            'resend-code',
            customClass
          )}
        />
      ) : (
        <>
          <RichText
            text={otpValidationError ? verificationCodeInlineMessage || '' : didNotGetACodeLabel || ''}
            componentId="verification-code"
            customClass={clsx(isMFAFlow ? 't-font-s' : 't-font-xs', 'd-inline-block')}
          />
          <div
            onClick={handleOtp}
            className={clsx(
              'ml-1 pointer-cursor help-text-decoration mr-3 d-inline-block resend-code',
              otpValidationError ? 'validation-msg' : '',
              isMFAFlow ? 't-font-s' : 't-font-xs'
            )}
            data-testid="verify_code"
          >
            <RichText text={resendNewCodeLabel} componentId="resend-new-code" customClass="" />
          </div>
        </>
      )}
    </StyledOtpTimer>
  );
});
