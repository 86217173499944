/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useContext, useState, useEffect } from 'react';
import clsx from 'clsx';
import { EditableComponent } from '@adobe/aem-react-editable-components';

import { Button, Heading, Text, Types, Messages, RichText } from '@marriott/mi-ui-library';
import { useAccountPersistentStore } from '../../modules/store/accountPersistentStore';

import { PageContext, useCheckBreakpoint } from '../../modules';
import { PromotionNotAvailableProps } from './PromotionNotAvailable.types';
import { StyledPromotionNotAvailable } from './PromotionNotAvailable.styles';

export const PromotionNotAvailable: FC<PromotionNotAvailableProps> = (pageProps: any) => {
  const pageContext = useContext(PageContext);
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const isViewportL = useCheckBreakpoint('viewportL');
  const isViewportS = !useCheckBreakpoint('viewportS');
  const [isPromoInvalid, setIsPromoInvalid] = useState(false);
  const { promoInvalid, setPromoInvalid } = useAccountPersistentStore(state => state);

  useEffect(() => {
    if (promoInvalid) {
      setIsPromoInvalid(promoInvalid);
      setPromoInvalid?.(false);
    }
  }, [promoInvalid]);

  return (
    <StyledPromotionNotAvailable
      data-testid="promotionnotavailable"
      data-component-name="o-account-promotionnotavailable"
    >
      <div className="container">
        <Heading
          fontSize={Types.size.small}
          variation={Types.headingType.title}
          titleText={pageProps?.model?.title}
          customClass="pb-5 mb-0"
          element={Types.tags.h1}
        />

        {isPromoInvalid && (
          <Messages messageType="error-sev1" className="account-page-error-msg mb-5">
            <RichText
              text={pageProps?.model?.promoExpiredWarningMessage}
              customClass="t-font-s"
              componentId="reset-token-error"
            />
          </Messages>
        )}

        <Heading
          fontSize={isViewportL ? Types.size.extraLarge : Types.size.large}
          variation={Types.headingType.subtitle}
          titleText={pageProps?.model?.subTitle}
          element={Types.tags.h2}
          customClass="pb-3 mb-0"
        />
        <Text
          element={Types.tags.div}
          fontSize={Types.size.medium}
          dangerouslySetInnerHTML={{ __html: pageProps?.model?.description }}
        />
        <Text
          element={Types.tags.div}
          fontSize={Types.size.small}
          dangerouslySetInnerHTML={{ __html: pageProps?.model?.customerSupportStatement }}
        />
        <div className="divider pt-4"> </div>
        <Text
          element={Types.tags.div}
          fontSize={Types.size.medium}
          copyText={pageProps?.model?.newPromotionAvailabilityCheck}
          customClass="pt-5 mt-2"
        />

        {sessionData && sessionData?.consumerID ? (
          <Button isLink={true} className="m-link-tertiary-button mt-3 mb-4" href={pageProps?.model?.ctaPath}>
            {' '}
            {pageProps?.model?.ctaLabel}
          </Button>
        ) : (
          <div className="mt-3 mb-4">
            {' '}
            <Button
              className={clsx(`m-button-primary btn join-btn text-center ${isViewportS ? 'm-button-s' : 'm-button-m'}`)}
              isLink={true}
              href={pageProps?.model?.joinNowCTAPath}
            >
              {pageProps?.model?.joinNowLabel}
            </Button>
            <Button
              className={clsx(
                `m-button-secondary btn text-center sign-in-btn ${isViewportS ? 'm-button-s ml-4' : 'm-button-m ml-3'}`
              )}
              isLink={true}
              href={pageProps?.model?.signInCTAPath}
            >
              {pageProps?.model?.signInLabel}
            </Button>
          </div>
        )}
      </div>
    </StyledPromotionNotAvailable>
  );
};
export const PromotionBannerConfig = {
  emptyLabel: 'PromotionNotAvailable',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/promotionnotavailable`,
};
export const PromotionNotAvailableEditable = (props: any) => {
  return (
    <EditableComponent config={PromotionBannerConfig} {...props}>
      <PromotionNotAvailable {...props} />
    </EditableComponent>
  );
};
