/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import axios from 'axios';
import dynamic from 'next/dynamic';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { Heading, Types, Button, Link, RadioButton, Messages, RichText } from '@marriott/mi-ui-library';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';
import {
  addClassToBlankTargetLinks,
  addSubDirectoryPrefix,
  canUseDOM,
  getRedirectUrlFromParams,
  logger,
} from '../../modules/utils';
import { PageContext } from '../../modules';
import { StyledConfirmIdentity } from './ConfirmIdentity.styles';
import { UserData } from './ConfirmIdentity.types';
import {
  sendOtpUrl,
  MFA_OPTIONS_KEY,
  mfaAfterSignInUrl,
  ADD_MOBILE_NUMBER_PATH,
  ENCRYPTED_DATA,
  ENCRYPTED_VERIFY_DATA,
  TRACKING_CONSTANTS,
} from '../../modules/utils/constants/constants';

const VerifyYourCode = dynamic(() => import('./VerifyYourCode/VerifyYourCode').then(mod => mod.VerifyYourCode));

const AddMobileNumber = dynamic(() => import('./AddPhoneNumber/AddMobileNumber').then(mod => mod.AddMobileNumber));

const { log } = logger({})('ConfirmIdentity');

export const ConfirmIdentity = (pageProps: any) => {
  const aemModelData = pageProps?.model;
  const mockData = pageProps?.mockData;
  const pageContext = useContext(PageContext);
  const clientEnvVars = useClientEnvVarsStore.getState().envVarsObject;
  const { SKIP_DECODE_URL_FOR_PATH: skipDecodeUrlForPath, TRACKING_PAGE_NAME: trackingPageName = '' } = clientEnvVars;
  const { EMAIL_PHONE, SEND_CODE, CANCEL, INTERNAL } = TRACKING_CONSTANTS;
  const isEAA = pageContext?.isEAASignIn;
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const [redirectURL, setredirectURL] = useState<string | null>();
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState('');
  const [formError, setFormError] = useState('');
  const [selectedPhoneNumberType, setSelectedPhoneNumberType] = useState('');
  const [hasMFAOptions, setHasMFAOptions] = useState(true);
  const [isMFALoading, setIsMFALoading] = useState(true);
  const [isResendOtp, setIsResendOtp] = useState(false);
  const [otpSpinner, setOtpSpinner] = useState(false);
  const [isAddMobile, setIsAddMobile] = useState(false);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [userLoginInfo, setUserLoginInfo] = useState<UserData>({
    emailAddress: '',
    phoneNumbers: [],
  });
  const [verifyYourCodeShow, setVerifyYourCode] = useState(false);
  const [returnUrl, setReturnUrl] = useState<string | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const router = useRouter();
  const clientId = router && router.query['clientId'];
  const validClientID = typeof clientId === 'string' ? clientId : Array.isArray(clientId) ? clientId[0] || '' : '';

  useEffect(() => {
    if (window?.location?.pathname.includes(ADD_MOBILE_NUMBER_PATH)) {
      setIsAddMobile(true);
    } else {
      const storedLoginInfo = window?.sessionStorage?.getItem(MFA_OPTIONS_KEY);
      let parsedLoginInfo;
      if (storedLoginInfo) {
        parsedLoginInfo = JSON.parse(storedLoginInfo);
        setUserLoginInfo(phoneNumberSortingHandler(parsedLoginInfo));
        setIsMFALoading(false);
      } else if (!storedLoginInfo) {
        fetchData();
      } else if (mockData) {
        // "mockData" is only meant to be passed from storybook for populating dummy email and phone options.
        // Dont use this prop to pass data from Parent Comp.
        setUserLoginInfo(mockData);
        setIsMFALoading(false);
      }
      setredirectURL(getRedirectUrlFromParams(false, false, skipDecodeUrlForPath));
      setSelectedOption(
        parsedLoginInfo?.phoneNumbers?.length > 0
          ? `number_${parsedLoginInfo?.phoneNumbers?.[0]?.type}`
          : parsedLoginInfo?.emailAddress
          ? 'email'
          : ''
      );
    }
  }, []);

  useEffect(() => {
    const defaultPhoneNumber = userLoginInfo?.phoneNumbers?.[0]?.number;
    const defaultPhoneNumberType = userLoginInfo?.phoneNumbers?.[0]?.type || '';

    if (defaultPhoneNumber) {
      setSelectedPhoneNumber(defaultPhoneNumber);
      setSelectedPhoneNumberType(defaultPhoneNumberType);
      setSelectedOption(`number_${defaultPhoneNumberType}`);
    }
  }, [userLoginInfo]);

  const fetchData = async () => {
    try {
      // Make API call to get MFA Options
      const parsedLoginInfo = await getMFAOptions();
      setUserLoginInfo(phoneNumberSortingHandler(parsedLoginInfo));
      setSelectedOption(
        parsedLoginInfo?.emailAddress ? 'email' : parsedLoginInfo?.phoneNumbers?.length > 0 ? 'number' : ''
      );
      if (
        !parsedLoginInfo.emailAddress &&
        (!parsedLoginInfo.phoneNumbers ||
          (Array.isArray(parsedLoginInfo?.phoneNumbers) && parsedLoginInfo?.phoneNumbers?.length === 0))
      ) {
        setHasMFAOptions(false);
      }
    } catch (error) {
      // Handle error if the API call fails
      log.debug('Error fetching MFA options:', error);
    } finally {
      setIsMFALoading(false);
    }
  };

  const emailAddress = userLoginInfo?.emailAddress?.toLowerCase();

  const sendCode = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    formSubmit(isResendOtp);
  };

  const getMFAOptions = async (): Promise<UserData> => {
    try {
      const response = await axios.get(`${mfaAfterSignInUrl}${validClientID && `?clientId=${validClientID}`}`, {
        headers: {
          'Content-Type': 'application/json',
          Cookie: 'sessionID=' + sessionData?.sessionToken,
        },
      });

      return response.data;
    } catch (error) {
      if (error) {
        setHasMFAOptions(false);
        log.debug(`[MFA Options] error: ${error}`);
      }
    }

    // If there's an error or no data, return default values
    return {
      emailAddress: '',
      phoneNumbers: [],
    };
  };

  const phoneNumberSortingHandler = (items: UserData) => {
    const typeOrder: Record<string, number> = { MT: 1, HT: 2, BT: 3 }; // Sort function with custom order for type
    return {
      emailAddress: items?.emailAddress,
      phoneNumbers: items?.phoneNumbers?.sort(
        (a, b) => (typeOrder[a.type] || Infinity) - (typeOrder[b.type] || Infinity)
      ),
    };
  };

  const formSubmit = async (isResend?: boolean) => {
    const allowedOptions = userLoginInfo.phoneNumbers.map(phoneNumberData => `number_${phoneNumberData?.type}`);
    try {
      const response = await axios.post(
        addSubDirectoryPrefix(sendOtpUrl),
        {
          mfaOption: selectedOption === 'email' ? 'OTP_EMAIL' : 'OOB_SMS',
          resendOtp: !!isResend || false,
          emailAddress: selectedOption === 'email' ? emailAddress : '',
          phoneNumber: allowedOptions.includes(selectedOption) ? selectedPhoneNumber : '',
          phoneNumberType: allowedOptions.includes(selectedOption) ? selectedPhoneNumberType : '',
          returnUrl: redirectURL,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Cookie: 'sessionID=' + sessionData?.sessionToken,
          },
        }
      );
      const { nextStateURI } = response.data;
      setReturnUrl(nextStateURI);
      setVerifyYourCode(true);
      setOtpSpinner(false);
      setFormError('');
      setIsTimerActive(true);
    } catch (error: any) {
      if (error) {
        log.debug(`[Confirm Identity] submit Funtion called error: ${error}`);
        setOtpSpinner(false);
        setFormError(pageContext?.uxlErrorMessage);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getCancelCTAPath = () => {
    let cancelCTAPath = aemModelData?.cancelCTAPath;
    if (validClientID && canUseDOM) {
      cancelCTAPath = window.document?.referrer;
    } else if (redirectURL) {
      cancelCTAPath = redirectURL;
    }
    return addSubDirectoryPrefix(cancelCTAPath);
  };

  const clearSessionData = () => {
    sessionStorage?.removeItem(MFA_OPTIONS_KEY);
    sessionStorage?.removeItem(ENCRYPTED_DATA);
    sessionStorage?.removeItem(ENCRYPTED_VERIFY_DATA);
    return true;
  };

  const handleRegenerateOtp = (timerState: boolean) => {
    setIsTimerActive(timerState);
  };

  return (
    <StyledConfirmIdentity
      className="my-5 pt-md-4 pb-5"
      data-testid="confirmidentity"
      data-component-name="o-account-confirmidentity"
    >
      {formError ? (
        <Messages messageType="error-sev1" className="mt-3 mb-4 verify-page-error-msg">
          <RichText text={formError} componentId="confirm-identity-form-error" />
        </Messages>
      ) : (
        ''
      )}
      {!isAddMobile ? (
        isMFALoading ? (
          <div className="confirm-identity-container container">
            <div className="skeleton-loader skeleton__title"></div>
            <div className="skeleton-loader skeleton__subtitle mt-4"></div>
          </div>
        ) : hasMFAOptions ? (
          // Render the confirmation section if hasMFAOptions is false
          verifyYourCodeShow ? (
            <VerifyYourCode
              {...pageProps}
              selectedOption={selectedOption}
              emailAddress={emailAddress}
              phoneNumber={selectedPhoneNumber}
              regenerateOtpCall={() => {
                setOtpSpinner(true);
                formSubmit(true);
              }}
              otpSpinner={otpSpinner}
              cancelCTAPath={getCancelCTAPath()}
              setVerifyYourCode={(isVerify: boolean) => {
                setVerifyYourCode(isVerify);
                setIsResendOtp(true);
              }}
              redirectURL={returnUrl}
              skipDecodeUrlForPath={skipDecodeUrlForPath}
              clientId={validClientID}
              clearSessionData={clearSessionData}
              setFormError={setFormError}
              onRegenerateOtp={handleRegenerateOtp}
              isTimerActive={isTimerActive}
            />
          ) : (
            <div className="confirm-identity-container container">
              <Heading
                variation={Types.headingType.title}
                titleText={aemModelData?.title}
                customClass="date t-title-s"
              />
              <div className="confirm-identity-container__subtitle t-subtitle-m pt-1 pb-2 pb-md-3 mb-5">
                {aemModelData?.subTitle}
              </div>
              {/* phone number */}
              {userLoginInfo?.phoneNumbers &&
                userLoginInfo?.phoneNumbers?.map((phoneNumber, index) => (
                  <RadioButton
                    key={index}
                    dataTestId="radio-btn"
                    data-testid={`number_${phoneNumber?.type}`}
                    radiobuttonId={`number_${phoneNumber?.type}`}
                    radiobuttonName={`number_${phoneNumber?.type}`}
                    radiobuttonLabel={`${aemModelData?.mobileNumberLabel} ${phoneNumber?.number}`}
                    checked={selectedOption === `number_${phoneNumber?.type}`}
                    onChange={() => {
                      setSelectedOption(`number_${phoneNumber?.type}`);
                      setSelectedPhoneNumber(phoneNumber?.number);
                      setSelectedPhoneNumberType(phoneNumber?.type);
                    }}
                    value={`number_${phoneNumber?.type}`}
                    customClickClass={''}
                    setTabIndex={0}
                    custom_click_track_value={`${trackingPageName}|${EMAIL_PHONE}|${INTERNAL}|`}
                    labelClassName="radio_btn pl-4"
                    aria-label={`confirm-number-${phoneNumber?.type}`}
                  />
                ))}
              {/* email option */}
              {emailAddress && (
                <RadioButton
                  data-testid="email"
                  radiobuttonId="email"
                  radiobuttonName="email"
                  radiobuttonLabel={aemModelData?.emailLabel + ' ' + emailAddress}
                  checked={selectedOption === 'email'}
                  onChange={() => {
                    setSelectedOption('email');
                    setSelectedPhoneNumber('');
                    setSelectedPhoneNumberType('');
                  }}
                  value="email"
                  customClickClass={''}
                  setTabIndex={0}
                  custom_click_track_value={`${trackingPageName}|${EMAIL_PHONE}|${INTERNAL}|`}
                  labelClassName="radio_btn pl-4"
                  aria-label="confirm-email"
                ></RadioButton>
              )}
              <div className="confirm-identity-container__cta d-flex align-items-center flex-column flex-md-row mb-4 pt-lg-5 pt-4 mt-5">
                <Button
                  type={Types.ButtonTypeVariation.Button}
                  buttonCopy={''}
                  testId="send-code-btn"
                  className="confirm-identity-container__cta--sendcode m-button-m m-button-primary  mr-md-5"
                  isLink={false}
                  {...(!isEAA && {
                    custom_click_track_value: `Confirm Your Identity Modal|Continue|internal|event200`,
                  })}
                  callback={sendCode}
                  isDisabled={isLoading}
                  custom_click_track_value={`${trackingPageName}|${SEND_CODE}|${INTERNAL}|event200`}
                >
                  {isLoading ? (
                    <div className="m-spinner-s" data-testid="sendCode-spinner"></div>
                  ) : (
                    aemModelData?.sendCodeButtonLabel
                  )}
                </Button>

                <Link
                  text={aemModelData?.cancelCTALabel}
                  linkClassName="confirm-identity-container__cta--cancel m-link-action pt-md-0 pt-4"
                  ariaLabel={aemModelData?.cancelCTALabel}
                  linkHref={getCancelCTAPath()}
                  target="_self"
                  callback={clearSessionData}
                  custom_click_track_value={`${trackingPageName}|${CANCEL}|${INTERNAL}|`}
                ></Link>
              </div>

              <div className="confirm-identity-container__footer">
                <div className="confirm-identity-container__footer--tnc t-font-xs pb-2">
                  <span
                    dangerouslySetInnerHTML={{ __html: addClassToBlankTargetLinks(aemModelData?.contentDescription) }}
                  ></span>
                </div>
                <div className="confirm-identity-container__footer--support t-font-s">
                  <span
                    dangerouslySetInnerHTML={{ __html: addClassToBlankTargetLinks(aemModelData?.description) }}
                  ></span>
                </div>
              </div>
            </div>
          )
        ) : (
          // Render another div or component if userMFA is true
          <div className="confirm-identity-container container">
            <Heading
              variation={Types.headingType.title}
              titleText={aemModelData?.errorTitle}
              customClass="date t-title-s"
            />
            <div className="confirm-identity-container__subtitle t-subtitle-m pt-3 mb-2">
              {aemModelData?.errorSubTitle}
            </div>
            <div className="confirm-identity-container__footer">
              <div className="confirm-identity-container__footer--tnc t-font-s pb-2">
                <span dangerouslySetInnerHTML={{ __html: aemModelData?.errorDescription }}></span>
              </div>
            </div>
          </div>
        )
      ) : (
        <AddMobileNumber model={pageProps?.model} />
      )}
    </StyledConfirmIdentity>
  );
};

export const ConfirmIdentityConfig = {
  emptyLabel: 'confirmidentity',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/confirmidentity`,
};

export const ConfirmIdentityEditable = (props: any) => (
  <EditableComponent config={ConfirmIdentityConfig} {...props}>
    <ConfirmIdentity {...props} />
  </EditableComponent>
);
