import React, { FC, useRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import Glide from '@glidejs/glide';

import { Image } from '../../atoms/Image';
import { CarouselGalleryProps, VideoDetails } from './CarouselGallery.types';
import { StyledCarouselGallery } from './CarouselGallery.styles';
import { useWindowSize } from '../../hooks';
import { canUseDOM, VideoMolecule } from '@marriott/mi-ui-library';

export const CarouselGallery: FC<CarouselGalleryProps> = props => {
  const {
    componentId,
    styleclass,
    customClass,
    btnCustomClass,
    customAttributes,
    trackingProperties,
    carouselItems,
    carouselType,
    linkUrl,
    galleryAltText,
    previousLabel,
    nextLabel,
    leftArrowAriaLabel,
    rightArrowAriaLabel,
  } = props;

  const itemsPerView = 1;
  const isTripTych = carouselType === 'carouselTriptych';
  const isHero = carouselType === 'carouselHero';
  const itemCount = carouselItems?.length;
  const containerRef = useRef<HTMLDivElement>(null);
  const lang = canUseDOM ? document.documentElement.lang.replace('-', '_') : 'en_US';
  const { width: windowWidth = 0 } = useWindowSize();
  const [viewPort, setViewPort] = useState('Desktop');

  const handleResize = () => {
    const xs = window?.matchMedia?.('(max-width: 767px)');
    const md = window?.matchMedia?.('(min-width: 768px) and (max-width: 1199px)');
    const lg = window?.matchMedia?.('(min-width: 1200px)');
    if (xs?.matches) {
      setViewPort('Mobile');
    } else if (md?.matches) {
      setViewPort('Tablet');
    } else if (lg?.matches) {
      setViewPort('Desktop');
    }
  };

  function getVideoByLanguage(videosByLanguage: VideoDetails[], lang: string) {
    const video = videosByLanguage.find(video => video?.language?.includes(lang));
    if (!video) {
      return videosByLanguage.find(video => video?.language?.includes('en_US'));
    }
    return video;
  }

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const isFullBleed = styleclass?.includes('m-container-fullbleed');
    const divWidth = containerRef?.current?.offsetWidth || 0;
    const width = isFullBleed ? windowWidth : divWidth;
    const peekWidth = Math.trunc(divWidth - divWidth * 0.66 + width - divWidth);
    const carouselRootSelector = `#${componentId}`;
    let glide: Glide.Properties | Glide.Static;
    if (document?.querySelector(carouselRootSelector)) {
      try {
        glide = new Glide(carouselRootSelector, {
          type: 'carousel',
          bound: true,
          startAt: 0,
          dragThreshold: false,
          focusAt: 'center',
          keyboard: true,
          gap: isTripTych ? 16 : 0,
          perView: 1,
          peek: isTripTych ? Number(peekWidth) / 2 : 0,
          animationDuration: isTripTych ? 600 : 300,
          animationTimingFunc: isTripTych ? 'cubic-bezier(0, 0, 0.67, 1)' : '',
        });

        glide?.mount();

        const addArrowEventListener = (arrowId: string, clickTrack: string) => {
          const arrow = document?.querySelector(`#${arrowId}`);
          if (arrow) {
            arrow.addEventListener('touchstart', (e: Event) => {
              document.getElementById(clickTrack)?.click();
              e.preventDefault();
            });
            arrow.addEventListener('click', (e: Event) => {
              document.getElementById(clickTrack)?.click();
              e.preventDefault();
            });
          }
        };

        glide?.on(['mount.after', 'run'], () => {
          addArrowEventListener(`${componentId}_left_arrow`, 'left-arrow-click-track');
          addArrowEventListener(`${componentId}_right_arrow`, 'right-arrow-click-track');
        });
      } catch (error) {
        // log.error(`Error in initializing Glide for ${componentId}`, error);
      }
    }
    return () => {
      if (glide) {
        (glide as Glide.Properties)?.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentId, styleclass, windowWidth]);

  return (
    <StyledCarouselGallery
      data-component-name="m-ui-library-CarouselGallery"
      data-testid="ui-library-CarouselGallery"
      className={clsx(isTripTych && 'pt-3', styleclass, customClass)}
      {...customAttributes}
      variation={carouselType}
    >
      {itemCount ? (
        <div id={componentId} className={clsx(`glide`)} data-testid="carouselGallery">
          <div className={clsx('glide__track')} data-glide-el="track">
            <ul className={clsx('glide__slides')} id={`${componentId}__slides`}>
              {carouselItems.map((item, index) => {
                const image = item.carouselTriptych || item.hero;
                const itemType = 'videoAccountId' in item ? 'video' : 'image';
                const video = getVideoByLanguage(item.videosByLanguage || [], lang);
                return itemType === 'image' ? (
                  <li className="glide__slide" tabIndex={-1} aria-hidden="true" key={'image-' + index}>
                    <Image
                      altText={image?.altText}
                      renditions={image?.renditions}
                      dynamic={image?.dynamic}
                      defaultImageURL={image?.assetPath}
                    />
                  </li>
                ) : (
                  <li className="glide__slide" tabIndex={-1} aria-hidden="true" key={'video-image-' + index}>
                    <VideoMolecule
                      id={''}
                      accountId={item.videoAccountId || ''}
                      videoId={
                        viewPort === 'Mobile'
                          ? video?.brightcoveVideoIdMobile || ''
                          : video?.brightcoveVideoIdDesktop || ''
                      }
                      poster={
                        viewPort === 'Mobile'
                          ? video?.fileReferenceImageMobile || ''
                          : video?.fileReferenceImageDesktop || ''
                      }
                      enableMute={item.enableMute}
                      enableVideoLoop={item.enableVideoLoop}
                      renditions={
                        viewPort === 'Mobile'
                          ? video?.dynamicMediaMobile?.renditions || []
                          : video?.dynamicMediaDesktop?.renditions || []
                      }
                    />
                  </li>
                );
              })}
            </ul>
          </div>
          {itemCount > 1 && (
            <div className="center-align-controls container-sm" ref={containerRef}>
              <div className={clsx(isTripTych ? 'carouselControlType2' : 'carouselControlType3a')}>
                <div className="glide__arrows" data-glide-el="controls">
                  <button
                    className={clsx('left-arrow', 'glide__arrow--left', btnCustomClass)}
                    id={`${componentId}_left_arrow`}
                    data-glide-dir="<"
                    data-content={previousLabel}
                    aria-label={leftArrowAriaLabel}
                  >
                    <span className="icon-arrow-left" id={`${componentId}_left_arrow`} data-id="left"></span>
                  </button>
                </div>
                <input
                  type="hidden"
                  id="left-arrow-click-track"
                  className={clsx(`${trackingProperties?.clickTrack ? 'custom_click_track' : ''}`)}
                  data-custom_click_track_value={`${trackingProperties?.cardLocation}|${trackingProperties?.leftArrowDesc}|internal`}
                />
                <div data-glide-el="controls[nav]">
                  {Array.from({ length: itemCount - (itemsPerView - 1) }, (_, i) => (
                    <button
                      className=""
                      data-glide-dir={i}
                      aria-label={`Image ${i + 1} of ${itemCount}`}
                      key={itemsPerView + i}
                    ></button>
                  ))}
                </div>
                <div className="glide__arrows" data-glide-el="controls">
                  <button
                    className={clsx('right-arrow', 'glide__arrow--right', btnCustomClass)}
                    id={`${componentId}_right_arrow`}
                    data-glide-dir=">"
                    data-content={nextLabel}
                    aria-label={rightArrowAriaLabel}
                  >
                    <span className="icon-arrow-right" data-id="right"></span>
                  </button>
                </div>
                <input
                  type="hidden"
                  id="right-arrow-click-track"
                  className={clsx(`${trackingProperties?.clickTrack ? 'custom_click_track' : ''}`)}
                  data-custom_click_track_value={`${trackingProperties?.cardLocation}|${trackingProperties?.rightArrowDesc}|internal`}
                />
              </div>
              {isHero && linkUrl && (
                <a className="gallery-icon" href={linkUrl} title={galleryAltText}>
                  <span className="icon-photo-gallery" title={galleryAltText}></span>
                </a>
              )}
            </div>
          )}
        </div>
      ) : null}
    </StyledCarouselGallery>
  );
};
