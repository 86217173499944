import { TrackingDetailsProps } from '../utils/enums/enums';

export const trackingDetailArr: Array<TrackingDetailsProps> = [
  { text: 'trackingContentPosition', pre: '' },
  { text: 'trackingDescription', pre: 'msg=' },
  { text: 'trackingOfferType', pre: 'type=' },
  { text: 'trackingTag', pre: 'tag=' },
];
export const constants = {
  COBRAND_NON_POINTS_MATH: 'cobrandNonPointsMath',
  TARGET_BLANK: '_blank',
  SIMPLE: 'simple',
  OPEN_IN_NEW_TAB: 'Opens a new window',
  NO_OPENER: 'noopener',
  NO_REFERER: 'noreferrer',
  DEFAULT: 'DEFAULT',
  IMG_ALT_TEXT: 'Title',
  HOMEPAGE_HB_16X9_DES_REND2X: '2880px:1620px',
  HOMEPAGE_HB_16X9_TABLET_REND2X: '1536px:864px',
  HOMEPAGE_HB_16X9_MOB_REND2X: '728px:1126px',
  HOMEPAGE_HB_3X1_DES_REND2X: '2880px:960px',
  HOMEPAGE_HB_3X1_TABLET_REND2X: '2880px:960px',
  HOMEPAGE_HB_3X1_MOB_REND2X: '1456px:1456px',
  DOWNSIZE_PARAM: '?output-quality=70&interpolation=progressive-bilinear&downsize=',
  DEFAULT_LANG: 'en_US',
  COBRAND_MATH_HERO_CTA_CLASS: 'cobrand-math-hero-btn',
};
export const cardCodes = {
  '0513': 'updateboundless',
  '0532': 'updatebold',
  USPC: 'updatebrilliant',
  USBU: 'updatebusiness',
  USMT: 'updatebevy',
};
export const config = {
  gcv: 'AMCV_664516D751E565010A490D4C@AdobeOrg',
  cookie: 'MCMID',
  ctaURL: '/loyalty/offers/chase.mi?returnURL=/loyalty/myAccount/profile.mi&destinationURL=',
  cname: 'merchViewed',
  code1: '0513',
  code2: '0532',
  HIO: 'HIO',
  ctaUrlChasePrefix: '&returnURL=',
  ctaUrlChase: '/loyalty/myAccount/profile.mi',
  ctaUrlAmex: '/mi/cobrand/amex.mi?destinationURL=',
  ctaUrlAmexEvergreen: 'https://www.marriott.com/loyalty/offers/amex.mi?destinationURL=',
};

export const AccordionHiddenClasses = ['collapsed', 'hide-visibility'];
export const HEIGHT_VALUE = 3.75;
export const PX_TO_REM = 0.0625;
export const LOCALE_ZH_CN = 'zh-CN';
export const MOMENT_CN_LANG = 'zh-cn';

export const CN_RATING_TOOLTIP = '评分是基于客户完成住宿后在满意度调查中提供的客户评分整理得出的。';
