export type MemberPanelCardLink = {
  cardLinkLabel: string;
  cardLinkPath: string;
  cardLinkId?: string;
};

export type SignedMemberPanelProps = {
  pointsLabel: string;
  buyPointsLinkLabel: string;
  buyPointsLinkpath: string;
  memberPanelCardLinks?: MemberPanelCardLink[];
  logoutLinkLabel: string;
  logoutLinkPath: string;
};

export interface MemberPanelProps extends SignedMemberPanelProps {
  joinNowLabel: string;
  joinNowSubHeading: string;
  becomeMemberLabel: string;
  becomeMemberUrl: string;
  memberBenefitsLabel?: string;
}

export const MEMBERPANEL_CONSTANTS = {
  MY_TRIPS: 'myTrips',
  BENEFITS: 'benefits',
};
