export const constants = {
  //TODO: OPEN_IN_NEW_TAB value should come from i18n
  OPEN_IN_NEW_TAB: 'Opens a new window',
  NO_OPENER: 'noopener',
  NO_REFERER: 'noreferrer',
  TARGET_BLANK: '_blank',
  DEFAULT: 'DEFAULT',
  IMG_ALT_TEXT: 'Title',
  HOMEPAGE_HB_16X9_DES_REND2X: '2880px:1620px',
  HOMEPAGE_HB_16X9_TABLET_REND2X: '1536px:864px',
  HOMEPAGE_HB_16X9_MOB_REND2X: '728px:1126px',
  HOMEPAGE_HB_3X1_DES_REND2X: '2880px:960px',
  HOMEPAGE_HB_3X1_TABLET_REND2X: '2880px:960px',
  HOMEPAGE_HB_3X1_MOB_REND2X: '1456px:1456px',
  DOWNSIZE_PARAM: '?output-quality=70&interpolation=progressive-bilinear&downsize=',
  DEFAULT_LANG: 'en_US',
  COBRAND_MATH_HERO_CTA_CLASS: 'cobrand-math-hero-btn',
};

export const URL_CONSTANTS = {
  PLACEHOLDER_IMAGE:
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGNiOAMAANUAz5n+TlUAAAAASUVORK5CYII=',
  BRIGHTCOVE_PLAYER_DOMAIN: 'https://players.brightcove.net',
};

//MEDIA QUERY CONSTANTS
export const DESKTOP_MEDIA_QUERY = '(min-width: 768px)';

// POSTCARD CONSTANTS
export const POSTCARD_CONSTANTS = {
  POSITION: 'Postcard carousel',
  LEFT_ARROW: 'left-arrow',
  RIGHT_ARROW: 'right-arrow',
  LEFT_ARROW_BELOW: 'left-arrow-below',
  RIGHT_ARROW_BELOW: 'right-arrow-below',
};
