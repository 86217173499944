import React, { useMemo, useEffect } from 'react';
import rtlPlugin from 'stylis-plugin-rtl';
import { StyleSheetManager } from 'styled-components';
import { ModelManager } from '@adobe/aem-spa-page-model-manager';
import { version as globalStylesVersion } from '@marriott/global-styles/package.json';
import { CustomModelClient } from '@marriott/mi-headless-utils';
import {
  OVERVIEW_PAGE_URL,
  SWEEPSTAKES_ENTRY_PAGE_URL,
  constants,
  imageDomain,
  imageDomainCN,
} from '@marriott/mi-account-components/constants';
import '@glidejs/glide/src/assets/sass/glide.core.scss';
import '@glidejs/glide/src/assets/sass/glide.theme.scss';
import { defineComponentMapping } from '../import-components';
import { canUseDOM } from '@marriott/mi-ui-library';
import { BACKGROUND_COLOR_PAGE_ARRAY, PageContext } from '@marriott/mi-account-components';
import {
  UpdateDatalayerObj,
  setHWSReservationURL,
  setSubDirectoryPrefix,
  setRitzCarltonUrl,
  correctSubDirectoryPathForHeader,
} from '@marriott/mi-account-components/utils';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';
import { loadLocale, loadLocaleCurrency, setSessionKey } from '@marriott/mi-book-components';
import path from 'path';
import 'react-loading-skeleton/dist/skeleton.css';
import '@splidejs/splide/css';

path.resolve('./next.config.js');
declare global {
  interface Window {
    jQuery: unknown;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    $: any;
    deployedEnvType: string;
  }
}

if (canUseDOM) window.$ = window.jQuery = require('jquery-slim/dist/jquery.slim.min.js');

const modelClient = new CustomModelClient(useClientEnvVarsStore.getState().envVarsObject['NEXT_PUBLIC_AEM_HOST']);

if (useClientEnvVarsStore.getState().envVarsObject['NODE_ENV'] !== 'test') {
  ModelManager.initializeAsync({
    modelClient,
  });
}

const App = function (props) {
  const { Component, pageProps } = props;
  const {
    model,
    resolvedUrl,
    isAuthorMode,
    sessionData,
    sessionTimeOutPage = false,
    isEAA = false,
    isMobileAuthFeatureEnabled = false,
    isNonChinaMobileEnabled = false,
    sweepstakesPreprocessorResponse = {},
    isResetTokenValid,
    apolloEnvVars,
    userState,
    isDTT,
    isPointsTransferSuccess = false,
    cnEnvVars,
    NEXT_GLOBAL_ASSET_PREFIX,
  } = pageProps;

  const allowedComponents = model?.cqItems?.root?.[':items']?.responsivegrid?.allowedComponents?.components;
  const rtlPluginTs = (...args: unknown[]) => {
    return rtlPlugin(args[0] as number, args[1] as string);
  };
  const serverENV = pageProps?.serverENV ? JSON.parse(pageProps?.serverENV) : {};
  const setEnvVars = useClientEnvVarsStore(state => state.setEnvVars);
  const envVars = apolloEnvVars ? apolloEnvVars : {};
  envVars['ACCEPT_LANGUAGE'] = pageProps.currentLocale;
  envVars['TRACKING_PAGE_NAME'] = model?.trackingPageName;
  envVars['GOOGLE_MAP_API_KEY'] = process.env?.['GOOGLE_MAP_API_KEY'] || '';
  envVars['UXL_IMAGE_CACHE_DOMAIN'] =
    pageProps.currentLocale === constants.LOCALE_KEY_CHINA ? imageDomainCN : imageDomain;
  constants?.NEXT_PUBLIC_ENV_KEYS?.map(envKey => (envVars[envKey] = serverENV[envKey]));
  setEnvVars?.({ ...cnEnvVars, ...envVars });
  let subDirectoryPrefix = '';
  if (model) {
    subDirectoryPrefix = model.subDirectoryPrefix ? model.subDirectoryPrefix : '';
    setSubDirectoryPrefix(subDirectoryPrefix);
    setHWSReservationURL(model?.hwsURL || '');
    setRitzCarltonUrl(model?.rcURL || '');
  }

  defineComponentMapping(allowedComponents, resolvedUrl, isAuthorMode, isDTT, pageProps);

  const pageContext = useMemo(() => {
    /** attache memo value */
    return {
      /**use only to read the values */
      sessionData: sessionData,
      sessionTimedOutPage: sessionTimeOutPage,
      isEAASignIn: isEAA,
      serverENV: pageProps?.serverENV,
      currentLocale: pageProps.currentLocale, // to get current locale
      isMobileAuthFeatureEnabled: isMobileAuthFeatureEnabled,
      isNonChinaMobileEnabled: isNonChinaMobileEnabled,
      isOverViewPage: pageProps?.requestUri?.toLowerCase() === OVERVIEW_PAGE_URL?.toLowerCase(),
      uxlErrorMessage:
        model?.uxlFailureErrorMessage ??
        '<strong>We’re having trouble fetching some data.</strong> Please try again in a moment.',
      memberStatusList: model?.memberStatusList,
      sweepstakesPreprocessorResponse: sweepstakesPreprocessorResponse,
      rsaPublicKey: pageProps.rsaPublicKey ? pageProps.rsaPublicKey : null,
      enrollAndLinkStatus: pageProps.enrollAndLinkStatus ? pageProps.enrollAndLinkStatus : null,
      isResetTokenValid: isResetTokenValid,
      userState: userState,
      nuaDetails: pageProps.nuaDetails ? pageProps.nuaDetails : null,
      enableTimer: model?.timerFlag,
      isPointsTransferSuccess: isPointsTransferSuccess,
      localeBasedNameSwapConfig: model?.localeBasedNameSwapConfig,
      rtlEnable: model?.rtlEnable,
    };
  }, [sessionTimeOutPage]);

  useEffect(() => {
    if (isAuthorMode) {
      let remoteURL: URL | string;
      try {
        remoteURL = new URL(document.body.dataset.remoteUrl || '');
        remoteURL = remoteURL.origin;
      } catch (e) {
        remoteURL = '';
      }
      const assetUrl = `${
        process.env['NEXT_PUBLIC_ASSET_DOMAIN'] || remoteURL || 'https://www.marriott.com'
      }${NEXT_GLOBAL_ASSET_PREFIX}`;

      const globalStyle = document.createElement('link');

      globalStyle.rel = 'stylesheet';
      globalStyle.href =
        assetUrl +
        (pageProps?.model?.rtlEnable == 'true'
          ? envVars['GLOBAL_RTL_CSS_ENABLED_PAGES'] +
            ',' +
            envVars['RTL_PLUGIN_ENABLED_PAGES']?.includes(pageProps?.requestUri)
            ? `global-styles/${globalStylesVersion}/marriot.global.rtl.css`
            : `global-styles/${globalStylesVersion}/marriot.global.css`
          : `global-styles/${globalStylesVersion}/marriot.global.css`);
      document.head.appendChild(globalStyle);
    }
    const isPageFound =
      BACKGROUND_COLOR_PAGE_ARRAY.includes(pageProps?.requestUri) ||
      pageProps?.requestUri?.includes(SWEEPSTAKES_ENTRY_PAGE_URL);
    if (pageProps?.requestUri && isPageFound) {
      document.body.className += ' color-scheme3';
    }
    const dataLayerObj = window.dataLayer ?? {};
    UpdateDatalayerObj(dataLayerObj);
    // updated header nav items with subdirectory prefix
    correctSubDirectoryPathForHeader();
    // This is added as a part of NUA globalization as we are reusing book components. We are getting these labels from page level model which are required on below methods that are used for updating the locale, date formatting & currency symbol.
    if (model && pageProps.nuaDetails) {
      const {
        language = '',
        dateOrdinal = '',
        dateFormat = '',
        subDirectoryPrefix = '',
        localeCurrencySymbol = '',
      } = model;
      loadLocaleCurrency(localeCurrencySymbol);
      setSessionKey('SUBDIRECTORYCONTEXTPATH', subDirectoryPrefix);
      loadLocale(language, dateOrdinal, dateFormat);
    }
  }, []);

  Object.defineProperty(rtlPluginTs, 'name', { value: 'rtlPluginMonorepo' });
  return (
    <PageContext.Provider value={pageContext}>
      {/* TODO: STATIC PAGE ARRAY CONDITION ADDED IS TEMPORARY, IT NEEDS TO BE REMOVED ONCE RTL CLEAN UP IS DONE FOR ALL ACCOUNT PAGES */}
      {envVars['RTL_PLUGIN_ENABLED_PAGES']?.includes(pageProps?.requestUri) ? (
        <StyleSheetManager stylisPlugins={pageProps?.model?.rtlEnable == 'true' ? [rtlPluginTs] : []}>
          <Component {...pageProps} />
        </StyleSheetManager>
      ) : (
        <Component {...pageProps} />
      )}
    </PageContext.Provider>
  );
};

export default App;
