// Styles for Footer go here.
import styled from 'styled-components';
import { baseVariables, toRem, Text, Link } from '@marriott/mi-ui-library';

export const StyledContainer = styled.div`
  .add-border {
    border-bottom: solid ${toRem(0.5)} ${baseVariables.color['neutral40']};
  }

  .z-3 {
    z-index: 49 !important;
  }
`;

export const StyledRow = styled.div`
  @media only ${baseVariables.mediaQuery.md} {
    .thin-privacy-items {
      margin-right: ${toRem(8)};
      padding-right: ${toRem(8)};
      border-right: solid ${toRem(0.5)} ${baseVariables.color['neutral40']};
      margin-bottom: ${toRem(8)};
    }
  }

  .thin-privacy-items:last-of-type {
    border: none;
    margin-right: none;
    padding-right: none;
  }
  margin-bottom: ${toRem(24)};
`;

const getBulgariStyles = (isBulgari?: boolean) => {
  if (isBulgari) {
    return `
      background-color: ${baseVariables.color['merch50']} !important; 
      color: ${baseVariables.color['base10']} !important; 
      // Force children to inherit colors from the parent.
      & * {
        background-color: inherit !important; 
        color: inherit !important;
      }
    `;
  }
  return '';
};

export const StyledFooterContainer = styled.div<{ isBulgari?: boolean }>`
  background-color: ${baseVariables.color['base10']};
  padding-top: ${toRem(48)};
  padding-bottom: ${toRem(48)};

  .m-accordion-container .accordion-button {
    border-top: ${toRem(0.5)} solid ${baseVariables.color['neutral40']};
  }

  ${({ isBulgari }) => getBulgariStyles(isBulgari)}
`;

export const StyledText = styled(Text)`
  font-size: ${toRem(22)};
`;

export const StyledP = styled.p`
  font-size: ${toRem(22)};
`;

export const StyledLink = styled(Link)`
  font-size: ${toRem(14)} !important;
`;

export const LanguageSelectorContainer = styled.div`
  .footer-language-selector {
    background-color: ${baseVariables.color['base10']};
    padding-left: 0;
  }
`;

export const DestinationWrapper = styled.div`
  #default-destination-accordion_body {
    margin: ${toRem(8)} 0 0 0;
  }
`;

export const DestinationOnTopWrapper = styled.div`
  border-bottom: solid ${toRem(0.5)} ${baseVariables.color['neutral40']};
  #destination-on-top-accordion_body {
    margin: ${toRem(8)} 0 0 0;
  }
  .accordion__title {
    margin-top: 0 !important;
  }
  .col-md-3 {
    padding: 0;
    margin-right: ${toRem(16)};
  }
  margin-left: ${toRem(-0.25)};
  margin-right: ${toRem(-0.5)};
  padding-bottom: ${toRem(16)};
`;

export const LinkWrapper = styled.div`
  display: flex;
  .html-content {
    // OneTrust link is inserted inside the span with this ID.
    // The link OneTrust inserts has inline styles so the
    // !importants are neessary to override that.
    #teconsent {
      a {
        font: normal normal ${baseVariables.font.fontWeightRegular} ${baseVariables.font.fontS} /
          ${baseVariables.font.fontXl} ${baseVariables.font.fontSwiss} !important;
        font-size: ${toRem(14)} !important;
        color: ${baseVariables.color['neutral30']} !important;
        line-height: ${baseVariables.font.lineHeightXs} !important;
        display: block !important; // Normalize text spacing when line wraps.
        &:hover {
          color: ${baseVariables.color['base20']} !important;
          font-weight: ${baseVariables.font.fontWeightMedium} !important;
        }
      }
    }
  }
  a:hover {
    text-decoration: underline;
  }
`;

export const MarriottWrapper = styled.div`
  .col-md-3 {
    padding: 0;
  }
  .col-md-3:not(:nth-of-type(4)) {
    margin-right: ${toRem(16)};
  }
  margin-left: ${toRem(-0.25)};
  margin-right: ${toRem(-0.5)};

  // fix for weird overflow issue in this specific screen size
  @media (min-width: 768px) and (max-width: 799px) {
    .col-md-3:nth-of-type(4) {
      display: flex;
      flex: auto;
    }
  }

  @media (min-width: 992px) and (max-width: 1040px) {
    .col-md-3:nth-of-type(4) {
      display: flex;
      flex: auto;
    }
  }
`;

export const LineHeightWrapper = styled.div`
  p {
    margin-bottom: 0;
  }
`;
