import { StateCreator } from 'zustand';
import Cookies from 'js-cookie';

import { NEXT_DATA } from 'next/dist/shared/lib/utils';
import { canUseDOM } from '@marriott/shared/mi-helper-utils';

import { createAppStore } from '../zustand';
import { UserDetailsState, UserProfileData } from './userDetailsStore.types';

declare global {
  export interface Window {
    __NEXT_DATA__: NEXT_DATA;
    dataLayer?: Record<string, unknown>;
  }
}

export const initialState = {
  userProfileData: {
    status: 'FAILURE',
  },
  isSignedInUser: false,
  isRememberedUser: false,
};

export const userDetailsStore: StateCreator<UserDetailsState> = set => {
  return {
    ...initialState,
    fetchUserDetails: async (getUserDetailsPath: string) => {
      // Set up payload for userDetails fetch request.
      const sessionToken = canUseDOM ? Cookies.get('sessionID') : '';
      const localeKey = canUseDOM ? window?.__NEXT_DATA__?.props?.['pageProps']?.currentLocale : '';
      const payload = {
        sessionToken: sessionToken,
        context: {
          context: {
            localeKey,
          },
        },
      };

      try {
        // currently we have to support both hybrid and phoenix endpoints.
        const response = await fetch(
          getUserDetailsPath,
          /phoenix/i.test(getUserDetailsPath)
            ? {
                method: 'GET',
                ...payload,
              }
            : {
                headers: { 'Content-Type': 'application/json' },
                method: 'POST',
                body: JSON.stringify(payload),
              }
        );

        // Response status not OK, error.
        if (!response.ok) {
          throw new Error(`HTTP error fetching userDetails. Status: ${response.status}`);
        }

        const data: UserProfileData = await response.json();
        return set({
          userProfileData: data,
          isSignedInUser: data.status.toLowerCase() === 'success',
          isRememberedUser: !!data?.rememberedUser,
        });
      } catch (error) {
        // Other errors.
        if (error instanceof Error) {
          console.error('Fetch userDetails error:', error.message);
        }
      }
    },
  };
};

export const useUserDetailsStore = createAppStore(userDetailsStore, {
  usePersistentStore: false,
});
