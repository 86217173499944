/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import clsx from 'clsx';
import { PopupHeaderProps } from '../Modal.types';
import { StyledPopupHeaderDiv, StyledCloseDiv } from './index.styles';
import { useCheckBreakpoint } from '../../../hooks';
import { Heading } from '../../../atoms/Heading';
import { headingType, tags } from '../../../utils/enums/enums';

declare module 'react' {
  interface HTMLAttributes<T> {
    custom_click_track_value?: string;
  }
}

const PopupHeader: FC<PopupHeaderProps> = ({
  className,
  labelText,
  popupHeaderOnCLoseFunc,
  popupCloseClassName,
  headingTagElement = tags.h4,
  closeIconClickTrackValue,
  customHeadingClass,
  closeBtnAriaLabel,
}) => {
  const handleClick = (event: any): void => {
    if (event.key === 'Enter' || event.type === 'click' || event.keyCode === 13) {
      if (popupHeaderOnCLoseFunc) {
        popupHeaderOnCLoseFunc(false);
      }
    }
  };
  const isDesktop = useCheckBreakpoint('viewportM'); // check if viewport is desktop till 768px
  const clickTrackingLoc = 'Phoenix Search Results';
  return (
    <StyledPopupHeaderDiv className={className}>
      {labelText && (
        <Heading
          element={headingTagElement}
          variation={headingType.title}
          customClass={clsx(customHeadingClass ?? (isDesktop ? 't-subtitle-xl' : 't-font-m'), 'mb-0', 'header-heading')}
          titleText={labelText}
        />
      )}
      <div
        className={clsx('popup-close', 'custom_click_track', popupCloseClassName)}
        tabIndex={0}
        role="button"
        onClick={(e): void => {
          handleClick(e);
        }}
        onKeyDown={(e): void => {
          handleClick(e);
        }}
        {...{
          custom_click_track_value: closeIconClickTrackValue
            ? closeIconClickTrackValue
            : `${clickTrackingLoc}| Close Button |internal`,
        }}
        aria-label={closeBtnAriaLabel || 'Close pop up'}
      >
        <StyledCloseDiv className="icon-clear"></StyledCloseDiv>
      </div>
    </StyledPopupHeaderDiv>
  );
};

export default PopupHeader;
