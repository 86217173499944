import { accountConstants, fallbackImages, fallbackImagesCN, imageDomain, imageDomainCN } from './constants';
import { checkChinaLocale } from './helper';
export interface RenditionProps {
  renditionPath?: string;
  damPath?: string;
  mediaValue?: string;
  mediaQuery?: string;
  width?: number;
  height?: number;
}

export type imageDimensionsType = {
  width: number;
  height: number;
};
const outputQuality = 90;
const outputInterpolation = 'progressive-bilinear';

export const imageDimensionsSize = [576, 768, 992, 1140, 1420];
export const imageDimensionsSizeMobile = [440, 570, 769, 992];
/**
 * Construct a string with all arguments for image processing.
 */
export const getImgUrlQueryStringWithWidth = (width: number) => {
  // get image according to width cropping ratio accordingly handle depend on width
  return `?output-quality=${outputQuality}&interpolation=${outputInterpolation}&downsize=` + width + 'px:*';
};

export const generateImageRenditions = (
  src: string,
  imageSizeList: { imageWidth?: number; imageHeight?: number; breakpoint: string; queryType: string }[]
) => {
  return imageSizeList.map(rendition => {
    return {
      renditionPath: `${src}${accountConstants.DOWNSIZE_PARAM}${
        rendition?.imageWidth ? rendition?.imageWidth * 2 + 'px' : '*'
      }:${rendition?.imageHeight ? rendition?.imageHeight * 2 + 'px' : '*'}`,
      mediaValue: rendition.breakpoint,
      width: 0,
      dynamic: false,
      damPath: src,
      mediaQuery: rendition.queryType,
      height: 0,
    };
  });
};

export const generateAEMImageRenditions = (
  src: string,
  imageSizeList: Array<{ imageWidth: number; breakpoint: string; queryType: string }> = [
    {
      breakpoint: accountConstants.VIEWPORT_SIZE.desktop,
      queryType: 'min-width',
      imageWidth: 1440,
    },
    {
      breakpoint: accountConstants.VIEWPORT_SIZE.largerTablet,
      queryType: 'min-width',
      imageWidth: 1024,
    },
    {
      breakpoint: accountConstants.VIEWPORT_SIZE.tablet,
      queryType: 'min-width',
      imageWidth: 992,
    },
    {
      breakpoint: accountConstants.VIEWPORT_SIZE.mobile,
      queryType: 'min-width',
      imageWidth: 680,
    },
    {
      breakpoint: accountConstants.VIEWPORT_SIZE.mobile,
      queryType: 'max-width',
      imageWidth: 480,
    },
  ]
) => {
  if (src) {
    return imageSizeList.map(rendition => {
      return {
        renditionPath: `${src}${accountConstants.DOWNSIZE_PARAM}${rendition?.imageWidth}px:* 1x,${src}${
          accountConstants.DOWNSIZE_PARAM
        }${rendition?.imageWidth * 2}px:* 2x`,
        mediaValue: rendition.breakpoint,
        width: 0,
        dynamic: false,
        damPath: src,
        mediaQuery: rendition.queryType,
        height: 0,
      };
    });
  }
  return [];
};

export const getImageRenditionsWithCropQueryString = (
  croppingRatio: number,
  outputQuality: number,
  outputInterpolation: string,
  imageDimensions: imageDimensionsType
) => {
  return (
    `?output-quality=${outputQuality}&interpolation=${outputInterpolation}&crop=${
      imageDimensions.width * croppingRatio
    }:${imageDimensions.height * croppingRatio};*,*&downsize=` +
    imageDimensions.width +
    'px:*'
  );
};

export const genrateImageRenditionPathForAllMobileAndTablet = (src: string) => {
  return imageDimensionsSizeMobile.map(key => {
    return {
      renditionPath: `${src}${getImgUrlQueryStringWithWidth(key)}`,
      mediaValue: `${key}px`,
      width: 0,
      dynamic: false,
      damPath: src,
      mediaQuery: 'max-width',
      height: 0,
    };
  });
};

export const addParamToRenditions = (renditions?: RenditionProps[]) => {
  return (
    renditions &&
    renditions.map(rendition => {
      const temRendition = rendition?.renditionPath?.split(',').map(path => `${path}&fmt=png-alpha`);
      return {
        ...rendition,
        renditionPath: temRendition?.join(', '),
      };
    })
  );
};

export const getImageDomain = (currentLocale: string) => {
  if (checkChinaLocale(currentLocale)) {
    return imageDomainCN;
  }
  return imageDomain;
};

type JsonValue = string | number | boolean | null | JsonObject | JsonArray;
interface JsonObject {
  [key: string]: JsonValue;
}
interface JsonArray extends Array<JsonValue> {}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateImageDomain = (jsonResponse: any, currentLocale: string) => {
  const updatedResponse = JSON.parse(JSON.stringify(jsonResponse));
  if (checkChinaLocale(currentLocale)) {
    return replaceDomainInJson(updatedResponse);
  }
  return updatedResponse;
};

/**
 * Replaces all occurrences of a specified domain in a JSON object or array with a new domain.
 *
 * @param {JsonValue} obj - The JSON value to process. It can be a string, number, boolean, null, object, or array.
 * @param {string} oldDomain - The domain to be replaced.
 * @param {string} newDomain - The new domain to replace the old domain with.
 * @returns {JsonValue} - The processed JSON value with the domain replaced.
 *
 * @example
 * const json = {
 *   "url": "http://cache.marriott.com/page",
 *   "nested": {
 *     "url": "http://cache.marriott.com/another-page"
 *   },
 *   "array": [
 *     "http://cache.marriott.com/page1",
 *     "http://cache.marriott.com/page2"
 *   ]
 * };
 * const result = replaceDomainInJson(json, "cache.marriott.com", "cache.marriott.com.cn");
 * console.log(result);
 * // {
 * //   "url": "http://cache.marriott.com.cn/page",
 * //   "nested": {
 * //     "url": "http://cache.marriott.com.cn/another-page"
 * //   },
 * //   "array": [
 * //     "http://cache.marriott.com.cn/page1",
 * //     "http://cache.marriott.com.cn/page2"
 * //   ]
 * // }
 */

export const replaceDomainInJson = (obj: JsonValue): JsonValue => {
  const regex = new RegExp(imageDomain, 'g');
  if (typeof obj === 'string') {
    return obj.replace(regex, imageDomainCN);
  } else if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      obj[i] = replaceDomainInJson(obj[i]);
    }
  } else if (typeof obj === 'object' && obj !== null) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        obj[key] = replaceDomainInJson(obj[key]);
      }
    }
  }
  return obj;
};

export const getFallbackImage = (currentLocale: string) => {
  if (checkChinaLocale(currentLocale)) {
    return fallbackImagesCN;
  }
  return fallbackImages;
};
