/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import React, { FC, useEffect, useId, useState } from 'react';
import clsx from 'clsx';

import { InputTextField, Types } from '@marriott/mi-ui-library';

import { Dropdown } from '../Dropdown';
import { PhoneNumberFieldProps } from './PhoneNumberField.types';
import { StyledPhoneNumberField } from './PhoneNumberField.styles';

// Use named rather than default exports.
export const PhoneNumberField: FC<PhoneNumberFieldProps> = props => {
  const {
    label,
    mobileInputVal,
    isInputError,
    inputErrorMsg,
    placeHolderText,
    defaultCCOption,
    dropdownCCOptions,
    dropdownId,
    disabled = false,
    fetchMobileNumber,
    isNonChinaMobileEnabled = false, //this flag is used to identify if country code dropdown in enabled by default its dissabled
    isRegisteredNumber = false,
    isMobileEditable = false,
    showIdandValueinOptions = false,
    numberLength = 16,
    badgeProp,
    inputTextLabel,
  } = props;

  const [countryCode, setCountryCode] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [isPrePopulatedNumber, setPrePopulatedNumber] = useState(false);
  useEffect(() => {
    setCountryCode(defaultCCOption);
  }, [defaultCCOption]);

  useEffect(() => {
    setMobileNumber(mobileInputVal);
    !isMobileEditable && setPrePopulatedNumber(mobileInputVal?.length > 0);
  }, [mobileInputVal]);
  const inputId = useId();
  const handleCountryCodeChange = (option: any) => {
    setCountryCode(option?.value);
    setCompleteMobileNumber(option?.value, mobileNumber);
  };
  const handleKeyPress = (ev: any) => {
    /**
     * handle key press event on input field that will submit the
     * form
     */
    if (ev.key === 'Enter' || ev.keyCode === 13) {
      // handleCountryCodeChange()
    }
  };
  const setCompleteMobileNumber = (cc: string, mobNum: string) => {
    fetchMobileNumber({
      countryCode: cc,
      mobileNumber: mobNum,
    });
  };

  return (
    <StyledPhoneNumberField data-component-name="m-account-PhoneNumberField" data-testid="account-PhoneNumberField">
      <div className="d-flex row">
        <div className="dropdown-field col-12 col-sm-6">
          <label
            className="country-label m-ellipsis-1line numberfieldwidth"
            id="dropdown-label-country-code"
            htmlFor="dropdown-label-fp-country"
            data-testid="dropdown-label-fp-country"
          >
            {label}
          </label>
          <Dropdown
            defaultOption={countryCode || '+1'}
            dropdownOptions={dropdownCCOptions || []}
            onChange={handleCountryCodeChange}
            customClassName={clsx('country-code-dropdown', { 'is-disabled': disabled })}
            dropdownId="country-code"
            id={dropdownId || 'dropdown-label-fp-country'}
            labelSize={Types.size.medium}
            showIdandValueinOptions={showIdandValueinOptions}
          ></Dropdown>
          {disabled || !isNonChinaMobileEnabled || isPrePopulatedNumber ? <div className="blurred"></div> : ''}
        </div>
        <div className="inputtext-field col-12 col-sm-6 mt-sm-0 mt-5">
          <InputTextField
            label={inputTextLabel || ''}
            inputValue={mobileNumber}
            showErrorMessage={isInputError}
            placeHolderText={placeHolderText}
            messageToShow={inputErrorMsg}
            setErrorHtml={true}
            messageClass="error-label"
            inputMaxLength={numberLength}
            infoLabel={'Phone-number-field'}
            getInputProps={() => ({
              /** setReadOnly props for remeber case */
              autoComplete: 'off',
              onKeyUp: handleKeyPress,
              id: `${inputId}-Dialcode`,
              disabled: disabled || isRegisteredNumber || isPrePopulatedNumber,
            })}
            getLabelProps={() => ({
              htmlFor: `dropdown-label-fp-country`,
            })}
            className={clsx(
              'm-input-field',
              disabled || isRegisteredNumber || isPrePopulatedNumber ? 'is-disabled' : ''
            )}
            getInputValue={(val: string) => {
              setMobileNumber(val);
              setCompleteMobileNumber(countryCode, val);
            }}
            showBadge={!!badgeProp}
            badgeValue={badgeProp}
          />
        </div>
        {disabled || isPrePopulatedNumber ? <div className="blurred"></div> : ''}
      </div>
    </StyledPhoneNumberField>
  );
};
