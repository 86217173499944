/* eslint-disable @typescript-eslint/no-explicit-any */
import { constants, URL_CONSTANTS, hardCodedAllLocale } from '../constants';
import { AxiosRequestConfig } from 'axios';
import { ServerResponse } from 'http';
import { v4 as uuidV4 } from 'uuid';
import { getDayJsDate, getDayJsMonths, convertArabicToEnglishNumbers } from './dateFunctions';
import { logger } from './logger';
import { axiosWrapper } from './axiosClient';
import { eventUtil } from './eventUtil';
import { getServerTargetPayload } from './serverTarget';
import { datalayerProps } from './serverTarget/index.types';
import { RequestProps } from './dataLayer/index.types';
import moment from 'moment';
declare global {
  interface Window {
    MI_S2_ARIES_JS_LIST: [];
    impressionTrack: (value: string) => Record<string, string>; //temporary change
    impressionArr: Array<string>;
    resSignInModal: string;
    dataLayer?: Record<string, unknown>;
    bookPrintModalUXLStatus?: Record<string, unknown>;
    bookOCJUXLStatus?: Record<string, unknown>;
    sessionDataClient?: Record<string, unknown>;
    resOneClickJoinModal?: string;
    isBulgari?: boolean;
  }
  interface String {
    getSymbol(): string;
  }
}
// eslint-disable-next-line @typescript-eslint/no-var-requires
const objectMapper = require('object-mapper');

const {
  BREAKPOINT_TABLET,
  BREAKPOINT_DESKTOP,
  BREAKPOINT_LARGE_DESKTOP,
  PARTNER_SITES,
  ENV_DEV,
  PREFERENCES_ERS_CODE,
  VIEWPORT_SIZE,
  APPLICATION_NAME,
  ROUTING_CONFIG,
  EXPERIENCE_SEGMENT,
  BULGARI_EXPERIENCE_SEGMENT,
  BULGARI_HOST,
  NOT_ALLOWED_STATE_FIELD,
  CLIENT_ID,
  NO_DECIMAL_LOCALE,
  DEFAULT_LANG,
  PLANTASTIC_PRODUCTS,
  FAMTASTIC_PRODUCTS,
  TRAVEL_AGENT_PRODUCTS,
  PLANTASTIC_CODE,
  FAMTASTIC_CODE,
  TRAVEL_AGENT_CODE,
  THE_RITZ_CARLTON_CODE,
  SESSION_VALIDATION_FAILED_OR_EXPIRED,
  CART_VALIDATION_FAILED_OR_EXPIRED,
  ACCEPT_LANG,
  MARRIOTT_BRAND_CODE,
  DEFAULT_LANGUAGE,
  SERVER_TARGET_MBOX_INDEX,
  SERVER_TARGET_MBOX_NAME,
  SERVER_TARGET_MBOX_RLM_NAME,
  PHX_TARGET_COOKIE_NAME,
  OLD_RECIPE,
  COMPLETE,
  INDONESIA_DEFAULT_LOCALE,
  INDONESIA_LOCALE,
  LOOKUP_RESERVATION,
  LOOKUP_RESERVATION_FOR_AUTH_FLOW_302_TO_MYTRIPS,
  RES_LOOKUP_OVERLAY,
  BULGARI_BRAND_CODE,
  ARABIC_PERIODS,
} = constants;

const {
  PLAY_SERVICE_PATH,
  MI_VENDOR_JS,
  MI_FOUNDATION_JS,
  CONFIRMATION_URL,
  RRD_URL,
  IFRAME_CARD_URL,
  GUEST_INFO_URL,
  RRD_GI_URL,
  RATE_LIST_MENU_URL,
  CACHE_CLEAR_URL,
  UPCOMING_RES_IFRAME,
  UPCOMING_RES_URL,
  UPCOMING_RES_CANCELLATION,
  MODIFY_RLM,
  UPGRADE_RES_URL,
  THE_RITZ_CARLTON_URL,
  TRC_HOTEL_REVIEW_PATH,
  ERROR_PAGE_URL,
  EXPIRED_SESSION_URL,
  RLM_REDIRECT_URL,
  MI_ASSETS,
  EXPIRED_RES_URL,
  REDIRECT_PAGE_URL,
  AVAILABILITY,
  FIND_RESERVATION_LIST,
  BULGARI_HOTEL_URL,
  HTTPS_STRING,
} = URL_CONSTANTS;

const { NEXT_PUBLIC_IS_SESSION_CALL_ON } = process.env;
const { NEXT_PUBLIC_NGINX_SESSION_URL } = process.env;
const { NEXT_PUBLIC_PRE_PROCESSING_RES_CONF_URL } = process.env;
const { NEXT_PUBLIC_PRE_PROCESSING_RRD_URL } = process.env;
const { NEXT_PUBLIC_PRE_PROCESSING_GI_URL } = process.env;
const { NEXT_PUBLIC_PRE_PROCESSING_RRD_GI_URL } = process.env;
const { NEXT_PUBLIC_MI_SESSION_URL } = process.env;
const { NEXT_PUBLIC_PRE_PROCESSING_RLM_URL } = process.env;
const { NEXT_PUBLIC_PRE_PROCESSING_UPCOMINGRES_URL } = process.env;
const { NEXT_PUBLIC_PRE_PROCESSING_CANCELRES_URL } = process.env;
const { NEXT_PUBLIC_MODIFY_RLM_URL } = process.env;
const { NEXT_PUBLIC_PRE_PROCESSING_UPGRADERES_URL } = process.env;
const { NEXT_PUBLIC_PRE_PROCESSING_EXPIREDRES_URL } = process.env;
const { NEXT_PUBLIC_PRE_PROCESSING_800PAGE_URL } = process.env;
const { NEXT_PUBLIC_PRE_PROCESSOR_LOCAL } = process.env;
const { NEXT_PUBLIC_DYNAMIC_ROUTING_URL_LOCAL } = process.env;
const { NEXT_PUBLIC_APPLICATION_NAME } = process.env;
const { NEXT_PUBLIC_DYNAMIC_ROUTING_URL } = process.env;
const { NEXT_PUBLIC_PRE_PROCESSING_AVAILABILITY_URL } = process.env;
const { NEXT_PUBLIC_LOOKUP_RESERVATION_URL } = process.env;
const { NEXT_PUBLIC_PROXY_HOST } = process.env;

let SESSION_CALL_URL = NEXT_PUBLIC_NGINX_SESSION_URL;

if (process.env['NODE_ENV'] === ENV_DEV) {
  SESSION_CALL_URL = NEXT_PUBLIC_MI_SESSION_URL;
}

export const DATA_LAYER_PROPERTIES = {
  NEXT_PUBLIC_ORIENTATION_LANDSCAPE: 'Landscape',
  NEXT_PUBLIC_ORIENTATION_PORTRAIT: 'Portrait',
  NEXT_PUBLIC_PAGE_BREAKPT: 'Page Loaded on ',
  DATALAYER_PORTRAIT_WIDTH_BREAKPOINT: 576,
};

/**
 * returns the boolean true/false.
 */
export const canUseDOM = !!(typeof window !== 'undefined' && window.document);

export const getProcessEnvs = () => {
  if (canUseDOM) {
    const envTag = document.getElementById('__SERVERENV__');
    if (envTag) {
      const envObject = JSON.parse(envTag.innerHTML);
      return envObject;
    }
    return process.env;
  }
  return process.env;
};

type preDataType = { experienceSegments?: { brandCode: string }; locale?: string };

// This method is to dynamically get the page model json data according to the model body in post call.
// We will use this method in preprocessor call instead of getPageModel that will not required.
export async function getDynamicPageModel(
  path: string,
  preHeader: Record<string, string>,
  preData: preDataType,
  sessionData: { sessionToken?: string },
  serverExpFragArr: string[],
  isbulgariFlow = false
) {
  const { log, pLog } = global.loggerInstance('ModelCall');
  const brandCode = preData?.experienceSegments?.brandCode?.toLowerCase() || MARRIOTT_BRAND_CODE.toLowerCase();
  const applicationName = NEXT_PUBLIC_APPLICATION_NAME || APPLICATION_NAME;
  const locale = preHeader[ACCEPT_LANG]?.includes(DEFAULT_LANGUAGE) ? DEFAULT_LANGUAGE : preHeader[ACCEPT_LANG];
  const bulgariExperienceSegment = isbulgariFlow
    ? [`${BULGARI_EXPERIENCE_SEGMENT}_${path.split('?')[0].split?.('/')[2]}`]
    : [];

  console.log('bulgariExperienceSegment', bulgariExperienceSegment);
  const modelBody = {
    requestType: ROUTING_CONFIG,
    seoUrl: path.split('?')[0],
    localeKey: isIndonesiaLocale(preData?.locale, locale),
    sessionToken: sessionData?.sessionToken,
    experienceSegment: [...EXPERIENCE_SEGMENT, ...serverExpFragArr, ...bulgariExperienceSegment],
    applicationName: applicationName,
    selectors: brandCode,
  };
  log.debug(`:::modelBody::: ${JSON.stringify(modelBody)}`);
  let pageModel: any = {};
  log.debug(`API url: ${NEXT_PUBLIC_DYNAMIC_ROUTING_URL}`);
  const apiStartTime = new Date().getTime();
  let dynamicPageUrl = NEXT_PUBLIC_DYNAMIC_ROUTING_URL;

  // Development Check to run getDynamicPageModel on local with mock API.
  if (isDev) {
    dynamicPageUrl = NEXT_PUBLIC_DYNAMIC_ROUTING_URL_LOCAL;
  }
  try {
    if (dynamicPageUrl !== '') {
      log.info(`fetching page model: ${dynamicPageUrl}`);

      const response = await axiosWrapper.post(`${dynamicPageUrl}`, modelBody, {
        headers: preHeader,
      });
      pageModel = response.data.data;
      log.debug(`API call success with status code: ${response.status}`);
    }
  } catch (e) {
    log.error(`API url: ${NEXT_PUBLIC_DYNAMIC_ROUTING_URL} | API call failed: ${e}`);
    throw new Error('Page Model call failed');
  }
  pLog.log('API call performance timing', apiStartTime, new Date().getTime());
  return pageModel;
}

/**
 * This is to get the right locale for Indonesia site.
 * @param locale locale read from predata.
 * @param localeFromHeader  locale read from the header.
 * @returns right locale
 */
export function isIndonesiaLocale(locale: string | undefined, localeFromHeader: string) {
  if (
    locale === INDONESIA_DEFAULT_LOCALE ||
    localeFromHeader === INDONESIA_DEFAULT_LOCALE ||
    locale === INDONESIA_LOCALE ||
    localeFromHeader === INDONESIA_LOCALE
  ) {
    return INDONESIA_LOCALE;
  }
  return locale || localeFromHeader;
}

export function getItemFromPageModel(pageModel: any, itemPath: string) {
  const parts = itemPath.split('/');
  let obj = pageModel;

  for (let i = 0; i < parts.length && obj; i++) {
    obj = (obj[':items'] || {})[parts[i]];
  }

  return obj || {};
}

/**
 * Verify if its small mobile screen viewport
 */
function isSmallMobileView(): boolean {
  return window.matchMedia(`(max-width: ${BREAKPOINT_TABLET - 1}px)`).matches;
}

/**
 * Verify if its tablet viewport
 */
function isTabletView(tabletDimension: number): boolean {
  return window.matchMedia(`(max-width: ${tabletDimension}px)`).matches;
}

/**
 * Verify if its desktop viewport
 */
function isDesktop(): boolean {
  return window.matchMedia(`(max-width: ${BREAKPOINT_LARGE_DESKTOP}px)`).matches;
}

export const getViewPortValue = (tabletDimension = BREAKPOINT_DESKTOP): 'mobile' | 'tablet' | 'desktop' => {
  let dataIndex: 'mobile' | 'tablet' | 'desktop';
  if (isSmallMobileView()) {
    dataIndex = 'mobile';
  } else if (isTabletView(tabletDimension)) {
    dataIndex = 'tablet';
  } else if (isDesktop()) {
    dataIndex = 'desktop';
  } else {
    dataIndex = 'desktop';
  }
  return dataIndex;
};

export const transformResponse = (result: any, mapObject: Record<string, string>) => {
  return objectMapper(result, mapObject) || {};
};

export const total = (arr: number[]) => {
  return arr.reduce((prev: number, val: number) => {
    return prev + val;
  }, 0);
};

/**
 * This is an IIFE function to return whether Locale and its currency has decimal placements or not.
 */
export const isNoDecimalNumber = (() => {
  const sessionObject = getWindowSession();
  const locale = sessionObject?.locale as keyof typeof NO_DECIMAL_LOCALE;
  const propertyCurrency = sessionObject?.cacheData?.data?.AriesReservation?.propertyCurrency;
  return NO_DECIMAL_LOCALE[locale]?.includes(propertyCurrency);
})();

// function to handle number which need internalization based on the value based
export const numberWithCommas = (val: number | string) => {
  if (isNoDecimalNumber && Number(val)) {
    return canUseDOM ? numberWithDelimiter(Number(val)?.toFixed(0)) : Number(val)?.toFixed(0);
  }
  return canUseDOM ? numberWithDelimiter(val) : String(val);
};

// fucntion to handle the price amounts which need fixed decimal placements
export const priceWithCommas = (val: number | string) => {
  if (isNoDecimalNumber && Number(val)) {
    return canUseDOM ? numberWithDelimiter(Number(val)?.toFixed(0)) : Number(val)?.toFixed(0);
  }
  return canUseDOM ? numberWithDelimiter(Number(val)?.toFixed(2)) : Number(val)?.toFixed(2);
};

export const createDecimalValue = (value: number, decimalPoint: number): number => {
  return Number((value / Math.pow(10, decimalPoint)).toFixed(decimalPoint));
};

/**
 * returns the decimal count of the value
 * @param value
 * @returns
 */
export const getDecimalCount = (value: number | string) => {
  const match = value?.toString().match(/(?:\.(\d+))?$/);
  if (!match?.[1]) {
    return 0;
  }
  return match[1].length;
};

/**
 * return the localized delimiter value for Number and Amount values
 * @param value
 * @returns
 */
export const numberWithDelimiter = (value: number | string) => {
  const docLang = document?.documentElement?.lang.split(/_|-/)[0].toLowerCase() || DEFAULT_LANG;
  if (typeof value === 'string') {
    return Number(value)?.toLocaleString(docLang === '' ? DEFAULT_LANG : docLang, {
      minimumFractionDigits: getDecimalCount(value),
    });
  }
  return value?.toLocaleString(docLang);
};

/**
 * returns the date concatenated with time in mm/dd/yyyy hours:minutes:second
 * @param {string} date (date in string format)
 * @param {string} time (time in string format)
 */
export const getChangedDateTimeFormat = (
  date: string | number | boolean | null,
  time: string | number | boolean | null
) => {
  if (typeof date === 'string' && typeof time === 'string') {
    const dateArray = date.split('-');
    return `${dateArray?.[1]}/${dateArray?.[2]}/${dateArray?.[0]} ${time}:00`;
  }
  return '';
};

/**
 * returns the date in mm/dd/yy
 * @param {string} date (date in string format)
 */
export const getChangedDateFormat = (date: string | number | boolean | null) => {
  if (typeof date === 'string') {
    const dateArray = date.split('-');
    return `${dateArray?.[1]}/${dateArray?.[2]}/${dateArray?.[0].substring(2)}`;
  }
  return '';
};

export const isPartnerSite = (): boolean => {
  return typeof window !== 'undefined' ? PARTNER_SITES.includes(window?.location?.pathname) : false;
};

/**
 * format the date in month dd, yyyy (July 17, 2022)
 * LL option is advance dayjs date format
 */
export const formatDate = (date: string) => {
  const options = { format: 'LL' } as const;
  const currentDate = getDayJsDate(new Date(date));
  const formattedDate = currentDate.format(options.format);
  return formattedDate;
};

/**
 * Update the yyyy-mm-dd formate into Month 00, yyyy
 */
export const updateDateFormat = (statement: string, date: RegExp) => {
  const informalDate = statement?.match(date) ?? '';
  const formalDate = formatDate(informalDate[0]);
  const updateMessage = statement?.replace(date, formalDate);
  return updateMessage;
};

/**
 * returns the total room count from items array either from product or basicinformation
 * @param {object} items (items array with room details)
 */
export const getRoomCount = (
  items: Array<{
    product: { totalPricing: { quantity: number } };
    basicInformation: { product: { totalPricing: { quantity: number } } };
  }>
): number => {
  let roomCount = 0;
  if (items) {
    items.forEach(item => {
      if (item?.product?.totalPricing?.quantity) {
        roomCount += item?.product?.totalPricing?.quantity || 0;
      } else if (item?.basicInformation?.product?.totalPricing?.quantity) {
        roomCount += item?.basicInformation?.product?.totalPricing?.quantity || 0;
      }
    });
  }
  return roomCount;
};

/**
 * returns the price with decimal value
 * @param {number} value (price value without decimal)
 * @param {number} decimalPoint (decimal point to be considered to convert input value)
 */
export const convertPriceFormat = (value: number, decimalPoint: number): number => {
  return Number(value / Math.pow(10, decimalPoint));
};

/**
 * returns the url with query params
 * @param {Record<string,string>} queryParams (query param object with keys and its values)
 */
export const createURLWithQueryParams = (link: string, queryParams: Record<string, string>) => {
  const ret = [];
  for (const key in queryParams) {
    ret.push(encodeURIComponent(key) + '=' + encodeURIComponent(queryParams[key]));
  }
  return link + '?' + ret.join('&');
};

// loading the mi-vendor initially onto the page.
export const loadMIVendorForModal = (overlayParsedOnce: { value: boolean }) => {
  if (
    !overlayParsedOnce.value &&
    typeof window !== 'undefined' &&
    canUseDOM &&
    !window?.MI_S2_ARIES_JS_LIST?.length &&
    document.querySelectorAll('script[data-vendor="true"]')?.length === 0
  ) {
    const script = document.createElement('script');
    let vendorJs = MI_VENDOR_JS;
    if (process.env['NODE_ENV'] === ENV_DEV) {
      vendorJs = vendorJs.replace('/aries', `${PLAY_SERVICE_PATH}/aries`);
    }
    script.src = vendorJs;
    script.setAttribute('data-vendor', 'true');
    document.body.appendChild(script);
    const scriptJs = document.createElement('script');
    let foundationJs = MI_FOUNDATION_JS;
    if (process.env['NODE_ENV'] === ENV_DEV) {
      foundationJs = foundationJs.replace('/aries', `${PLAY_SERVICE_PATH}/aries`);
    }
    scriptJs.src = foundationJs;
    scriptJs.setAttribute('data-vendor', 'true');
    document.body.appendChild(scriptJs);
  }
};

type ParseContactDetailsProps = {
  city: string | undefined;
  stateProvince: string | undefined;
  country: string | undefined;
  postalCode: string | undefined;
  addressLine1: string | undefined;
  addressLine2: string | undefined;
  addressLine3: string | undefined;
  isJPLocale: boolean | undefined;
  inModal?: boolean;
  overridePropertyCountry?: boolean;
  overridePropertyCountryText?: string;
  locale?: string;
};
export const parseContactDetails = ({
  city = '',
  stateProvince = '',
  country,
  postalCode = '',
  addressLine1 = '',
  addressLine2 = '',
  addressLine3 = '',
  isJPLocale,
  inModal,
  overridePropertyCountry,
  overridePropertyCountryText,
  locale,
}: ParseContactDetailsProps) => {
  if (overridePropertyCountry && overridePropertyCountryText === country) {
    country = '';
  }
  const separator = ' ';
  const citySeparator = stateProvince || country ? ',' : '';
  const parsedAddress =
    inModal || isCNLocale(locale)
      ? `${addressLine1}, ${addressLine2} ${addressLine3} ${city}, ${stateProvince}${separator}${country}, ${postalCode}`
      : `${addressLine1}, ${addressLine2} ${addressLine3} ${
          city + citySeparator
        } ${stateProvince}${separator}${postalCode} ${country}`;
  return !isJPLocale
    ? parsedAddress
    : `${postalCode}${city}${stateProvince}${addressLine1}${addressLine2}${addressLine3}${country ?? ''}`;
};

export const totalForStay = (
  cashTotal: Record<string, number>,
  _pointTotal: number,
  cashRate: number,
  pointRate: number,
  roomCount: number
): Record<string, number> => {
  return {
    totalCash: cashTotal
      ? createDecimalValue(cashTotal['value'], cashTotal['valueDecimalPoint'])
      : cashRate * roomCount,
    totalPoint: pointRate * roomCount,
  };
};

/**
 *
 * @param prefList
 * @param pref
 * @param prefKey
 * @param prefMsg
 * This is used to create the preference object
 */
export const createPrefObject = (
  prefList: Record<string, boolean | string>,
  pref: { description: string },
  prefKey: string,
  prefMsg: string
) => {
  prefList[prefKey] = true;
  prefList[prefMsg] = (prefList[prefMsg] ? prefList[prefMsg] + ', ' : '') + pref.description;
};

/**
 *
 * @param roomAttributes
 * @returns  codeExists, ersData
 */
export const ersCodeExists = (roomAttributes: any[]) => {
  let codeExists = false;
  let ersData: { description: string; category: { code: string } } = { description: '', category: { code: '' } };
  const ersDataList: { description: string; category: { code: string } }[] = [];
  roomAttributes?.length &&
    roomAttributes[0]?.attributes?.forEach((data: { category: { code: string }; description: string }) => {
      if (PREFERENCES_ERS_CODE.indexOf(data?.category?.code) >= 0) {
        codeExists = true;
        ersData = data;
        ersDataList.push(data);
      }
    });
  return { codeExists, ersData, ersDataList };
};

/**
 * @param item
 * @returns isErsProduct
 */
export const isErsProduct = (item: { basicInformation: { oldRates: boolean } }) => {
  return item.basicInformation.oldRates === false;
};

/**
 *
 * @param items
 * @returns roomCount after iterating through each room list
 */
export const getNumberOfRooms = (items: []) => {
  let roomCount = 0;
  if (items) {
    items.forEach((item: { totalPricing: { quantity: number } }) => {
      if (item?.totalPricing?.quantity) {
        roomCount += item.totalPricing.quantity;
      }
    });
  }

  return Number(roomCount);
};

/**
 *
 * @param renditions
 * @returns image size for all view port from rendition object
 */
export const createImageSizes = (
  renditions:
    | Array<{
        renditionPath: string;
        damPath: string;
        mediaValue: string;
        mediaQuery: string;
        width: number;
        height: number;
        dynamic: boolean;
      }>
    | undefined
) => {
  const imageSize: { desktop: string; tablet: string; mobile: string } = {
    desktop: '0:0',
    tablet: '0:0',
    mobile: '0:0',
  };
  renditions?.forEach(rendition => {
    if (rendition.mediaValue === VIEWPORT_SIZE.desktop) {
      imageSize.desktop = `${rendition.width}:${rendition.height}`;
    }
    if (rendition.mediaValue === VIEWPORT_SIZE.tablet) {
      imageSize.tablet = `${rendition.width}:${rendition.height}`;
    }
    if (rendition.mediaValue === VIEWPORT_SIZE.mobile) {
      imageSize.mobile = `${rendition.width}:${rendition.height}`;
    }
  });
  return imageSize;
};

/**
 * returns the boolean true/false.
 */

export const isMobileViewPort = () => {
  if (canUseDOM) {
    return window.innerWidth < 700;
  }
  return false;
};

/**
 * returns the cookies list from browser in the Object set of key values.
 */
export const getCookies = () => {
  let cookies = {};
  if (canUseDOM) {
    cookies = document.cookie
      .split(';')
      .reduce((ac, cv, _i) => Object.assign(ac, { [cv.split('=')[0]]: cv.split('=')[1] }), {});
  }
  return cookies;
};

export function getSessionDataScript(sessionData: Record<string, string>) {
  return `var sessionData = ${sessionData ? JSON.stringify(sessionData) : '{}'};`;
}
export function getCommonPageContentScript(commonPageContent: any) {
  return `var commonPageContent = ${commonPageContent ? JSON.stringify(commonPageContent) : '{}'};`;
}

export function getGraphQlLangScript(graphqlLanguage: any) {
  return `var graphqlLanguage =  ${graphqlLanguage ? JSON.stringify({ graphqlLanguage }) : '{}'};`;
}

export function getWindowSession(): any {
  // if (canUseDOM) {
  //   return window['sessionData' as any] ?? {};
  // }
  if (canUseDOM) {
    return window['sessionDataClient' as any] ?? window['sessionData'] ?? {};
  }
  return {};
}

/**
 * returns the session Object set, as per provided NEXT_PUBLIC_SESSION_KEYS.
 */
export async function getSessionData(
  preHeader: Record<string, string>,
  sessionBody: Record<string, string | undefined>
) {
  let sessionData = {};
  let sessionHeader: any = {};
  const { log, pLog } = global.loggerInstance('SessionCall');
  log.debug(`API url: ${SESSION_CALL_URL}`);
  const apiStartTime = new Date().getTime();
  try {
    if (NEXT_PUBLIC_IS_SESSION_CALL_ON === 'true') {
      const response = await axiosWrapper.post(`${SESSION_CALL_URL}`, sessionBody, {
        headers: preHeader,
      });
      sessionHeader = response.headers;
      sessionData = response.data;
      log.debug(`API call success with status code: ${response.status}`);
    }
  } catch (e) {
    log.error(`API url: ${SESSION_CALL_URL} | API call failed: ${e}`);
    throw new Error('Session call failed');
  }
  pLog.log('API call performance timing', apiStartTime, new Date().getTime());
  return { sessionData, sessionHeader };
}

/**
 * returns the user is authenticated or not
 *
 */
export function userAuthenticated() {
  const authenticated = JSON.parse(sessionStorage.getItem('authenticated') || 'false');
  return authenticated;
}

export const getRequestUUID = () => uuidV4();
export const getRequestIdExpiry = () =>
  new Date().getTime() + (process.env['REQUEST_ID_EXPIRY'] ? Number(process.env['REQUEST_ID_EXPIRY']) : 20) * 1000;

export function getDataLayerScript(dataLayer: { data: any; mvpOffersData: string }, isAcdl: boolean) {
  const dataLayeName = isAcdl ? 'adobeDataLayer' : 'dataLayer';
  return `var ${dataLayeName} = ${
    isAcdl
      ? []
      : JSON.stringify({
          ...(dataLayer?.data[0] ?? {}),
        })
  }; var mvpOffers = ${
    dataLayer?.mvpOffersData && dataLayer?.mvpOffersData !== 'null' ? dataLayer?.mvpOffersData : '{}'
  };
  function setSearchPosition() {
    var searchResult = (typeof window !== 'undefined' && sessionStorage?.getItem('search_result_position_selected')) || '';
    if (searchResult) {
      ${dataLayeName} = {...${dataLayeName}, search_result_position_selected: searchResult};
    }
  }
  setSearchPosition();`;
}

export function getRequestIdScript() {
  return `
  const cookiesObj = {};
  const cookies = document.cookie;
  decodeURIComponent(cookies)
  .split(';')
  .forEach(item => {
    const keyValuePair = item.split('=');
    if (keyValuePair.length >= 2) {
      cookiesObj[keyValuePair[0].trim().toLowerCase()] = keyValuePair[1];
    }
  });
  if (Object.keys(cookiesObj).includes('${PHX_TARGET_COOKIE_NAME}')) {
    const newDivTag = document.createElement('div');
    newDivTag.id = cookiesObj['${PHX_TARGET_COOKIE_NAME}'] ? cookiesObj['${PHX_TARGET_COOKIE_NAME}'] : '${OLD_RECIPE}'
    document.onreadystatechange = () => {
      if (document.readyState === '${COMPLETE}') {
        document.body.append(newDivTag);
      }
    };
  }
  if(!cookiesObj['x_page_trace_id']){
    const pathname = location.pathname;
    if(window && window.cookieStore){
      cookieStore.set({name: 'x_page_trace_id', value: pathname+"~X~${getRequestUUID()}", expires: ${getRequestIdExpiry()}})
    }
  }
  `;
}

export interface trackingPropertiesProps {
  trackingContentPosition?: string;
  trackingDescription?: string;
  trackingOfferType?: string;
  trackingTag?: string;
  payloadType?: string;
  impressionEventType?: string;
  impressionTracking?: string;
  clickEventType?: string;
  impressionTrack?: boolean;
}

export interface trackingDetailsProps {
  text: 'trackingContentPosition' | 'trackingDescription' | 'trackingOfferType' | 'trackingTag';
  pre: string;
}

const trackingDetailArr: Array<trackingDetailsProps> = [
  { text: 'trackingContentPosition', pre: '' },
  { text: 'trackingOfferType', pre: 'type=' },
  { text: 'trackingDescription', pre: 'msg=' },
  { text: 'trackingTag', pre: 'tag=' },
];

/**
 * @param trackingProperties
 * @param seperator
 * will return tracking string that will be used in tracking
 */
const getTrackingProperties = (trackingProperties: trackingPropertiesProps, seperator: string) => {
  const trackingArr: Array<string> = [];
  trackingDetailArr.forEach(item => {
    if (item.text) {
      trackingArr.push(`${item.pre}${trackingProperties?.[item.text]}`);
    }
  });
  return {
    trackingString: trackingArr.join(seperator),
    payloadType: trackingProperties?.payloadType || 'list3',
  };
};

export const trackImpression = (trackingProperties: trackingPropertiesProps, text: string, preTrackVal?: string) => {
  const { log } = logger({ requestID: '', sessionID: '' })('Track Impression');
  const tracking = getTrackingProperties(trackingProperties || {}, ',');
  const preTrackValue = preTrackVal ? preTrackVal + '|' : '';
  if (trackingProperties?.impressionTrack && !window.impressionArr?.includes(`${tracking.trackingString}${text}`)) {
    const ImpressionEventType = trackingProperties?.['impressionEventType'] || 'event168';
    if (window?.impressionTrack) {
      window.impressionTrack(
        `${preTrackValue}${ImpressionEventType}|${tracking?.payloadType}=${tracking.trackingString}`
      );
      log.debug(
        `Impression Track:
        ${preTrackValue}${ImpressionEventType}|${tracking?.payloadType}=${tracking.trackingString}`
      );
      if (typeof window.impressionArr === 'object') {
        window.impressionArr?.push(`${tracking.trackingString}${text}`);
      } else {
        window.impressionArr = [`${tracking.trackingString}${text}`];
      }
    }
  }
};

export function getHours() {
  const hours = [];
  for (let i = 0; i < 24; i++) {
    if (i < 10) {
      hours.push({ label: `0${i}:00`, value: `0${i}:00:00` });
    } else {
      hours.push({ label: `${i}:00`, value: `${i}:00:00` });
    }
  }
  return hours;
}

export const isDev = process.env['NODE_ENV'] === ENV_DEV;

export function getMonths() {
  const monthNames = getDayJsMonths();
  return monthNames.map((month, index) => {
    return { label: month, value: String(index + 1) };
  });
}

export function getYears() {
  const currentYear = new Date().getFullYear();
  const yearArr = [];
  let n = 0;
  while (n <= 10) {
    yearArr.push({ label: currentYear + n, value: currentYear + n });
    n++;
  }
  return yearArr;
}

/**
 * Searches for a small string in a large string that is separated by commas.
 * @param largeStringWithComma - The large string to search in, separated by commas.
 * @param smallString - The small string to search for.
 * @returns True if the small string is found in the large string, false otherwise.
 */
export const searchInStringWithComma = (largeStringWithComma: string | undefined, smallString: string) => {
  if (!largeStringWithComma) {
    return false;
  }
  const stringArray = largeStringWithComma.split(',');
  const searchStr = smallString?.toUpperCase();
  for (const str of stringArray) {
    if (str?.toUpperCase()?.includes(searchStr)) {
      return true;
    }
  }
  return false;
};

export const addSpaceAfterDigits = (e: any, numberOfDigits: number) => {
  const { value } = e.target;
  let tempValue = value;
  if (tempValue.length > 0) {
    tempValue = tempValue.replace(/\s/g, '');
    if (tempValue.length % numberOfDigits === 0) {
      e.target.value += '  ';
    }
  }
};

export type sortInOrderType = (a: any[], b: string[]) => void;

export const sortInOrder: sortInOrderType = (arr, order) => {
  arr.sort(function (a, b) {
    return order.indexOf(a.value) - order.indexOf(b.value);
  });
};

// Example usage
// const queryString = 'confirmationNumber=80883461,80883462&tripId=80883461&propertyId=berom';
// const modifiedQueryString = alterConfirmationNumber(queryString);
// console.log(modifiedQueryString); 'confirmationNumber=80883461&tripId=80883461&propertyId=berom'

function alterConfirmationNumber(queryString: string) {
  // Split the query string into an array of key-value pairs
  const params = queryString.split('&');

  // Loop through the parameters to find and modify the confirmationNumber parameter
  for (let i = 0; i < params.length; i++) {
    const [key, value] = params[i].split('=');

    if (key === 'confirmationNumbers') {
      // Split the value into an array of confirmation numbers
      const confirmationNumbers = value.split(',');

      // Take only the first confirmation number
      const modifiedValue = confirmationNumbers[0];

      // Replace the original value with the modified value
      params[i] = `${key}=${modifiedValue}`;
    }
  }

  // Join the modified parameters back into a query string
  const modifiedQueryString = params.join('&');

  return modifiedQueryString;
}

// Fetch PreProcessor URL based on page
export const getPreProcessorUrl = (url: string, queryParams = '') => {
  const pageNameArr = url.split('/');
  const pageName = pageNameArr[pageNameArr.length - 1];
  const preProcessorUrlMap = {
    [CONFIRMATION_URL]: NEXT_PUBLIC_PRE_PROCESSING_RES_CONF_URL,
    [RRD_URL]: NEXT_PUBLIC_PRE_PROCESSING_RRD_URL,
    [GUEST_INFO_URL]: NEXT_PUBLIC_PRE_PROCESSING_GI_URL,
    [IFRAME_CARD_URL]: NEXT_PUBLIC_PRE_PROCESSING_RRD_URL,
    [RRD_GI_URL]: NEXT_PUBLIC_PRE_PROCESSING_RRD_GI_URL,
    [RATE_LIST_MENU_URL]: NEXT_PUBLIC_PRE_PROCESSING_RLM_URL,
    [UPCOMING_RES_URL]: NEXT_PUBLIC_PRE_PROCESSING_UPCOMINGRES_URL + `?${queryParams}`,
    [UPCOMING_RES_IFRAME]: NEXT_PUBLIC_PRE_PROCESSING_UPCOMINGRES_URL + `?${queryParams}`,
    [UPCOMING_RES_CANCELLATION]: NEXT_PUBLIC_PRE_PROCESSING_CANCELRES_URL + `?${alterConfirmationNumber(queryParams)}`,
    [MODIFY_RLM]: NEXT_PUBLIC_MODIFY_RLM_URL,
    [UPGRADE_RES_URL]: NEXT_PUBLIC_PRE_PROCESSING_UPGRADERES_URL,
    [EXPIRED_RES_URL]: NEXT_PUBLIC_PRE_PROCESSING_EXPIREDRES_URL,
    [REDIRECT_PAGE_URL]: NEXT_PUBLIC_PRE_PROCESSING_800PAGE_URL,
    [AVAILABILITY]: NEXT_PUBLIC_PRE_PROCESSING_AVAILABILITY_URL + `?${queryParams}`,
    [LOOKUP_RESERVATION]: NEXT_PUBLIC_LOOKUP_RESERVATION_URL,
    [RES_LOOKUP_OVERLAY]: NEXT_PUBLIC_LOOKUP_RESERVATION_URL,
  };

  let preProcessorUrl = preProcessorUrlMap[pageName] || NEXT_PUBLIC_PRE_PROCESSING_RRD_URL; //Default url will be NEXT_PUBLIC_PRE_PROCESSING_RRD_URL

  // PreProcessor for running page on localhost with mock API
  if (isDev) {
    preProcessorUrl = NEXT_PUBLIC_PRE_PROCESSOR_LOCAL;
  }
  return preProcessorUrl;
};

/**
 * convert the current local time of user to local time of property
 * @param {Date} date (current local date of user)
 * @param {number} offset (gmtoffset in numeric format)
 */
export const convertTimeZone = (date: Date, offset: number) => {
  const currentDate = date;
  const utc = currentDate.getTime() + currentDate.getTimezoneOffset() * 60000;
  const newDate = new Date(utc + 3600000 * offset);
  return newDate;
};

/**
 * convert the gmtoffset to numeric format for calculation
 * @param {string} gmtOffset (gmtoffset in hours and minute format)
 */
export const convertGMTOffset = (gmtOffset: string) => {
  const gmtOffsetArray = gmtOffset?.split(':');
  const gmtoffsetHour = gmtOffsetArray?.[0];
  const gmtOffsetMinute = gmtOffsetArray?.[1];
  const offsetMinutes = Number(gmtoffsetHour) > 0 ? Number(gmtOffsetMinute) : -gmtOffsetMinute;

  return Number(gmtoffsetHour) + offsetMinutes / 60;
};

export const addCrossOriginScript = (src: string, id: string) => {
  const newScript = document.createElement('script');
  newScript.setAttribute('src', src);
  newScript.setAttribute('id', id);
  newScript.async = true;
  newScript.setAttribute('crossOrigin', 'anonymous');
  document.head.appendChild(newScript);
};

// User is redirected to specific url page on clicking browser back button
export const pageRedirect = (url: any) => {
  const currentPageUrl = window.location.href;
  window.history.pushState(null, '', currentPageUrl);
  window.addEventListener('popstate', e => {
    e.preventDefault();
    // If url has # appended due to modal then no redirect has to be done.
    if (window.location.href !== currentPageUrl + '#') {
      window.location.assign(addSubDirectoryPrefix(url));
    }
  });
};

// Extracts URL parameters from the current window's URL or a provided URL string.
export function getCurrentPageUrlParams(url = '') {
  if (canUseDOM) {
    const searchParams = new URLSearchParams(url || window.location.search) as unknown as Iterable<
      readonly [PropertyKey, any]
    >;
    return Object.fromEntries(searchParams);
  }
  return {};
}

/**
 * Retrieves the user's country code from Akamai's browser location data if available.
 * Returns the default country code if the data is not available or the DOM is not accessible.
 *
 * @returns {string} - The user's country code (in uppercase) or the default country code.
 */
export function getUserCountryCodeFromAkamai(): string {
  let countryCode = constants.DEFAULT_COUNTRY;
  if (canUseDOM) {
    countryCode =
      (window?.dataLayer?.['browser_akamai_loc_country'] as unknown as string) || constants.DEFAULT_COUNTRY?.toString();
  }
  return countryCode?.toUpperCase();
}

export const scrollIntoView = (elementId: string) => {
  canUseDOM && document.getElementById(elementId)?.scrollIntoView();
};

export const getFormFirstErrorElement = (formErrorElementRef: { [x: string]: { ref: HTMLElement | null } }) => {
  // If formErrors exists and has properties
  const firstErrorKey = Object.keys(formErrorElementRef || {})?.[0];
  if (firstErrorKey) {
    // Return the `ref` of the first errors
    return formErrorElementRef?.[firstErrorKey]?.ref || null;
  }
  return null; // If no errors, return null
};

// scroll to exact element of the DOM with content being in center
export const scrollIntoViewAdjusted = (element: HTMLElement, noTimeout = false) => {
  if (canUseDOM && element) {
    const isAuth = getWindowSession()?.authenticated;
    if (isAuth && !noTimeout) setTimeout(() => element?.scrollIntoView({ behavior: 'smooth', block: 'center' }), 700);
    else element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};

export const getTrackingProps = (trackingProperties?: {
  trackingContentPosition?: string;
  trackingOfferType?: string;
  atCCeVar48?: string;
  trackingDescription?: string;
  trackingTag?: string;
  isCoBrand?: boolean;
  clickTrack?: boolean;
  impressionTrack?: boolean;
  impressionCount?: boolean;
  merchandisingCategory?: string;
  additionalTrackingVariables?: string;
}) => {
  let trackingVar = `|${trackingProperties?.additionalTrackingVariables}`;
  if (trackingProperties?.trackingOfferType) {
    trackingVar += `,type=${trackingProperties?.trackingOfferType},msg=${trackingProperties?.trackingOfferType}`;
  }
  if (trackingProperties?.trackingTag) {
    trackingVar += `,tag=${trackingProperties?.trackingTag}`;
  }
  return trackingVar;
};

export const getClickTrackValue = (
  linkType: string,
  trackingProperties?: {
    trackingContentPosition?: string;
    trackingOfferType?: string;
    atCCeVar48?: string;
    trackingDescription?: string;
    trackingTag?: string;
    isCoBrand?: boolean;
    clickTrack?: boolean;
    impressionTrack?: boolean;
    impressionCount?: boolean;
    merchandisingCategory?: string;
    additionalTrackingVariables?: string;
  }
) => {
  let trackPosAndDesc = '';
  if (trackingProperties?.trackingContentPosition) {
    trackPosAndDesc += `${trackingProperties?.trackingContentPosition}|`;
  }
  if (trackingProperties?.trackingDescription) {
    trackPosAndDesc += `${trackingProperties?.trackingDescription}|`;
  }
  return `${trackPosAndDesc}${linkType}${
    trackingProperties?.additionalTrackingVariables ? getTrackingProps(trackingProperties) : ''
  }`;
};

export const setInSession = (key: string, value: boolean | string | Record<string, string | number | boolean>) => {
  if (canUseDOM) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
};

export const appendNode = (parent: HTMLInputElement, child: HTMLInputElement) => {
  try {
    child && parent?.appendChild(child);
  } catch (e: any) {
    console.log('Exception Occured in appendChild', e?.message);
  }
};
export const removeNode = (parent: HTMLInputElement, child: HTMLInputElement) => {
  try {
    child && parent?.removeChild(child);
  } catch (e: any) {
    console.log('Exception Occured in removeChild', e?.message);
  }
};

/**
 *
 * @param url
 * This is used for navigation from current to another page. This will check for subdirectory prefix
 * if present then it will be prefixed and redirected otherwise the redirected to url.
 */
export const redirect = (url: string) => {
  if (url) {
    url = addSubDirectoryPrefix(url);
    if (isDev) url = url.replace('.mi', '');
    window.location.assign(url);
  }
};

/**
 *
 * @param url
 * This is used for navigation from an iframe to another page. This will check for subdirectory prefix
 * if present then it will be prefixed and redirected otherwise the redirected to url.
 */
export const redirectFromIframe = (url: string) => {
  if (url && window?.top) {
    url = addSubDirectoryPrefix(url);
    if (isDev) url = url.replace('.mi', '');
    window.top.location.assign(url);
  }
};

/**
 *
 * @param url
 * This is used for navigation from current to another page
 */
export const getAcertifyId = ({ propId, checkInDate }: any) => {
  const formattedCheckInData = checkInDate?.includes('.') ? checkInDate?.split('.')[0] : checkInDate;
  const newDate = new Date().toISOString();
  const formattedDate = newDate?.includes('.') ? newDate?.split('.')[0] : newDate;
  const acertifyId = `${formattedDate}Z_${propId}_${formattedCheckInData?.split('T')[0].split('-').join('')}-00`;
  return acertifyId;
};

export const refetchSession = () => {
  // Signin popup clear button
  const clearButton = document.getElementsByClassName('m-rewards-clear-remembered-user')[0];

  if (clearButton) {
    // update session storage on clear button click
    clearButton.addEventListener('click', () => {
      sessionStorage.setItem('refetchSession', JSON.stringify(true));
    });
  }
  //Sign in button Aries Modal
  const signInButton = document.getElementsByClassName('t-signin-btn')[0];
  if (signInButton) {
    // update session storage on clear button click
    signInButton.addEventListener('click', () => {
      sessionStorage.setItem('refetchSession', JSON.stringify(true));
    });
  }
};

const { NEXT_PUBLIC_AKAMAI_ENABLER } = getProcessEnvs();
interface CookieObjProps {
  keyName: string;
  value: any;
  maxAge?: number;
}
export const setResCookies = (cookieObjArray: CookieObjProps[], res: ServerResponse) => {
  const cookieArray = cookieObjArray.map(_i => `${_i.keyName}=${JSON.stringify(_i.value)} ; Max-Age=${_i?.maxAge}`);
  res.setHeader('Set-Cookie', cookieArray);
};

type requestType = {
  cookies?: {
    sessionID: string;
    UserIdToken: string;
    xPreProcessor?: string;
    BVSAuthCookie?: string;
    mippIDCookie?: string;
  };
};

export const isServerSession = (pageUrl: string, req: requestType) => {
  if (NEXT_PUBLIC_AKAMAI_ENABLER !== 'true') {
    return true;
  }
  // No session call on server side for local development.
  if (isDev) {
    return false;
  }
  console.log(pageUrl);

  //Session Call will be done on server-side for MODIFY FLOW pages.
  if (
    pageUrl.includes(UPCOMING_RES_URL) ||
    pageUrl.includes(UPCOMING_RES_IFRAME) ||
    pageUrl.includes(MODIFY_RLM) ||
    pageUrl.includes(UPGRADE_RES_URL) ||
    pageUrl.includes(UPCOMING_RES_CANCELLATION)
  ) {
    return true;
  }
  return !req.cookies?.xPreProcessor;
};

export function setCookie(c_name: string, value: any, minutes?: number) {
  let c_value = value;

  if (minutes) {
    const expireTime = new Date();
    expireTime.setTime(expireTime.getTime() + minutes * 60 * 1000);

    c_value = value + '; expires=' + expireTime.toUTCString() + ';';
  }

  document.cookie = c_name + '=' + c_value;
}

export const getPreProcessorFromCookie = (req: requestType) => {
  const encodedData = req.cookies?.xPreProcessor || '';
  const decodedData = Buffer.from(encodedData, 'base64').toString();
  let parsedResponse = {};
  try {
    parsedResponse = encodedData && JSON.parse(decodedData);
  } catch (e) {
    console.log('Parsing failed for preProcessor decoded data');
  }
  const preProcessorData: { experienceSegments?: { brandCode: string }; locale?: string; languageTag?: string } =
    parsedResponse;
  return {
    brandCode: preProcessorData?.experienceSegments?.brandCode,
    locale: preProcessorData?.locale,
    languageTag: preProcessorData?.languageTag,
  };
};

export const isServerPreprocessor = (brandCode?: string, locale?: string) => {
  if (NEXT_PUBLIC_AKAMAI_ENABLER !== 'true') {
    return true;
  }
  return !(brandCode && locale);
};

export const isValidConfirmationSession = (sessionData: any) => {
  return sessionData?.cacheData?.data?.AriesReservation?.tripId;
};
export async function getSessionDataClient(isExpFrag?: boolean) {
  const { NEXT_PUBLIC_SESSION_KEYS, NEXT_PUBLIC_MI_SESSION_URL, NEXT_PUBLIC_EXP_FRAG_SESSION_URL, NEXT_PUBLIC_PREFIX } =
    getProcessEnvs();
  const sessionURL = NEXT_PUBLIC_PREFIX + (isExpFrag ? NEXT_PUBLIC_EXP_FRAG_SESSION_URL : NEXT_PUBLIC_MI_SESSION_URL);

  let sessionData;

  try {
    const response = await axiosWrapper.post(sessionURL, { keys: NEXT_PUBLIC_SESSION_KEYS });
    sessionData = response.data;
  } catch (e) {
    console.log('Session Call failed with ', e);
    sessionData = null;
  }
  return { sessionData: sessionData };
}

declare global {
  interface Window {
    sessionData: any;
  }
}
export function addTargetToIFrame() {
  const { IFRAME } = constants;
  const elemId = document.getElementsByClassName('target-container')[0];
  let intervalId;
  if (elemId) {
    const observer = new MutationObserver(mutationList => {
      for (let j = 0; j < mutationList.length; j++) {
        for (let i = 0; i < mutationList[j].addedNodes.length; i++) {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const ele = <any>mutationList[j].addedNodes[i];
          if (ele.nodeName === IFRAME) {
            ele.className = 'frag-iframe';
            ele.width = ele.closest('div').clientWidth;
            ele.contentWindow.sessionData = window?.sessionData;
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const dynamicIFrame = <any>document.getElementById('dynamic-iframe');
            intervalId = setTimeout(() => {
              const iFrameEle = dynamicIFrame.contentWindow; //id will be dynamic
              const heightToSet = iFrameEle.document.getElementsByClassName('target-component')[0].clientHeight; //common className must be used
              iFrameEle.document.body.setAttribute('style', 'overflow:hidden;background:inherit;');
              ele.height = heightToSet + 'px';
            }, 8000);
            addTargetEleToWindow(dynamicIFrame);
          }
        }
      }
    });
    observer.observe(elemId, {
      childList: true, // observe direct children
      subtree: true, // lower descendants too
      characterDataOldValue: true, // pass old data to callback
    });
  }
  return intervalId;
}

export function addTargetEleToWindow(dynamicIFrame: any) {
  const trackingEleArr = dynamicIFrame.contentWindow.document.querySelectorAll('.custom_click_track');
  trackingEleArr.forEach((elem: HTMLElement) => {
    const cloneEle = elem.cloneNode(true) as any;
    cloneEle.style.display = 'none';
    dynamicIFrame.after(cloneEle);
    elem.addEventListener('click', function (e: any) {
      e.preventDefault();
      document?.getElementById(e?.target?.id)?.click();
    });
  });
}

// will update the elements inside the iframe, which target drops on the page.
export function updateTarget(iframeId: string, eleSelector: string, keyAttr: string, valueAttr: string) {
  const taregtIFrame = document.getElementById(iframeId) as HTMLIFrameElement;
  const iFrameEle = taregtIFrame?.contentWindow;
  const selectedEle = iFrameEle?.document.getElementsByClassName(eleSelector)[0];
  selectedEle?.setAttribute(keyAttr, valueAttr);
}

// Remove keys from Session Storage
export const removeFromSession = (sessionItems: any) => {
  for (let i = 0; i < sessionItems.length; i++) {
    sessionStorage.removeItem(sessionItems[i]);
  }
};

export const getDateInMMDDYYYY = (date: string) => {
  const dateValue = new Date(date);
  return (
    (dateValue.getMonth() > 8 ? dateValue.getMonth() + 1 : '0' + (dateValue.getMonth() + 1)) +
    '/' +
    (dateValue.getDate() > 9 ? dateValue.getDate() : '0' + dateValue.getDate()) +
    '/' +
    dateValue.getFullYear()
  );
};

export const setSessionKey = (key: string, value: string) => {
  if (canUseDOM) {
    sessionStorage.setItem(key, value);
  }
};

export const getFromSession = (key: string) => {
  return canUseDOM && sessionStorage.getItem(key);
};

export const isNotStateApplicable = (countryCode: string) => {
  return NOT_ALLOWED_STATE_FIELD.includes(countryCode);
};

export const getDefaultCountry = (locale: string) => {
  return locale?.split('_')?.[1];
};

// getting rand param from encryption api
export const getRandParameter = async (
  fName: string,
  lName: string,
  isOta: boolean,
  obj: Record<string, string | unknown>,
  trip: string,
  englishFirstName?: string,
  englishLastName?: string
) => {
  const { startDate, endDate, countryCode } = obj;
  const processEnv = getProcessEnvs();
  let url = processEnv['NEXT_PUBLIC_ENCRYPTION_URL'] ?? '';
  if (process.env['NODE_ENV'] !== ENV_DEV) url = processEnv['NEXT_PUBLIC_PREFIX'] + url;

  const baseParams = `resId=${trip}&checkoutDate=${endDate}&enrollSrcCodeKey=${countryCode}_MI&firstName=${fName}&lastName=${lName}&checkinDate=${startDate}&otaReservation=${isOta}`;

  const value =
    englishFirstName || englishLastName
      ? `${baseParams}&englishFirstName=${englishFirstName}&englishLastName=${englishLastName}`
      : baseParams;

  let result;
  try {
    result = await axiosWrapper.post(url, {
      valueToEncrypt: value,
      client_id: CLIENT_ID,
    });
    return result?.data?.encryptedValue;
  } catch (err) {
    console.error('Error while fetching rand parameter', err);
  }
};

export const clearCouchBaseCache = async (consumerID: string) => {
  const processEnv = getProcessEnvs();
  const url = `${processEnv['NEXT_PUBLIC_PREFIX']}${CACHE_CLEAR_URL}`;
  let result = null;
  try {
    result = await axiosWrapper.post(url, {
      consumerID,
    });
    return result;
  } catch (err) {
    console.error('Error while clearing cache', err);
  }
  return result;
};

export async function axiosClient(
  method: string,
  url: string,
  payload?: Record<string, any>,
  configs?: AxiosRequestConfig,
  name = '',
  failureCallback?: (err?: any) => void
) {
  let response;
  try {
    if (url !== '' && url !== null) {
      switch (method.toUpperCase()) {
        case constants.POST:
          response = await axiosWrapper.post(url, payload, configs);
          break;
        default:
          response = await axiosWrapper.get(url);
          break;
      }
    }
  } catch (e) {
    console.error(`${name} API call failed: ${e}`);
    failureCallback?.(e);
  }
  return response;
}

// method to extract nested key value from the object
export function extractValue<T>(obj?: T | null, keyPath?: keyof T | string): any {
  // Validate inputs
  if (obj === null || obj === undefined || keyPath === null || keyPath === undefined) {
    return undefined;
  }

  // Convert keyPath to an array of keys
  const keys = typeof keyPath === 'string' ? keyPath?.split('.') : [keyPath as unknown as string];

  // Use reduce to traverse the object
  return keys?.reduce((acc: { [x: string]: any } | null, key: string) => {
    // Ensure acc is an object and has the key before accessing
    if (acc !== null && typeof acc === 'object' && key in acc) {
      return acc[key];
    }
    return undefined;
  }, obj);
}

//converts ":items" format to "cqItems" format.
export function transformToCQ(propKey: string) {
  const tempKey = propKey.substring(1);

  return 'cq' + tempKey.substring(0, 1).toUpperCase() + tempKey.substring(1);
}

//To format mock model in local dev, in other envs this formatting is taken care by OOTB fetchModel utility
export const respGridUtil = (item: { [x: string]: string }) => {
  if (!item || !Object.keys(item).length) {
    return { cqPath: '' };
  }

  const keys = Object.getOwnPropertyNames(item);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const props: any = {};

  keys.forEach((key: string) => {
    const propKey = key.startsWith(':') ? transformToCQ(key) : key;
    props[propKey] = item[key] || '';
  });

  return props;
};

/*
To convert phone number format for DE & ZH domain
*/
const convertPhoneToDEAndZH = (phoneNumber: string): string => {
  let parts;
  const index = phoneNumber.indexOf(' ');
  if (index === -1) {
    parts = [phoneNumber, ''];
  }
  parts = [phoneNumber.slice(0, index), phoneNumber.slice(index + 1)];

  return parts
    ? parts[0] +
        ' ' +
        parts[1]
          .replaceAll(' ', '')
          .replaceAll('-', '')
          .replace(/(\d{3})(\d{4})/, '$1 $2')
    : '';
};

/*
To format phone number format for DE domain , ZH Domain and US domain
*/ export const formatPhoneNumber = (phoneNumberString: string, lang: string): string => {
  let returnedNumber = '';
  switch (lang) {
    case 'de-DE':
    case 'zh-TW':
      returnedNumber = convertPhoneToDEAndZH(phoneNumberString);
      return returnedNumber;
    default:
      if (phoneNumberString?.includes('+')) {
        if (phoneNumberString.replaceAll(' ', '').replaceAll('-', '')?.split('')?.length > 12) {
          returnedNumber = phoneNumberString
            .replaceAll(' ', '')
            .replaceAll('-', '')
            .replace(/(\d{2})(\d{3})(\d{4})/, '$1 $2 $3');
        } else {
          returnedNumber = phoneNumberString
            .replaceAll(' ', '')
            .replaceAll('-', '')
            .replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1 $2-$3-$4');
        }
      }
      return returnedNumber;
  }
};

export const getCommaSeparator = (numb: number | string | undefined, lang: string): string => {
  const localeWithHyphen = lang?.replace('_', '-');
  const esLocale = hardCodedAllLocale.es;

  const inputStr = numb ?? '';
  // convert input number to locale string according to the lang
  // const str = Number(inputStr).toLocaleString(lang || '');
  let str = '';
  if (localeWithHyphen === esLocale) {
    str = Number(inputStr)?.toLocaleString(localeWithHyphen || '', { useGrouping: true });
  } else if (localeWithHyphen) {
    str = Number(inputStr)?.toLocaleString(localeWithHyphen);
  } else {
    str = Number(inputStr)?.toLocaleString();
  }
  return str;
};

/**
 * Function creates compact format for a given number based on the locale
 *
 * @param numb | string | undefined
 * @param locale | string
 * @param compactFormatLiteral | any
 * @returns string
 */
export function getCompactFormat(numb: number | string | undefined, locale: string, compactFormatLiteral: any): string {
  if (!numb) return '';
  if (Number(numb) < 1000) {
    return numb?.toString();
  }

  const numberLength = Math.trunc(Number(numb)).toString().length;
  const isMillion = !(numberLength > 3 && numberLength < 7);
  let result = '';
  const localeWithHyphen = locale?.replace('_', '-');
  switch (localeWithHyphen) {
    case 'ko-KR':
    case 'ja-JP':
      result = isMillion
        ? Number(Number(numb) / 1000000).toLocaleString(localeWithHyphen, { maximumFractionDigits: 1 }) +
          compactFormatLiteral?.millionLabel
        : Number(numb).toLocaleString(localeWithHyphen, { maximumFractionDigits: 1 });
      break;
    case 'en-US':
    case 'it-IT':
    case 'fr-FR':
      result =
        Number(Number(numb) / (isMillion ? 1000000 : 1000)).toLocaleString(localeWithHyphen, {
          maximumFractionDigits: 1,
        }) + (isMillion ? compactFormatLiteral?.millionLabel : compactFormatLiteral?.thousandLabel);
      break;
    case 'es-ES':
      result =
        Number(Number(numb) / (isMillion ? 1000000 : 1000)).toLocaleString(localeWithHyphen, {
          maximumFractionDigits: 1,
          useGrouping: true,
        }) + (isMillion ? compactFormatLiteral?.millionLabel : compactFormatLiteral?.thousandLabel);
      break;
    default:
      result = numb?.toString();
      break;
  }
  return result;
}

export function getCalculatedDistance(dist: number | undefined, km: boolean): string | null {
  /** calculated distance for km/ miles */
  if (dist) {
    if (km) {
      // convert meters to km
      return (dist / 1000).toFixed(1);
    } else {
      // convert meters to miles
      return (dist / 1609.344).toFixed(1);
    }
  }
  return null;
}

/**
 *
 * @param url
 * This is used for prefix the url. This will check for subdirectory prefix
 * if present then it will be prefixed otherwise the url is returned without prefix.
 */
export function addSubDirectoryPrefix(url: string) {
  const SUBDIRECTORY_PREFIX = getFromSession('SUBDIRECTORYCONTEXTPATH') || '';
  if (
    SUBDIRECTORY_PREFIX &&
    url?.startsWith('/') &&
    url?.substring(1, findNthOccurence(url, 1, '/'))?.toLowerCase() !== SUBDIRECTORY_PREFIX?.toLowerCase()
  ) {
    return SUBDIRECTORY_PREFIX ? '/' + SUBDIRECTORY_PREFIX + url : url;
  } else return url;
}

/**
 *
 * This function is used to return subdirectory value from session Storage.
 */
export function getSubDirectoryPrefix() {
  const SUBDIRECTORY_PREFIX = getFromSession('SUBDIRECTORYCONTEXTPATH') || '';
  return SUBDIRECTORY_PREFIX;
}

/**
 *
 * @param url as str
 * This is used find the nth occurence of a char in string
 */
const findNthOccurence = (str: string, nth: number, char: string) => {
  let index = 0;
  for (let i = 0; i < nth; i += 1) {
    if (index !== -1) index = str.indexOf(char, index + 1);
  }
  return index;
};

export const getTagpCorporateCode = (rateCode: string) => {
  if (FAMTASTIC_PRODUCTS.includes(rateCode)) {
    return FAMTASTIC_CODE;
  } else if (PLANTASTIC_PRODUCTS.includes(rateCode)) {
    return PLANTASTIC_CODE;
  } else if (TRAVEL_AGENT_PRODUCTS.includes(rateCode)) {
    return TRAVEL_AGENT_CODE;
  }
  return '';
};

// Function Name: objectToQueryString

// Description: This function takes an object as input and converts it into a URL query string.
// It iterates through the object's key-value pairs, encodes both the keys and values using encodeURIComponent, and joins them with ampersands ('&') to create a properly formatted query string.

// Parameters: obj (Type: Record<string, any): The input object that contains the key-value pairs you want to convert into a query string.
// The function can handle objects with string keys and values of any type.

// Return Value:
// Type: string
// The function returns a string representing the URL query parameters.

export const objectToQueryString = (obj: Record<string, any>): string => {
  let result = '';
  try {
    result = Object.keys(obj)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
      .join('&');
  } catch (e) {
    console.log('Error in objectToQueryString', e);
  }
  return result;
};

// http://www.ritzcarlton.com/?marshaCode=jktrz&locale=en_US
// Function to get The Ritz-Carlton details URL
export const getTRCDetailsURL = ({ marshaCode = '', locale = '' }) => {
  // Create an object with marshaCode and locale properties
  const queryParamsObject = {
    marshaCode: marshaCode,
    locale: locale,
  };

  // Convert the object to a query string
  const queryParams = objectToQueryString(queryParamsObject);
  // Concatenate the query string with the base URL
  const trcURL = THE_RITZ_CARLTON_URL + '?' + queryParams;
  // Return the constructed URL
  return trcURL;
};

// Rates and Reviews - http://www.ritzcarlton.com/en/hotels.hotel-reviews/jktrz (baseUrl/**language**/hotels.hotel-reviews/**marshacode**)
//   baseUrl - http://www.ritzcarlton.com
// language -  for locale "zh_CN" - "zh-cn", "ja_JP" - "jp"  and others locale.getLanguage()
// marshacode
export const getTRCReviewURL = ({ marshaCode = '', locale = '' }) => {
  let language = locale.substring(0, 2);
  if (locale === 'zh_CN') {
    language = 'zh-cn';
  }
  if (locale === 'ja_JP') {
    language = 'jp';
  }
  const trcReviewURL = THE_RITZ_CARLTON_URL + `/${language + TRC_HOTEL_REVIEW_PATH}/${marshaCode}`;
  return trcReviewURL;
};

export const getBulgariDetailsURL = ({ location = '', locale = '' }) => {
  return `${BULGARI_HOTEL_URL}/${locale}/${location}`;
};

export const getHotelDetailURL = ({
  brandCode = '',
  locale = '',
  marshaCode = '',
  isbulgariFlow = false,
  location = '',
}) => {
  const brandMap = {
    [THE_RITZ_CARLTON_CODE]: getTRCDetailsURL({ marshaCode, locale }),
    [BULGARI_BRAND_CODE]: getBulgariDetailsURL({ location, locale }),
  };
  let url = brandMap[brandCode.toUpperCase()];
  if (isbulgariFlow) {
    url = getBulgariDetailsURL({ location, locale });
  }
  return url;
};

export const getHotelReviewURL = ({ brandCode = '', locale = '', marshaCode = '' }) => {
  const brandMap = {
    [THE_RITZ_CARLTON_CODE]: getTRCReviewURL({ marshaCode, locale }),
  };
  const url = brandMap[brandCode.toUpperCase()];
  return url;
};

/**
 * Retrieves the selected currency, ensuring it is not the default currency.
 *
 * @param {string | undefined} selectedCurrency - The currency code that is selected.
 * @returns {string | null} The selected currency if not the default, otherwise null.
 */
export const getSelectedCurrency = (selectedCurrency?: string) => {
  if (selectedCurrency && selectedCurrency?.toLowerCase() !== constants.DEFAULT) {
    return selectedCurrency;
  }
  return null;
};

/**
 * Generates an object representing an exchange currency variable.
 *
 * @param {string} fromCurrency - The currency code to convert from.
 * @param {string} toCurrency - The currency code to convert to.
 * @param {number} [fromValue=1] - The value of the from currency (default is 1).
 * @returns {Object} An object with the structure for exchange currency variables.
 */
export const generateExCurrVariable = (fromCurrency: string, toCurrency: string, fromValue = 1) => {
  return {
    input: {
      from: {
        currency: fromCurrency,
        value: Math.round(fromValue * 100),
        valueDecimalPoint: 2,
      },
      toCurrency: toCurrency,
    },
  };
};
/**
 * Function to determine the screen size's
 */

export function getScreenCategory() {
  let screenCategory = '';
  switch (true) {
    case window.innerWidth < 240:
      screenCategory = 'XXS';
      break;
    case window.innerWidth >= 240 && window.innerWidth < 320:
      screenCategory = 'XS';
      break;
    case window.innerWidth >= 320 && window.innerWidth < 480:
      screenCategory = 'S';
      break;
    case window.innerWidth >= 480 && window.innerWidth < 560:
      screenCategory = 'SM';
      break;
    case window.innerWidth >= 560 && window.innerWidth < 672:
      screenCategory = 'M';
      break;
    case window.innerWidth >= 672 && window.innerWidth < 768:
      screenCategory = 'MML';
      break;
    case window.innerWidth >= 768 && window.innerWidth < 864:
      screenCategory = 'ML';
      break;
    case window.innerWidth >= 864 && window.innerWidth < 960:
      screenCategory = 'MLL';
      break;
    case window.innerWidth >= 960 && window.innerWidth < 1024:
      screenCategory = 'L';
      break;
    case window.innerWidth >= 1024 && window.innerWidth < 1200:
      screenCategory = 'XL';
      break;
    case window.innerWidth >= 1200 && window.innerWidth < 1440:
      screenCategory = 'XXL';
      break;
    case window.innerWidth >= 1440:
      screenCategory = 'XXXL';
      break;
    default:
      screenCategory = 'Unknown';
      break;
  }
  return screenCategory;
}

export function updateDataLayerObj(dataLayer: Record<string, unknown> | undefined) {
  try {
    if (dataLayer) {
      let deviceOrientation = DATA_LAYER_PROPERTIES?.NEXT_PUBLIC_ORIENTATION_LANDSCAPE;
      if (
        window.innerWidth < DATA_LAYER_PROPERTIES.DATALAYER_PORTRAIT_WIDTH_BREAKPOINT &&
        window.innerHeight > window.innerWidth
      ) {
        deviceOrientation = DATA_LAYER_PROPERTIES?.NEXT_PUBLIC_ORIENTATION_PORTRAIT;
      }
      const screenCategory = getScreenCategory();
      dataLayer['ensightenEnabled'] = true;
      dataLayer[
        'page_breakpt_orientation'
      ] = `${DATA_LAYER_PROPERTIES?.NEXT_PUBLIC_PAGE_BREAKPT}${screenCategory} - ${window.innerWidth}w: ${deviceOrientation}`;
    }
  } catch (e) {
    console.log('updateDataLayerObj', e);
  }
}

export const getPreProcessorRedirectURLs = () => {
  return {
    redirect: {
      permanent: false,
      destination: addSubDirectoryPrefix(ERROR_PAGE_URL),
      expireSession: addSubDirectoryPrefix(EXPIRED_SESSION_URL),
      rlm: addSubDirectoryPrefix(RLM_REDIRECT_URL),
      lookupReservation: addSubDirectoryPrefix(FIND_RESERVATION_LIST),
    },
  };
};

export const evaluatePreData = (preData: any, subDirectoryContextPath: string, pageUrl = '') => {
  const redirect = getPreProcessorRedirectURLs();
  const prefix = subDirectoryContextPath ? `/${subDirectoryContextPath}` : '';
  if (preData?.errorMessage === SESSION_VALIDATION_FAILED_OR_EXPIRED) {
    return {
      location: `${prefix}${redirect.redirect.expireSession}`,
      resCode: 302,
    };
  }
  if (preData?.errorMessage === CART_VALIDATION_FAILED_OR_EXPIRED) {
    return {
      location: `${prefix}${redirect.redirect.rlm}`,
      resCode: 302,
    };
  }
  if (preData?.notFound) {
    return {
      location: `${prefix}${redirect.redirect.destination}`,
      resCode: 307,
    };
  }
  if (preData?.errorMessage === LOOKUP_RESERVATION_FOR_AUTH_FLOW_302_TO_MYTRIPS) {
    return {
      location: `${prefix}${redirect.redirect.lookupReservation}`,
      resCode: 302,
    };
  }
  if (preData?.navigationURL && pageUrl?.includes(URL_CONSTANTS.AVAILABILITY)) {
    return {
      location: `${prefix}${preData?.navigationURL}`,
      resCode: 302,
    };
  }
  return { location: '' };
};

export const redirectTo = (res: ServerResponse, location: string, errorCode = 302) => {
  res.writeHead(errorCode, { Location: location });
  res.end();
  return res;
};

export const safeFailValue = (value: string | undefined) => {
  return value ?? '';
};
export const getSessionDataScriptSrc = ({ buildId }: { buildId: string }) => {
  return `${isDev ? '' : MI_ASSETS}/static/sessionData.js?v=${buildId}`;
};

/**
 * method to load Locale Currency on pageload
 */
export function loadLocaleCurrency(currencySymbol: string) {
  const sessionObject = getWindowSession();
  const localeCode = sessionObject?.locale?.split(/_|-/)?.[1];

  const rk_currency = sessionObject?.cacheData?.data?.AriesCommon?.rk_currency;
  const list: { [key: string]: string } = {};
  rk_currency?.split(',')?.forEach((pair: string) => {
    const [rk, currency] = pair.split(':');
    list[rk.trim()] = currency?.trim();
  });
  const localeCurrency = list[localeCode];

  // eslint-disable-next-line no-extend-native
  String.prototype.getSymbol = function () {
    const currency = String(this || '');
    const isLocaleCurrency = localeCurrency?.toUpperCase() === currency?.toUpperCase();
    return currencySymbol && isLocaleCurrency ? currencySymbol : currency;
  };
}

type languageDescriptionType = {
  translatedText: string;
};
export const readLocalizedDescription = (rules: {
  type: { code: string };
  descriptions: string[];
  localizedDescriptions: languageDescriptionType[];
}) => {
  const translatedText = rules?.localizedDescriptions?.[0]?.translatedText;
  if (translatedText) {
    return translatedText;
  }
  return rules.descriptions[0] ?? '';
};

export const updateBookPrintModalUXLStatus = (uxlName?: string) => {
  window.bookPrintModalUXLStatus = {
    ...window.bookPrintModalUXLStatus,
    ...(uxlName && { [uxlName]: true }),
  };

  const phoenixBookHotelHeaderData = window?.bookPrintModalUXLStatus?.PhoenixBookHotelHeaderData;
  const phoenixBookNotificationStatus = window?.bookPrintModalUXLStatus?.PhoenixBookNotificationStatus;
  const phoenixBookConfirmationMessage = window?.bookPrintModalUXLStatus?.PhoenixBookConfirmationMessage;

  if (phoenixBookHotelHeaderData && phoenixBookNotificationStatus && phoenixBookConfirmationMessage) {
    eventUtil.dispatch(constants.PRINT_MODAL_UXL_STATUS, {
      printUxlStatus: true,
    });
  }
};

// This function is to set uxl query status in bookOCJUXLStatus global var and disptach custom even.
export const updateBookOCJUXLStatus = (uxlName?: string) => {
  window.bookOCJUXLStatus = {
    ...window.bookOCJUXLStatus,
    ...(uxlName && { [uxlName]: true }),
  };

  const phoenixBookRoomOverviewDetails = window?.bookOCJUXLStatus?.PhoenixBookRoomOverviewDetails;

  if (phoenixBookRoomOverviewDetails) {
    eventUtil.dispatch(constants.OCJ_UXL_STATUS, {
      printUxlStatus: true,
    });
  }
};

export const isCNLocale = (currentLocale?: string) => {
  const locale = currentLocale?.replace('_', '-');
  return locale === constants.LOCALE_CN;
};

export const isCNPhoneCountryCode = (phoneCountryCode?: string) => {
  return phoneCountryCode === constants.CN_COUNTRY_CODE_VALUE;
};

export const isCNCountry = (country?: string) => {
  return country === constants.CHINA_COUNTRY;
};

export const getTargetMboxName = (pageUrl: string) => {
  const pageNameArr = pageUrl.split('/');
  const pageName = pageNameArr[pageNameArr.length - 1];
  // Add other mappings for target mbox name
  const PAGE_MBOX_MAP = {
    [RATE_LIST_MENU_URL]: SERVER_TARGET_MBOX_RLM_NAME,
  };
  return PAGE_MBOX_MAP[pageName] || SERVER_TARGET_MBOX_NAME;
};

const generateSupplimentalId = () => {
  const digits = '0123456789';
  let high = '';
  let low = '';
  let digitValue;
  let digitValueMax = 8;
  const characters = digits + 'ABCDEF';

  // Do not use generateRandomNumber for serverSide functions
  for (let digitNum = 0; digitNum < 16; digitNum++) {
    digitValue = Math.floor(Math.random() * digitValueMax);
    high += characters.substring(digitValue, digitValue + 1);
    digitValue = Math.floor(Math.random() * digitValueMax);
    low += characters.substring(digitValue, digitValue + 1);
    digitValueMax = 16;
  }
  return high + '-' + low;
};

export const isSSTEnabled = (pageUrl: string, SSTHeader: string) => {
  const pageNameArr = pageUrl.split('/');
  const pageName = pageNameArr[pageNameArr.length - 1];
  const targetEnabledPageList = [URL_CONSTANTS.RRD_GI_URL, URL_CONSTANTS.RATE_LIST_MENU_URL];

  if (SSTHeader !== undefined) {
    return SSTHeader === 'true';
  }

  return process.env['NEXT_PUBLIC_SERVER_TARGET_ENABLE'] === 'true' && targetEnabledPageList.includes(pageName);
};

function isDesktopDevice(characteristicsHeader: string) {
  // Split the header string into key-value pairs
  const characteristics = characteristicsHeader?.split(';');

  // Iterate through key-value pairs to find is_mobile field
  for (const pair of characteristics) {
    const [key, value] = pair.split('=');
    if (key.trim() === 'is_mobile') {
      // If is_mobile is false, it's a desktop device
      return value.trim() === 'false';
    }
  }

  // If is_mobile field is not found, assume it's a desktop device
  return true;
}

export const targetPayloadGenerator =
  (dataLayer: { data: datalayerProps[] }, sessionDataServer: any, pageUrl: any, headers: any, queryParams = '') =>
  (defaultPayload: any) => {
    const { log } = global.loggerInstance('Target Payload Generator');
    const datalayerData = dataLayer.data?.[0] ?? {};
    const is_desktop_device = isDesktopDevice(headers?.['x-akamai-device-characteristics'] ?? '');
    const serverTargetPayload = getServerTargetPayload(
      datalayerData,
      sessionDataServer?.cacheData?.data,
      is_desktop_device
    );
    const payload = {
      ...defaultPayload,
      execute: {
        mboxes: [
          {
            name: getTargetMboxName(pageUrl),
            index: SERVER_TARGET_MBOX_INDEX,
            parameters: serverTargetPayload,
          },
        ],
      },
    };

    const { env_site_name } = datalayerData || {};
    const { 'x-host': hostFromHeaders } = headers || {};
    const { NEXT_PUBLIC_TARGET_BASE_URL: defaultHost } = process.env;
    const hostName = `${HTTPS_STRING}${env_site_name ?? hostFromHeaders ?? defaultHost?.replace(HTTPS_STRING, '')}`;

    payload.context.address.url = `${hostName}${pageUrl}.mi${queryParams ? `?${queryParams}` : ''}`;

    payload.context.browser.host = hostName;

    // decrypting cookies and merging with header object
    decodeURIComponent(headers.cookies)
      .split(';')
      .forEach(item => {
        const keyValuePair = item.split('=');
        if (keyValuePair.length >= 2) {
          headers[keyValuePair[0].trim().toLowerCase()] = keyValuePair[1];
        }
      });
    payload.id.tntId = datalayerData.cookie_mi_visitor;
    if (headers.mi_visitor) {
      payload.id.tntId = headers.mi_visitor;
    }
    if (datalayerData.altCustId) {
      payload.id.thirdPartyId = datalayerData.altCustId;
    }
    if (headers[constants.gcv]) {
      const gcvToken = decodeURI(headers[constants.gcv]);
      const gcvTokenArray = gcvToken.split('|');
      const MCMIDIndex = gcvTokenArray.indexOf('MCMID');
      payload.id.marketingCloudVisitorId = gcvTokenArray[MCMIDIndex + 1];
    }
    const supplimentalId = generateSupplimentalId();
    payload.experienceCloud.analytics.supplementalDataId = supplimentalId;
    log.debug(`target payload: ${JSON.stringify(payload)}, is_desktop_device: ${is_desktop_device}`);
    return payload;
  };

/**
 * method checks whether login is disabled for the current locale
 */
export function isLoginDisabledBasedOnLocale(): boolean {
  const sessionData = getWindowSession();
  return (
    constants.LIST_OF_LOCALE_LOGIN_IS_DISBALED.includes(sessionData?.locale) ||
    constants.LIST_OF_LOCALE_LOGIN_IS_DISBALED.includes(sessionData?.locale?.replace('_', '-'))
  );
}

/**
 * method to capitalize a string
 */
export function capitalizeFirstChar(text: string): string {
  if (text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  return '';
}

export function getReplacedText(text?: string, value?: string) {
  return value ? text?.replace('{xx}', value) : '';
}

export function generateDynamicLabel(
  label?: string,
  labels?: string,
  value?: string,
  isCN?: boolean,
  unitOfRoom?: string
) {
  if (isCN && unitOfRoom) {
    return value
      ? Number(value) > 1
        ? labels?.replace('{xx} ', `${value} ${unitOfRoom}`)
        : label?.replace('{xx} ', `${value} ${unitOfRoom}`)
      : '';
  }
  return value ? (Number(value) > 1 ? labels?.replace('{xx}', value) : label?.replace('{xx}', value)) : '';
}

/**
 * Updates the sign-in modal platform based on the modal type.
 * @param modalType The type of the modal.
 * @returns The platform (Phoenix or Aries) of the sign-in modal.
 */
export const updateSignModalPlatform = (modalType: string): string => {
  // Get process environment variables
  const processEnv = getProcessEnvs();
  // Check if the code is running in a browser environment
  if (canUseDOM) {
    // Get the account container modal element
    const accountContainerModal = document?.querySelector(`.account-container-modal__${modalType}`);
    // Determine the platform based on environment variables and DOM availability
    const platform =
      accountContainerModal && [true, constants.TRUE].includes(processEnv['NEXT_PUBLIC_PHOENIX_SIGNIN_OVERLAY']);
    // Set the platform for the sign-in modal in the global window object
    if (window.dataLayer) {
      window.dataLayer['phoenixSignIn'] = platform ? true : false;
      window.dataLayer['resSignInModal'] = platform ? constants.PHOENIX : constants.ARIES;
    }
    window.resSignInModal = platform ? constants.PHOENIX : constants.ARIES;
    // Return the determined platform
    return platform ? constants.PHOENIX : constants.ARIES;
  }

  // Return empty string if not running in a browser environment
  return '';
};

/**
 * To get the OCJ modal platform based on the modal type.
 * @param modalType The type of the modal.
 * @returns The platform (Phoenix or Aries) OCJ modal.
 */
export const getOCJModalPlatform = (modalType: string): string => {
  // Get process environment variables
  const processEnv = getProcessEnvs();
  // Check if the code is running in a browser environment
  if (canUseDOM) {
    // Get the account container modal element
    const modalContainer = document?.querySelector(`.ocj-container__${modalType}`);
    // Determine the platform based on environment variables and DOM availability
    const platform = modalContainer && [true, constants.TRUE].includes(processEnv['NEXT_PUBLIC_PHOENIX_OCJ_OVERLAY']);
    // Set the platform for the modal in the global window object
    window.resOneClickJoinModal = platform ? constants.PHOENIX : constants.ARIES;
    // Return the determined platform
    return platform ? constants.PHOENIX : constants.ARIES;
  }

  // Return empty string if not running in a browser environment
  return '';
};

export function isValidObject(variable: any) {
  return variable !== null && typeof variable === 'object' && !Array.isArray(variable);
}

export function validateCTSEndpoint(clientEnvVars: any) {
  if (clientEnvVars?.NEXT_PUBLIC_CTS_IMAGE_ENDPOINT_ENABLE?.toLowerCase() === 'true') {
    return true;
  }
  return false;
}

export const getTAGPCookie = (req: requestType): boolean => {
  return Boolean(req.cookies?.BVSAuthCookie && req.cookies?.mippIDCookie);
};

export function getCookieValue(cookieName: string): string | null {
  if (!canUseDOM) {
    return null;
  }
  // Get all cookies in a string
  const cookies = document.cookie;

  // Split the string into individual cookies
  const cookieArray = cookies.split(';');

  // Iterate through the cookies array
  for (let cookie of cookieArray) {
    // Trim any leading whitespace
    cookie = cookie.trim();

    // Check if the cookie starts with the cookie name
    if (cookie.startsWith(`${cookieName}=`)) {
      // Return the cookie value by splitting and decoding
      return decodeURIComponent(cookie.split('=')[1]);
    }
  }

  // Return null if the cookie is not found
  return null;
}

export const checkFormValidation = (regex: string | RegExp) => {
  return new RegExp(regex);
};

export function isBulgariFlowFn() {
  let isBulgari = false;
  if (canUseDOM) {
    isBulgari = Boolean(window.isBulgari);
  }
  return isBulgari;
}

export function isBulgariFlowRequest(req: { headers: RequestProps }, isFrame = false) {
  if (isFrame) {
    return false;
  }
  const requestHost = req.headers['x-host'] || '';
  const isbulgariFlow =
    requestHost.includes(BULGARI_HOST) ||
    NEXT_PUBLIC_PROXY_HOST?.includes(BULGARI_HOST) ||
    Boolean(req.headers['is-bulgari-flow']); //TODO : Temporary code to run bulgari through mod header.

  return isbulgariFlow;
}

/**
 * Removes HTML tags from a given string.
 *
 * This function uses a regular expression to identify and remove all HTML tags
 * from the input string. It is useful for sanitizing strings and ensuring that
 * no HTML elements are present in the final output.
 *
 * Regular Expression Explanation:
 * - `/<\/?[^>]+(>|$)/g`
 * - `<\/?`: Matches the opening `<` character, optionally followed by a `/` (to
 * cover both opening and closing tags).
 * - `[^>]+`: Matches one or more characters that are not `>`. This ensures that
 * we capture everything inside the tag.
 * - `(>|$)`: Ensures that the match ends with a `>` or the end of the string
 * `$`. This is important to avoid partial matches.
 * - `g`: Global flag to ensure that all occurrences in the string are replaced,
 * not just the first one.
 *
 * @param {string} input - The input string containing HTML tags.
 * @returns {string} - The sanitized string without HTML tags.
 *
 * @example
 * const input = "<p>TEXT CONTENT</p>";
 * const output = removeHtmlTags(input); => "TEXT CONTENT"
 */
export function removeHtmlTags(input: string): string {
  return input.replace(/<\/?[^>]+(>|$)/g, '');
}

export function removeNonNumericCharacters(input: string): string {
  return input.replace(/\D/g, '');
}

//To get ForeignCurrencyAlert
export function getForeignCurrencyAlert(
  selectedCurrency: string | null,
  exchangeCurrency: string,
  propertyCurrency: string
) {
  return !!selectedCurrency && selectedCurrency === exchangeCurrency && selectedCurrency !== propertyCurrency;
}

//get the locale from document lang in client side
export function getLocaleFromDocument(): string {
  if (typeof document === 'undefined') return '';
  return document.documentElement?.lang?.split(/[_-]/)?.[0]?.toLowerCase();
}

//this function to return time in Arabic time of day format
export function getTimeInArabicTimeOfDayFormat(timeString: string): string {
  // Parse the time string with moment
  const time = moment(timeString, 'hh:mm A');

  if (!time.isValid()) {
    return timeString;
  }

  const hour = time.hour();

  const arabicSuffix =
    hour >= 6 && (hour <= 12 || (hour === 12 && time.minute() === 0))
      ? ARABIC_PERIODS.MORNING // Morning
      : hour > 12 && hour < 16
      ? ARABIC_PERIODS.NOON // Noon
      : hour >= 16 && hour < 18
      ? ARABIC_PERIODS.AFTERNOON // Afternoon
      : ARABIC_PERIODS.EVENING; // Evening

  // Format the time as hh:mm and append the Arabic suffix
  return convertArabicToEnglishNumbers(`${time.format('h:mm')} ${arabicSuffix}`);
}

/**
 * Replaces all whitespace characters in the given string with the tab character (`%09`).
 *
 * @param input - The string to process. If not provided or undefined, the function returns an empty string.
 *
 * @returns The processed string with all whitespace replaced by `%09`.
 *          If the input is undefined or empty, an empty string is returned.
 *
 * @example
 * // Example usage:
 * replaceSpacesWithTabs("hello world");
 * // Returns: "hello%09world"
 *
 * replaceSpacesWithTabs("   a   b   c   ");
 * // Returns: "%09a%09b%09c%09"
 *
 * replaceSpacesWithTabs();
 * // Returns: ""
 */
export const replaceSpacesWithTabs = (input?: string) => input?.replace(/\s+/g, '%09') || '';

//get the dir from document in client side
export const getDocumentDir = () => {
  if (typeof document === 'undefined') return '';
  return document.documentElement?.dir || '';
};

//this finction will detect any currecny in a string and reorder the currecny symbol for RTL enabled site
export const reorderCurrencyForRTL = (inputString: string) => {
  // Regular expression to detect a currency and amount pattern
  const pattern = /\b(\d+\.\d{2})\s*([A-Z]{3})\b|\b([A-Z]{3})\s*(\d+\.\d{2})\b/g;

  // Function to handle the replacement
  function replaceMatch(
    match: string,
    amountBefore: string,
    currencyAfter: string,
    currencyBefore: string,
    amountAfter: string
  ) {
    if (amountBefore && currencyAfter) {
      // Match format: '70.00 USD'
      return `${currencyAfter} ${amountBefore}`;
    } else if (currencyBefore && amountAfter) {
      // Match format: 'USD 70.00'
      return `${currencyBefore} ${amountAfter}`;
    }
    return match;
  }

  try {
    // Replace all occurrences in the string
    return inputString.replace(pattern, replaceMatch);
  } catch (e) {
    //if error return the original string
    return inputString;
  }
};

// Utility function to check if the current locale is Arabic
export const isArabicLocale = () => getLocaleFromDocument() === constants.IS_LOCALE_ARABIC;

// Function to handle text reordering of currency sysmbol if its RTL enabled site
export const getDisplayTextBasedOnHTMLDir = (item: string, isArabic: boolean) => {
  return isArabic ? reorderCurrencyForRTL(item) : item;
};

// Extracts the value of a specific header or query parameter based on the page URL.
export function getHeaderValue(req: { headers: RequestProps }, pageUrl: string, queryParamString: string) {
  const isRLMPage = pageUrl?.includes('rateListMenu');
  const isHybridPage = pageUrl?.includes('reviewguestinformation');
  const rlmExpFrag = 'rlm-exp-frag';
  const rrdgiExpFrag = 'rrdgi-exp-frag';

  try {
    const queryParams = new URLSearchParams(queryParamString);
    if (isRLMPage && (req.headers[rlmExpFrag] || queryParams.has(rlmExpFrag))) {
      return req.headers[rlmExpFrag] || queryParams.get(rlmExpFrag);
    }
    if (isHybridPage && (req.headers[rrdgiExpFrag] || queryParams.has(rrdgiExpFrag))) {
      return req.headers[rrdgiExpFrag] || queryParams.get(rrdgiExpFrag);
    }
    return null;
  } catch (_e) {
    return null;
  }
}
