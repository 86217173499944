import React, { FC, FormEvent, useState } from 'react';
import { useRouter } from 'next/router';
import { logger } from '@marriott/shared/mi-helper-utils';

import clsx from 'clsx';
import {
  Heading,
  InputTextField,
  CheckBox,
  Button,
  Link,
  headingType,
  ButtonTypeVariation,
} from '@marriott/mi-ui-library';
import { AuthStateEnum } from '@marriott/shared/mi-helper-utils';
import { FullWidthContainer, StyledLinkButton, StyledSignInForm, WrapText, JoinNowWrapper } from './SignInForm.styles';
import { SignInFormProps, ErrorMessageKeys } from './SignInForm.types';
import { useUserDetailsStore } from '@marriott/mi-store-utils';
import { encryptPassword } from './helper';
import { MFA_OPTIONS_KEY } from '../../Navigation/_constants';

const { log } = logger({})('SignInForm');

export const SignInForm: FC<SignInFormProps> = props => {
  const {
    signInLabel,
    memberNumberLabel,
    loginPasswordLabel,
    loginPasswordPlaceHolder,
    remeberMeLabel,
    signInButtonLabel,
    signInButtonUrl,
    forgotPasswordLinkLabel,
    forgotPasswordLinkPath,
    activateAccountLinkLabel,
    activeAccountLinkUrl,
    clearRememberedAccountLabel,
    clearRememberedAccountUrl,
    welcomeText,
    signInSubHeading,
    signInErrorMessages,
    joinNowLabel,
    joinNowSubHeading,
    becomeMemberLabel,
    becomeMemberUrl,
  } = props;
  const router = useRouter();
  const { ATTEMPTED_SIGNIN } = AuthStateEnum;

  const { userProfileData, isRememberedUser } = useUserDetailsStore(state => ({
    userProfileData: state.userProfileData,
    isRememberedUser: state.isRememberedUser,
  }));
  const [username, setUsername] = useState<string>(userProfileData?.memberNumber || '');
  const [password, setPassword] = useState<string>('');
  const [validUserName, setValidUserName] = useState<boolean>(true);
  const [validPassword, setValidPassword] = useState<boolean>(true);
  const [passwordType, setPasswordType] = useState<'text' | 'password'>('password');
  const [checked, setChecked] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  // Match for the following:
  // * Remembered me userid, a string with exactly 4 digits followed by one or more asterisks: \d{4}\*+
  // * Straight-up userid, a string with at least 6 digits: \d{6,}
  // * An email address: [\w.+-]+@[\w-]+\.[\w.-]+
  const usernamePattern = /^(?:\d{3}\*+|\d{6,}|[\w.+-]+@[\w-]+\.[\w.-]+)$/;
  const [signInErrors, setSignInErrors] = useState<ErrorMessageKeys[]>();

  const isSubmitDisabled = !validPassword || !validUserName || isSubmitting;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!password.length || !username.length) {
      setValidPassword(password.length >= 1);
      setValidUserName(username.length >= 1);
      return;
    }

    setIsSubmitting(true);

    try {
      const encryptedPassword = await encryptPassword(password, userProfileData?.pwdPublicKey);

      const formData = {
        // TODO: Once the return url and sign-in url ara available from model add those variables to value attribrute
        returnUrl: '/default.mi',
        errorUrl: '/sign-in-error.mi',
        userId: username,
        password: encryptedPassword,
        rememberMe: checked,
        pwrdDecryptNotNeeded: !userProfileData?.pwdPublicKey,
      };

      const response = await fetch(signInButtonUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();

      const isMFA = data?.mfaOption && (data?.mfaOption?.emailAddress || data?.mfaOption?.phoneNumbers?.length > 0);

      if (data.status !== 'SUCCESS') {
        const errorMessages = data?.phoenixErrorMessages?.errorMessages as ErrorMessageKeys[];
        setSignInErrors(errorMessages);
        setIsSubmitting(false);
      } else if (isMFA) {
        window.sessionStorage.setItem(MFA_OPTIONS_KEY, JSON.stringify(data.mfaOption));
        router.push(data.nextStateURI);
      } else {
        window.location.href = formData?.returnUrl;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      log.error(error);
      setIsSubmitting(false);
    } finally {
      localStorage.setItem(ATTEMPTED_SIGNIN, 'true');
    }
  };

  const clearRememberedUser = async () => {
    try {
      const response = await fetch(clearRememberedAccountUrl);
      const { nextStateURI } = await response.json();
      if (nextStateURI) {
        window.location.href = nextStateURI;
      } else {
        window.location.reload();
      }
    } catch (e) {
      log.error('Something went wrong clearing remembered user', e);
      window.location.reload();
    }
  };

  return (
    <StyledSignInForm onSubmit={handleSubmit} className="d-flex flex-column align-items-start">
      {!isRememberedUser && (
        <JoinNowWrapper>
          <Heading
            variation={headingType.title}
            titleText={joinNowLabel}
            customClass="heading join-now-label m-0 t-title-s"
            data-testid="join-now"
          />
          <Heading
            variation={headingType.body}
            titleText={joinNowSubHeading}
            customClass={'subHeading join-now-subheading mt-2'}
          />
          <Button
            className="join-now-button m-button-m m-button-secondary pl-3 pr-3"
            buttonCopy={becomeMemberLabel}
            href={becomeMemberUrl}
            isLink={true}
          />
          <hr className={clsx('separator m-0 mt-4 mt-lg-5')} />
        </JoinNowWrapper>
      )}
      <Heading
        variation={headingType.title}
        titleText={isRememberedUser ? `${welcomeText} ${userProfileData?.headerSubtext?.consumerName}` : signInLabel}
        customClass={clsx(' t-title-s', isRememberedUser ? 'welcomeText mt-5' : 'mt-4 pb-2', ' mt-lg-5 mb-0')}
      />
      {isRememberedUser && (
        <div>
          <Heading
            variation={headingType.subtitle}
            titleText={signInSubHeading}
            customClass={'subHeading t-label-s mb-3'}
          />
        </div>
      )}
      {/* TODO: Once the return and sign-in url ara available from model add those variables to value attribrute */}
      <input type="hidden" className="sign-in-return-url" name="returnUrl" value="/default.mi" />
      <input type="hidden" name="errorUrl" value="/sign-in-error.mi" />
      {signInErrors &&
        signInErrors.map(
          errorMessageKey =>
            signInErrorMessages[errorMessageKey] && (
              <FullWidthContainer>
                <div className="m-message-inline error-sev1" data-testid="error-message">
                  <div className="m-message-content-wrap">
                    <WrapText dangerouslySetInnerHTML={{ __html: signInErrorMessages[errorMessageKey] as string }} />
                  </div>
                </div>
              </FullWidthContainer>
            )
        )}

      <InputTextField
        type={'text'}
        testId={'signinusername'}
        label={memberNumberLabel}
        inputValue={isRememberedUser ? userProfileData?.memberNumber : username}
        showErrorMessage={!validUserName}
        className={` m-input-field ${!isRememberedUser && validUserName && username?.length > 0 ? 'is-active' : ''}${
          isRememberedUser ? 'is-disabled' : ''
        } align-self-stretch mt-3 mt-lg-4 pb-2`}
        messageToShow={signInErrorMessages.errorForUserName}
        messageClass="mx-3 d-block"
        getInputValue={val => {
          setUsername(val);
          setValidUserName(usernamePattern.test(val));
        }}
        getInputProps={() => {
          return isRememberedUser ? { disabled: true } : {};
        }}
      />
      <InputTextField
        type={passwordType}
        testId={'signinpassword'}
        label={loginPasswordLabel}
        placeHolderText={loginPasswordPlaceHolder}
        inputValue={password}
        className={`m-input-field ${password?.length > 0 ? 'is-active' : ''} align-self-stretch mt-4 mt-lg-5`}
        messageClass="errorMessage mx-3 d-block"
        getInputValue={val => {
          setPassword(val);
          setValidPassword(val?.length >= 1);
        }}
        iconOnClick={() => {
          if (passwordType === 'password') {
            setPasswordType('text');
          } else setPasswordType('password');
        }}
        iconClass={`icon icon-visibility${passwordType !== 'password' ? '-off' : ''}`}
        showIcon={!!password?.length}
        showErrorMessage={!validPassword}
        messageToShow={signInErrorMessages.errorForPassword}
      />
      <input type="hidden" name="errorUrl" value="/sign-in-error.mi" />
      <CheckBox
        checkboxId={'signincheckbox'}
        checked={checked}
        onChange={() => setChecked(!checked)}
        checkboxLabel={remeberMeLabel}
        checkboxName="signincheckbox"
        className="checkBoxLabel mt-4 mt-lg-5"
      />
      <Button
        className={clsx('m-button-m signInButton mt-4 mt-lg-5', { disabled: isSubmitDisabled })}
        id="signInButton"
        testId="signinbutton"
        type={ButtonTypeVariation.Submit}
        isDisabled={isSubmitDisabled}
      >
        {isSubmitting ? <div className="m-spinner-s ml-3 mr-3"></div> : signInButtonLabel}
      </Button>
      <div className="d-flex flex-column align-items-start mt-4">
        <Link
          text={forgotPasswordLinkLabel}
          linkClassName={'m-link-action linkClass'}
          linkHref={forgotPasswordLinkPath}
          id="forgotpassword"
        />
        {isRememberedUser ? (
          <StyledLinkButton
            className="m-link-action linkClass mt-2"
            callback={clearRememberedUser}
            id="clearrememberedaccount"
            buttonCopy={clearRememberedAccountLabel}
          />
        ) : (
          <Link
            text={activateAccountLinkLabel}
            linkClassName="m-link-action linkClass mt-2"
            linkHref={activeAccountLinkUrl}
            id="activateonlineaccount"
          />
        )}
      </div>
    </StyledSignInForm>
  );
};
